import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const page = {
  _tag: `Page`,
  key: `page`
} as const;

export const pageTaggedC = t.type({
  _tag: t.literal(`Page`)
});
export type PageTaggedC = typeof pageTaggedC;
export type PageTagged = t.TypeOf<PageTaggedC>;
export type Page = PageTagged & typeof page;
export const pageC = pipe(pageTaggedC, c => new t.Type<Page, PageTagged>(
  `Page`,
  (u: unknown): u is Page => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Page> => pipe(c.decode(u), E.map(x => ({ ...x, ...page }))),
  (x: Page): PageTagged => ({ ...x, _tag: `Page`}),
));
export type PageC = typeof pageC;


export const sortCol = {
  _tag: `SortCol`,
  key: `sortCol`
} as const;

export const sortColTaggedC = t.type({
  _tag: t.literal(`SortCol`)
});
export type SortColTaggedC = typeof sortColTaggedC;
export type SortColTagged = t.TypeOf<SortColTaggedC>;
export type SortCol = SortColTagged & typeof sortCol;
export const sortColC = pipe(sortColTaggedC, c => new t.Type<SortCol, SortColTagged>(
  `SortCol`,
  (u: unknown): u is SortCol => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SortCol> => pipe(c.decode(u), E.map(x => ({ ...x, ...sortCol }))),
  (x: SortCol): SortColTagged => ({ ...x, _tag: `SortCol`}),
));
export type SortColC = typeof sortColC;


export const sortDir = {
  _tag: `SortDir`,
  key: `sortDir`
} as const;

export const sortDirTaggedC = t.type({
  _tag: t.literal(`SortDir`)
});
export type SortDirTaggedC = typeof sortDirTaggedC;
export type SortDirTagged = t.TypeOf<SortDirTaggedC>;
export type SortDir = SortDirTagged & typeof sortDir;
export const sortDirC = pipe(sortDirTaggedC, c => new t.Type<SortDir, SortDirTagged>(
  `SortDir`,
  (u: unknown): u is SortDir => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SortDir> => pipe(c.decode(u), E.map(x => ({ ...x, ...sortDir }))),
  (x: SortDir): SortDirTagged => ({ ...x, _tag: `SortDir`}),
));
export type SortDirC = typeof sortDirC;


export const search = {
  _tag: `Search`,
  key: `search`
} as const;

export const searchTaggedC = t.type({
  _tag: t.literal(`Search`)
});
export type SearchTaggedC = typeof searchTaggedC;
export type SearchTagged = t.TypeOf<SearchTaggedC>;
export type Search = SearchTagged & typeof search;
export const searchC = pipe(searchTaggedC, c => new t.Type<Search, SearchTagged>(
  `Search`,
  (u: unknown): u is Search => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Search> => pipe(c.decode(u), E.map(x => ({ ...x, ...search }))),
  (x: Search): SearchTagged => ({ ...x, _tag: `Search`}),
));
export type SearchC = typeof searchC;


export const filters = {
  _tag: `Filters`,
  key: `filters`
} as const;

export const filtersTaggedC = t.type({
  _tag: t.literal(`Filters`)
});
export type FiltersTaggedC = typeof filtersTaggedC;
export type FiltersTagged = t.TypeOf<FiltersTaggedC>;
export type Filters = FiltersTagged & typeof filters;
export const filtersC = pipe(filtersTaggedC, c => new t.Type<Filters, FiltersTagged>(
  `Filters`,
  (u: unknown): u is Filters => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Filters> => pipe(c.decode(u), E.map(x => ({ ...x, ...filters }))),
  (x: Filters): FiltersTagged => ({ ...x, _tag: `Filters`}),
));
export type FiltersC = typeof filtersC;


export const allTableParamC = [pageC, sortColC, sortDirC, searchC, filtersC] as const;
export const allTableParamNames = [`Page`, `SortCol`, `SortDir`, `Search`, `Filters`] as const;
export type TableParamName = (typeof allTableParamNames)[number];

export const TableParamCU = t.union([pageC, sortColC, sortDirC, searchC, filtersC]);
export type TableParamCU = typeof TableParamCU;
export type TableParamU = t.TypeOf<TableParamCU>;

export const tableParamOrd: Ord.Ord<TableParamU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allTableParam = [page, sortCol, sortDir, search, filters] as const;
export type TableParamMap<A> = { [K in TableParamName]: A };


