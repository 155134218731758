import Clipboard from "clipboard";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { Q } from "@scripts/dom/q";
import { Tooltip } from "@scripts/ui/tooltip";
export class Copy {
  static init(): void {
    const clipboard = new Clipboard(".copy-to-clipboard");
    clipboard.on("success", (e: ClipboardJS.Event) => {
      pipe(
        O.fromNullable(e.trigger.getAttribute("data-target")),
        O.chain(Q.one),
        O.chain(Tooltip.cache.get),
        O.map((tt: Tooltip) => {
          tt.showNow();
          window.setTimeout(tt.hideNow, 2000);
        })
      );
    });
  }
}
