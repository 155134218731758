import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import type * as t from "io-ts";

import type { BrowserBLWindow } from "./bondlink";
import type { BLBaseSession, BLIssuerSitesSession } from "./generated/models/blWindow";
import type { Issuer } from "./generated/models/issuer";
import { issuerC } from "./generated/models/issuer";
import type { UserWithRoles } from "./generated/models/user";
import { userWithRolesC } from "./generated/models/user";
import { errorsToLogErrors, logErrors } from "./util/log";

const bondlinkUserDecodeErrors = (message: string) => (errors: t.Errors) => pipe(errors, errorsToLogErrors("fatal", message), logErrors);

export const getUser = (): O.Option<UserWithRoles> => pipe(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  O.fromNullable((window as unknown as BrowserBLWindow<BLBaseSession>).BLSession.user),
  O.chain(x =>
    pipe(
      userWithRolesC.decode(x),
      E.mapLeft(bondlinkUserDecodeErrors("Failure to decode BLWindow.BLUser for userWithRolesC in bondlinkUser.user")),
      O.fromEither
    )
  ),
);

export const setUser = (newUser: unknown) => pipe(
  userWithRolesC.decode(newUser),
  E.matchW(
    bondlinkUserDecodeErrors("Failure to decode newUser for userWithRolesC in bondlinkUser.setUser"),
    () => pipe(
      window,
      O.fromNullable,
      O.map(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (w) => (w as unknown as BrowserBLWindow<BLBaseSession>).BLSession.user = newUser as BrowserBLWindow<BLBaseSession>["BLSession"]["user"]
      )
    )
  )
);

export const getIssuer = (): O.Option<Issuer> => pipe(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  O.fromNullable((window as unknown as BrowserBLWindow<BLIssuerSitesSession>).BLSession.issuer),
  O.chain(x =>
    pipe(
      issuerC.decode(x),
      E.mapLeft(bondlinkUserDecodeErrors("Failure to decode BLWindow.BLIssuer for issuerC in bondlinkUser.issuer")),
      O.fromEither
    )
  )
);
