import { lookup } from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { chain, map } from "fp-ts/lib/Option";

import { Q } from "../dom/q";

export function initMarkdownTables(): void {
  Q.all<HTMLTableElement>(".md-to-html .table").forEach((t: Q<HTMLTableElement>) => {
    const headers = t.all("thead th");
    t.all("tbody tr").forEach((r: Q) =>
      r.all("td").forEach((d: Q, i: number) =>
        pipe(lookup(i, headers), chain((h: Q) =>
          pipe(h.getInnerText(), map((txt: string) =>
            d.setData("title", txt)))))));
  });
}
