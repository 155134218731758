import { pipe } from "fp-ts/lib/function";
import { filter, fold, map } from "fp-ts/lib/Option";
import { bimap } from "fp-ts/lib/TaskEither";

import { Config } from "@scripts/csr-only/config";
import type { QEvent } from "@scripts/dom/q";
import { Q } from "@scripts/dom/q";
import { blFetch } from "@scripts/fetch";
import * as SitesRouter from "@scripts/generated/routers/sitesRouter";
import { spinnerCreate } from "@scripts/ui/spinner";
import { eq } from "@scripts/util/eq";
import { makeConfigLogger } from "@scripts/util/log";

export function initDraftPreview(): void {
  map((e: Q) => e.listen("click", (evt: QEvent) => {
    spinnerCreate();
    pipe(
      evt.thisElement.getData("mode"),
      filter(eq("preview")),
      fold(SitesRouter.issuersitesIssuerControllerEnterPreview, SitesRouter.issuersitesIssuerControllerExitPreview),
      blFetch(Config(makeConfigLogger())),
      bimap(() => window.location.reload(), () => window.location.reload()))();
  }))(Q.one("#preview-switcher"));
}
