import { head } from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import { not } from "fp-ts/lib/Predicate";

import type { QEvent } from "../dom/q";
import { Q } from "../dom/q";
import { html, UnsafeHtml } from "../dom/unsafeHtml";
import { eq } from "../util/eq";
import { invoke0, invoke1 } from "../util/invoke";
import { Modal } from "./modal";

export class DataFilters {
  static readonly toggleSelector = ".data-filter-toggle";

  static init(): void {
    Q.body.all(".data-filters").forEach((container: Q) => {
      DataFilters.updateFilter(container, container.one(`${DataFilters.toggleSelector}.current`));

      pipe(
        O.Do,
        O.apS("modal", container.closest(Modal.modalSelector)),
        O.bind("modalId", ({ modal }) => modal.getId()),
        O.map(({ modal, modalId }) => Q.body.listen("click", `[data-target="#${modalId}"][data-filter-type]`, ({ selectedElement: btn }: QEvent) => pipe(
          btn.getData("filter-type"),
          O.chain((t: string) => modal.one(`${DataFilters.toggleSelector}[data-type="${t}"]`)),
          O.map((el: Q) => DataFilters.setCurrent(container, el)),
          // Open modal regardless of whether filtered type was found
          () => Modal.toggle(true)(modal),
        ))),
      );
    });

    Q.body.listen("click", DataFilters.toggleSelector, Q.prevented(
      (e: QEvent) => O.map((container: Q) => {
        DataFilters.setCurrent(container, e.selectedElement);
      })(e.selectedElement.closest(".data-filters"))
    ));
  }

  static setCurrent(container: Q, el: Q): void {
    container.all(DataFilters.toggleSelector).forEach(invoke1("removeClass")("current"));
    el.addClass("current");
    DataFilters.updateFilter(container, O.some(el));
  }

  static updateFilter(container: Q, toggle: Option<Q>): void {
    const tpe = pipe(
      toggle,
      O.chain(invoke1("getData")("type")),
      O.getOrElse(() => "all")
    );
    const target = O.chain(Q.one)(container.getData("target"));
    pipe(
      target,
      O.map(invoke1("all")(".data-filter-empty")),
      O.getOrElse<Q[]>(() => [])
    ).forEach(invoke0("remove"));

    pipe(
      target,
      O.map<Q, Q[]>(invoke1("all")(".data-filter-target[data-type]")),
      O.getOrElse<Q[]>(() => [])
    ).map(invoke1("removeClass")("d-none"))
      .filter((el: Q) => tpe !== "all" && O.fold(() => false, not(eq(tpe)))(el.getData("type")))
      .forEach(invoke1("addClass")("d-none"));

    pipe(
      target,
      O.chain((t: Q) => head(t.all(`.data-filter-target${tpe === "all" ? "" : `[data-type="${tpe}"]`}`))),
      O.alt(
        () => O.map(
          invoke1("append")(Q.createElement(
            "div",
            [invoke1("addClass")("data-filter-empty")],
            []
          ).setInnerHtml(pipe(
            toggle,
            O.chain((t: Q) => O.map(UnsafeHtml)(t.getData("empty-html"))),
            O.getOrElse(() => html`<p>No items match your selection</p>`))
          ))
        )(target)
      )
    );
  }
}
