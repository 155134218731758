import autobind from "autobind-decorator";
import { sequenceT } from "fp-ts/lib/Apply";
import { filterMapWithIndex, head, lookup } from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { alt, Applicative, chain, filter, fold, getOrElse, isNone, map, none, some } from "fp-ts/lib/Option";

import type { QCustomEvent, QEvent } from "@scripts/dom/q";
import { CachedElements, Q } from "@scripts/dom/q";
import { Form } from "@scripts/form/form";
import { AjaxTable } from "@scripts/table/ajaxTable";
import { DataTable, parseDataTableParams } from "@scripts/table/dataTable";
import { initMarkdownTables } from "@scripts/table/markdownTable";
import { Tooltip } from "@scripts/ui/tooltip";
import { invoke0, invoke1 } from "@scripts/util/invoke";
import { mergeDeep } from "@scripts/util/merge";
import { parseBool } from "@scripts/util/parseBool";
import { mkDeepObj } from "@scripts/util/path";
import { trimFilter } from "@scripts/util/trimFilter";

import { scrollTo } from "../dom/scrollTo";

@autobind
export class Table {
  static readonly ROW_STRING_ID_POSITION = 0;
  static readonly ROW_ALPHANUMERIC_ID_POSITION = 1;
  static readonly ROW_MODIFIER_POSITION = 2;

  static readonly pageSize = 50;
  static readonly cache = new CachedElements("table", ".table:not(.no-datatable)", Table);
  static readonly searchProxySelector = ".table-search-proxy";
  static readonly sortDropdownSelector = ".table-sort-dropdown";

  static readonly staticOptions: SimpleDataTables.Options = {
    pagerDelta: 1,
    perPage: Table.pageSize,
    perPageSelect: false,
    prevText: "←",
    nextText: "→",
  };

  dataTable: DataTable;

  static init(): void {
    initMarkdownTables();
    Table.bindEvents();
    Table.cache.init();
  }

  static bindEvents(): void {
    Q.body.listen<"input", HTMLInputElement>("input", Table.searchProxySelector, (e: QEvent<"input", HTMLInputElement>) =>
      map((postfix: Q) =>
        postfix[fold<string, "addClass" | "removeClass">(() => "removeClass", () => "addClass")(trimFilter(e.selectedElement.getAttr("value")))]("has-value"))(
          head(e.selectedElement.siblings(".input-group-postfix"))
      ));

    Q.body.listen<"change", HTMLSelectElement>("change", Table.sortDropdownSelector, (e: QEvent<"change", HTMLSelectElement>) => map(
      ([table, v]: [Table, { column: number, direction: SimpleDataTables.SortDir }]) => table.sort(v.column, v.direction))(
        sequenceT(Applicative)(chain(Table.cache.get)(e.selectedElement.getData("table")), Form.getElementValue(e.selectedElement))));

    Q.body.listen("datatable.update", ".table", (e: QCustomEvent) => filterMapWithIndex((_, el: Q) => Tooltip.cache.get(el))(pipe(
      e.originationElement,
      chain(invoke1("closest")(".dataTable-wrapper")),
      map(invoke1("siblings")(Tooltip.contentSelector)),
      getOrElse<Q[]>(() => [])
    )).forEach(invoke0("update")));
  }

  static getOptions(element: Q<HTMLTableElement>): SimpleDataTables.Options {
    const maybeAddOpt = <A>(dataKey: string, optPath: string[], f: (s: string) => Option<A>) =>
      (opts: SimpleDataTables.Options): SimpleDataTables.Options =>
        mergeDeep(opts)(pipe(
          element.getData(dataKey),
          chain(f),
          fold(() => ({}), (a: A) => mkDeepObj(optPath, a))));
    const fs: Array<(o: SimpleDataTables.Options) => SimpleDataTables.Options> = [
      maybeAddOpt<string>("empty-text", ["labels", "noRows"], some),
      maybeAddOpt<boolean>("fixed-columns", ["fixedColumns"], parseBool),
    ];

    return fs.reduce(
      (acc: SimpleDataTables.Options, f: (o: SimpleDataTables.Options) => SimpleDataTables.Options) => f(acc),
      Table.staticOptions);
  }

  static lockRow(row: Q<HTMLTableRowElement>): void {
    row.all<HTMLButtonElement>("button").map(e => e.setAttr("disabled", true));
  }

  static unlockRow(row: Q<HTMLTableRowElement>): void {
    row.all<HTMLButtonElement>("button").map(e => e.setAttr("disabled", false));
  }

  constructor(element: Q<HTMLTableElement>) {
    const params = parseDataTableParams();
    this.dataTable = element.hasClass("ajax-table")
      ? new AjaxTable(element, params, Table.getOptions(element))
      : new DataTable(element, params, true, Table.getOptions(element));

    this.dataTable.initFilters();
    this.proxySearchInput();
    this.bindPagination();
  }

  getElement(): Q<HTMLTableElement> { return this.dataTable.element; }
  setElement(e: Q<HTMLTableElement>): void { this.dataTable.element = e; }

  update(): void {
    this.dataTable.update();
  }

  scrollToTop(): void {
    scrollTo(this.dataTable.element);
  }

  proxySearchInput(): void {
    Q.body.listen<"input", HTMLInputElement>("input",
      `.table-search-proxy[data-table="#${this.dataTable.element.getAttr("id")}"]`,
      (e: QEvent<"input", HTMLInputElement>) => map(
        (inp: Q<HTMLInputElement>) => inp.setAttr("value", e.selectedElement.getAttr("value")).trigger("keyup"))(this.dataTable.origSearchInput));
  }

  bindPagination(): void {
    this.dataTable.on("datatable.page", this.scrollToTop);
  }

  paginateTo(page: number, scroll: boolean = true): void {
    if (!scroll) {
      this.dataTable.off("datatable.page", this.scrollToTop);
      this.dataTable.on("datatable.page", this.bindPagination);
    }
    this.dataTable.page(page);
  }

  sort(column: number, direction: SimpleDataTables.SortDir): void {
    this.dataTable.columns().sort(column, direction);
  }

  splitId(id: string): string[] {
    return id.split("-", 3);
  }

  getRowIdFromTarget(el: Q): Option<string> {
    // Make sure the click is not in the table head or foot
    return pipe(
      el.closest("thead"),
      alt(() => el.closest("tfoot")),
      fold(
        () => chain((row: Q) => {
          const parts = this.splitId(row.getAttr("id"));
          return pipe(lookup(Table.ROW_ALPHANUMERIC_ID_POSITION, parts),
            filter(() => isNone(lookup(Table.ROW_MODIFIER_POSITION, parts))));
        })(el.closest("tr")),
        () => none
      )
    );
  }
}
