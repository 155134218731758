import * as E from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import type * as t from "io-ts";

import type { BLConfigWithLog } from "@scripts/bondlink";

import type { ApiError, ApiErrors } from "../generated/models/apiErrors";
import { apiErrorsC } from "../generated/models/apiErrors";
import { ModelField } from "./modelField";
import type { FieldValidationErrors, ValidationErrors } from "./validationErrors";
import { fieldValidationErrors, validationErrorsC } from "./validationErrors";

export type FieldApiErrors = ReadonlyArray<[ModelField, ApiError]>;
export type ResponseErrors = E.Either<FieldValidationErrors, FieldApiErrors>;

const genResponseErrorsToResponseErrors = (re: ApiErrors): ResponseErrors =>
  E.right(re.errors.map(e => [ModelField.fromString(e.field), e]));

const validationErrorsToResponseErrors = (config: BLConfigWithLog) => (ve: ValidationErrors): ResponseErrors =>
  E.left(fieldValidationErrors(config)(ve));

export const decodeResponseErrors = (config: BLConfigWithLog) => (u: unknown): t.Validation<ResponseErrors> =>
  pipe(
    apiErrorsC.decode(u),
    E.fold<t.Errors, ApiErrors, t.Validation<ResponseErrors>>(
      () => pipe(validationErrorsC.decode(u), E.map(validationErrorsToResponseErrors(config))),
      flow(genResponseErrorsToResponseErrors, E.right)
    ),
  );
