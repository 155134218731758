import autobind from "autobind-decorator";
import { splitAt } from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { fromReadonlyArray } from "fp-ts/lib/ReadonlyNonEmptyArray";

@autobind
export class ModelField {
  static readonly separator = "__";
  static readonly MODEL_FIELD_RE = /^[a-z\d]+(__[a-z\d]+)*(\[\])?$/i;

  static split(mf: string): O.Option<string[]> {
    return O.fromPredicate(() => ModelField.isValid(mf))(mf.split(ModelField.separator));
  }

  static isValid(mf: string): boolean {
    return ModelField.MODEL_FIELD_RE.test(mf);
  }

  static fromString(str: string): ModelField {
    const [init, tail] = splitAt(1)(str.split(ModelField.separator));
    return new ModelField(
      pipe(fromReadonlyArray(init), O.map(x => x.join(ModelField.separator))),
      tail.join(ModelField.separator),
    );
  }

  static formatted(field: string): ModelField {
    const split = field.split(ModelField.separator);
    const m = new ModelField(O.fromNullable(split[0]), split.slice(1).join(ModelField.separator));
    const rfield = (m.field === "data") ? "content" : m.field;
    const ffield = rfield.charAt(0).toUpperCase() + rfield.slice(1).split(/(?=[A-Z])/).join(" ");
    return new ModelField(m.model, ffield);
  }

  constructor(public model: O.Option<string>, public field: string) {
    this.model = model;
    this.field = field;
  }

  toString(): string {
    return pipe(
      this.model,
      O.fold(
        () => this.field,
        m => `${m}${ModelField.separator}${this.field}`,
      ),
    );
  }
}
