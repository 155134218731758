import type * as E from "fp-ts/lib/Either";
import { identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RM from "fp-ts/lib/ReadonlyMap";
import type { ReadonlyNonEmptyArray } from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as R from "fp-ts/lib/Record";
import { Ord as strOrd } from "fp-ts/lib/string";
import * as t from "io-ts";
import { readonlyMapFromEntries, type ReadonlyMapFromEntriesC } from "io-ts-types/lib/readonlyMapFromEntries";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { readonlyNonEmptyArrayC } from "@scripts/codecs/readonlyNonEmptyArray";
import { N } from "@scripts/fp-ts";

import type { ValidationError } from "../generated/models/validationError";
import { validationErrorC } from "../generated/models/validationError";
import { ModelField } from "./modelField";

export const stringRecordC = <V extends t.Mixed>(value: V): t.RecordC<t.StringC, V> => t.record(t.string, value);
export const numberMapC = <V extends t.Mixed>(value: V): ReadonlyMapFromEntriesC<t.NumberC, V> => readonlyMapFromEntries(t.number, N.Ord, value);

export const validationErrorsNEAC = readonlyNonEmptyArrayC(validationErrorC);
type ValidationErrorFields = { [field: string]: ValidationErrorsU };
type ValidationErrorIndices = ReadonlyMap<number, ValidationErrorsU>;
type ValidationErrorsU = ReadonlyNonEmptyArray<ValidationError> | ValidationErrorFields | ValidationErrorIndices;

const validationErrorsUC: () => t.Type<ValidationErrorsU> = () => {
  const rec = () => t.union([
    validationErrorsNEAC,
    stringRecordC(validationErrorsUC()),
    numberMapC(validationErrorsUC()),
  ]);

  return new t.Type<ValidationErrorsU, ValidationErrorsU>(
    "ValidationErrorsU",
    (u: unknown): u is ValidationErrorsU => rec().is(u),
    (u: unknown): E.Either<t.Errors, ValidationErrorsU> => rec().decode(u),
    (v: ValidationErrorsU): ValidationErrorsU => v
  );
};

const isValidationErrorsNEA = validationErrorsNEAC.is;
const isValidationErrorIndices = numberMapC(validationErrorsUC()).is;
const isValidationErrorFields = stringRecordC(validationErrorsUC()).is;

const foldValidationErrorsU = (config: BLConfigWithLog) => <A>(
  onErrs: (errs: ReadonlyNonEmptyArray<ValidationError>) => A,
  onFields: (fields: ValidationErrorFields) => A,
  onIndices: (fields: ValidationErrorIndices) => A
) => (v: ValidationErrorsU): A => {
  if (isValidationErrorsNEA(v)) {
    return onErrs(v);
  } else if (isValidationErrorFields(v)) {
    return onFields(v);
  } else if (isValidationErrorIndices(v)) {
    return onIndices(v);
  }
  return config.exhaustive(v);
};

export type FieldValidationErrors = ReadonlyArray<[ModelField, ReadonlyNonEmptyArray<ValidationError>]>;

const foldModelField = <A>(onNone: () => A, onStr: (s: string) => A, onMF: (mf: ModelField) => A): (o: O.Option<string | ModelField>) => A =>
  O.fold(onNone, x => typeof x === "string" ? onStr(x) : onMF(x));

const nextModelField = (key: string | number): (o: O.Option<string | ModelField>) => string | ModelField =>
  foldModelField(
    () => typeof key === "number" ? "[]" : key,
    screenLeft => typeof key === "number" ? `${screenLeft}[]` : new ModelField(O.some(screenLeft), key),
    m => typeof key === "number" ? new ModelField(m.model, `${m.field}[]`) : new ModelField(O.some(m.toString()), key)
  );

const fieldValidationErrorsU = (config: BLConfigWithLog) =>
  (modelField: O.Option<string | ModelField>) => (errs: ValidationErrorsU): FieldValidationErrors =>
    foldValidationErrorsU(config)<FieldValidationErrors>(
      (es: ReadonlyNonEmptyArray<ValidationError>) => [[
        pipe(modelField, foldModelField(() => new ModelField(O.none, "unknown"), f => new ModelField(O.none, f), identity)),
        es,
      ]],
      (fs: ValidationErrorFields) => pipe(
        fs,
        R.reduceWithIndex(strOrd)([], (key: string, fieldErrs: FieldValidationErrors, vErrs: ValidationErrorsU) =>
          fieldErrs.concat(fieldValidationErrorsU(config)(O.some(nextModelField(key)(modelField)))(vErrs))
        )
      ),
      (is: ValidationErrorIndices) => pipe(
        is,
        RM.reduceWithIndex(N.Ord)([], (key: number, fieldErrs: FieldValidationErrors, vErrs: ValidationErrorsU) =>
          fieldErrs.concat(fieldValidationErrorsU(config)(O.some(nextModelField(key)(modelField)))(vErrs))
        )
      ),
    )(errs);

export const validationErrorsC = t.type({ validationErrors: stringRecordC(validationErrorsUC()) });
export type ValidationErrorsC = typeof validationErrorsC;
export type ValidationErrors = t.TypeOf<ValidationErrorsC>;

export const fieldValidationErrors = (config: BLConfigWithLog) => (errs: ValidationErrors): FieldValidationErrors =>
  R.reduceWithIndex(strOrd)([], (key: string, fieldErrs: FieldValidationErrors, vErrs: ValidationErrorsU) =>
    fieldErrs.concat(fieldValidationErrorsU(config)(O.some(key))(vErrs))
  )(errs.validationErrors);
