import * as E from "fp-ts/lib/Either";
import type * as O from "fp-ts/lib/Option";
import { filter, tryCatch } from "fp-ts/lib/Option";
import * as s from "fp-ts/lib/string";

export const parseNumber = (x: unknown): O.Option<number> =>
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  filter((n: number) => !isNaN(n))(tryCatch(() => parseInt(`${x}`)));

export const parseNumberOrString = (a: number | string) => s.isString(a) ? E.left(parseNumber(a)) : E.right(a);
