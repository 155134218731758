export interface WindowSize {
  width: number;
  outerWidth: number;
  height: number;
  outerHeight: number;
}

export const getWindowSize = (): WindowSize => ({
  width: window.innerWidth,
  outerWidth: window.outerWidth,
  height: window.innerHeight,
  outerHeight: window.outerHeight,
});
