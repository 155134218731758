import type { BLConfigWithLog } from "@scripts/bondlink";

export const isBondlinkUrl = (config: BLConfigWithLog): boolean => window.location.origin === config.baseUrl;

export const originOf = (l: { protocol: string, host: string, origin?: string }): string => {
  return typeof l.origin === "string" ? l.origin : `${l.protocol}//${l.host}`;
};

export const urlOf = (l: { protocol: string, host: string, origin?: string, pathname: string, search: string }): string => {
  return `${originOf(l)}${l.pathname}${l.search.length === 0 ? "" : l.search}`;
};

export const pathOf = (l: { protocol: string, host: string, origin?: string, pathname: string, search: string, hash: string }): string => {
  return `${urlOf(l)}${l.hash}`;
};

export const currentUrl = (): string => {
  return urlOf(window.location);
};

export const currentPath = (): string => {
  return pathOf(window.location);
};

export const currentOrigin = (): string => {
  return originOf(window.location);
};

export const currentAppSection = (): "issuer-portal" | "investor-portal" | "admin" | "issuer-sites" | "deal-portal" => window.location.pathname.startsWith("/issuer-portal/")
  ? "issuer-portal" : window.location.pathname.startsWith("/investor-portal/")
    ? "investor-portal" : window.location.pathname.startsWith("/deal-portal/")
      ? "deal-portal" : window.location.pathname.startsWith("/admin/")
        ? "admin"
        : "issuer-sites";
