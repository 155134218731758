import { Static } from "@scripts/bondlinkStatic";
import type { QEvent } from "@scripts/dom/q";
import { Q } from "@scripts/dom/q";

import { Lightbox } from "./lightbox";

export class ImageGallery {
  static init(): void {
    Q.all(".image-gallery-preview").forEach((gallery: Q) => {
      gallery.listen("click", (e: QEvent) => {
        Q.body.append(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          Lightbox.buildLayout(e.thisElement.children("img") as Array<Q<HTMLImageElement>>)
            .addClass("showing"));
        window.setTimeout(
          () => Lightbox.instance.addClass("show").removeClass("showing"),
          Static.baseTransitionDelay);
        Lightbox.init();
      });
    });
  }
}
