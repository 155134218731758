import type { Lazy } from "fp-ts/lib/function";

export function fold<A>(onTrue: A, onFalse: A): (b: boolean) => A;
export function fold<A>(onTrue: A): (onFalse: A) => (b: boolean) => A;
export function fold<A>(onTrue: A, onFalseO?: A): ((b: boolean) => A) | ((onFalse: A) => (b: boolean) => A) {
  const ret = (onF: A) => (b: boolean) => b ? onTrue : onF;
  return onFalseO ? ret(onFalseO) : ret;
}

export function foldL<A>(onTrue: Lazy<A>, onFalse: Lazy<A>): (b: boolean) => A;
export function foldL<A>(onTrue: Lazy<A>): (onFalse: Lazy<A>) => (b: boolean) => A;
export function foldL<A>(onTrue: Lazy<A>, onFalseO?: Lazy<A>): ((b: boolean) => A) | ((onFalse: Lazy<A>) => (b: boolean) => A) {
  const ret = (onF: Lazy<A>) => (b: boolean) => b ? onTrue() : onF();
  return onFalseO ? ret(onFalseO) : ret;
}
