import { findFirst } from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { chain, map } from "fp-ts/lib/Option";

import { Q } from "@scripts/dom/q";

export const landerInit = () =>
  Q.all(".show-link").forEach(link => pipe(
    link.parent(),
    chain(p => pipe(p.children(), findFirst(e => e.hasClass("truncate-fade")))),
    map(expando => {
      const fullHeight = expando.element.scrollHeight;
      link.listen("click", () => {
        if (link.hasClass("open")) {
          link.removeClass("open");
          expando.removeClass("open");
          expando.removeInlineStyle("maxHeight");
        } else {
          link.addClass("open");
          expando.addClass("open");
          expando.setInlineStyle("maxHeight", `${fullHeight}px`);
        }
      });
    })
  ));
