import type * as TE from "fp-ts/lib/TaskEither";

import type { BLConfigWithLog } from "@scripts/bondlink";
import type { IssuerOverviewCard, IssuerOverviewCards } from "@scripts/generated/models/issuerOverviewCard";
import * as V2Router from "@scripts/generated/routers/v2Router";
import type { IssuerFeedParams } from "@scripts/routes/routing/investorportal/baseRoutes";
import { issuerFeedDaysToLoad } from "@scripts/routes/routing/params/date";

import * as CorpRouter from "../generated/routers/corpRouter";
import { _apiFetchUnsafe, apiFetch, apiFetchWithCred, apiFetchWithCredResp, makeBatchedErrorHandlingRequest } from "./methods";

export type IssuersFilter = "all" | "subscribed";

export type RespData<T> = T extends TE.TaskEither<unknown, infer U> ? U : never;

const fetchUpcomingOfferingsUnsafe = (config: BLConfigWithLog) => _apiFetchUnsafe(config)(V2Router.investorPortalOfferingsControllerSubscribedUpcoming({ page: 1 }));
const fetchPastOfferingsUnsafe = (config: BLConfigWithLog) => _apiFetchUnsafe(config)(V2Router.investorPortalOfferingsControllerSubscribedPast({ page: 1 }));
const fetchUnscheduledOfferingsUnsafe = (config: BLConfigWithLog) => _apiFetchUnsafe(config)(V2Router.investorPortalOfferingsControllerSubscribedUnscheduled({ page: 1 }));
const fetchRecentIssuersUnsafe = (config: BLConfigWithLog) => _apiFetchUnsafe(config)(V2Router.investorPortalInvestorControllerRecentlyViewedIssuers());
const fetchSubscribedIssuersUnsafe = (config: BLConfigWithLog) => _apiFetchUnsafe(config)(V2Router.investorPortalInvestorControllerSubscribedIssuers({ page: 1 }));
export const fetchUpcomingOfferings = (config: BLConfigWithLog) => apiFetch(config)(V2Router.investorPortalOfferingsControllerSubscribedUpcoming({ page: 1 }));


export const fetchAllOfferings = (config: BLConfigWithLog) => makeBatchedErrorHandlingRequest({
  upcomingOfferings: fetchUpcomingOfferingsUnsafe(config),
  pastOfferings: fetchPastOfferingsUnsafe(config),
  unscheduledOfferings: fetchUnscheduledOfferingsUnsafe(config),
});

const fetchRecentRfpsUnsafe = (config: BLConfigWithLog) => _apiFetchUnsafe(config)(V2Router.investorPortalRfpsControllerRecent({ page: 1 }));
const fetchActiveAndUnscheduledRfpsUnsafe = (config: BLConfigWithLog) => _apiFetchUnsafe(config)(V2Router.investorPortalRfpsControllerActiveAndUnscheduled({ page: 1 }));
export const fetchActiveAndUnscheduledRfps = (config: BLConfigWithLog) => apiFetch(config)(V2Router.investorPortalRfpsControllerActiveAndUnscheduled({ page: 1 }));

export const fetchAllRfps = (config: BLConfigWithLog) => makeBatchedErrorHandlingRequest({
  past: fetchRecentRfpsUnsafe(config),
  upcoming: fetchActiveAndUnscheduledRfpsUnsafe(config),
});

export type RecentAndSubscribedIssuers = { recentIssuers: ReadonlyArray<IssuerOverviewCard>, subscribedIssuers: IssuerOverviewCards };
export const fetchRecentAndSubscribedIssuers = (config: BLConfigWithLog) => makeBatchedErrorHandlingRequest({
  recentIssuers: fetchRecentIssuersUnsafe(config),
  subscribedIssuers: fetchSubscribedIssuersUnsafe(config),
});

export const fetchPublishedIssuersAndActivities = (
  config: BLConfigWithLog,
  params: Pick<IssuerFeedParams, "allOrSubscribed">
) =>
  makeBatchedErrorHandlingRequest({
    activities: fetchIssuerActivitiesUnsafe(config)({ ...params, duration: issuerFeedDaysToLoad }),
    issuers: fetchPublishedIssuersUnsafe(config),
  });

export const fetchPublishedIssuersAndSubscribedActivities = (config: BLConfigWithLog) =>
  makeBatchedErrorHandlingRequest({
    activities: fetchSubscribedIssuerActivitiesUnsafe(config)({ days: issuerFeedDaysToLoad }),
    issuers: fetchPublishedIssuersUnsafe(config),
  });

const fetchPublishedIssuersUnsafe = (config: BLConfigWithLog) =>
  _apiFetchUnsafe(config)(V2Router.investorPortalInvestorControllerPublishedIssuersByDateRange({ dateRange: issuerFeedDaysToLoad }));

const fetchAllIssuerActivitiesUnsafe =
  (config: BLConfigWithLog) =>
    (params: V2Router.InvestorPortalIssuerActivityControllerAllParams) =>
      _apiFetchUnsafe(config)(V2Router.investorPortalIssuerActivityControllerAll(params));

const fetchSubscribedIssuerActivitiesUnsafe = (config: BLConfigWithLog) =>
  (params: V2Router.InvestorPortalIssuerActivityControllerAllParams) =>
    _apiFetchUnsafe(config)(V2Router.investorPortalIssuerActivityControllerSubscribed(params));

const fetchIssuerActivitiesUnsafe =
  (config: BLConfigWithLog) =>
    ({ duration, allOrSubscribed }: Omit<IssuerFeedParams, "types">) => {
      const call = allOrSubscribed === "all"
        ? fetchAllIssuerActivitiesUnsafe(config)
        : fetchSubscribedIssuerActivitiesUnsafe(config);
      return call({ days: duration });
    };

export const fetchSubscribedActivities =
  (config: BLConfigWithLog) =>
    (params: V2Router.InvestorPortalIssuerActivityControllerSubscribedParams) =>
      apiFetch(config)(V2Router.investorPortalIssuerActivityControllerSubscribed(params));

export const updateOfferingSubscriptionRequest =
  (config: BLConfigWithLog) =>
    (params: V2Router.InvestorPortalOfferingsControllerUpdateSubscriptionParams) =>
      apiFetchWithCred(config)(
        V2Router.investorPortalOfferingsControllerUpdateSubscription(params)
      )({});

export const updateRfpSubscriptionRequest =
  (config: BLConfigWithLog) =>
    (params: V2Router.InvestorPortalRfpsControllerUpdateSubscriptionParams) =>
      apiFetchWithCred(config)(
        V2Router.investorPortalRfpsControllerUpdateSubscription(params)
      )({});

export const subscribeToNewsletter =
  (config: BLConfigWithLog) =>
    apiFetchWithCredResp(config)(V2Router.investorPortalMarketingEmailsControllerSubscribe())({});

export const unsubscribeFromNewsletter =
  (config: BLConfigWithLog) =>
    apiFetchWithCredResp(config)(V2Router.investorPortalMarketingEmailsControllerUnsubscribe())({});

export const updateIssuerNotifications =
  (config: BLConfigWithLog) =>
    (params: V2Router.InvestorPortalNotificationSubscriptionsControllerUpdateIssuerSubscriptionParams) =>
      apiFetchWithCredResp(config)(V2Router.investorPortalNotificationSubscriptionsControllerUpdateIssuerSubscription(params))({});

export const issuerRequest =
  (config: BLConfigWithLog) =>
    (issuer: string, captcha: string) =>
      apiFetchWithCred(config)(CorpRouter.corporateIssuerRequest())({ issuer, captcha });

export const fetchInvestorSectors =
  (config: BLConfigWithLog) =>
    apiFetch(config)(V2Router.investorPortalInvestorControllerGetInvestorSectors());
