import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { QEvent } from "../dom/q";
import { Q } from "../dom/q";

export function initCardLinks(): void {
  Q.body.listen("click", ".card[data-href]", cardLinkListener);
}

export function cardLinkListener(e: QEvent): void {
  if (pipe(e.originationElement, O.chain((oe: Q) => oe.closest<HTMLAnchorElement>("a")), O.isNone)
    && pipe(e.originationElement, O.chain((oe: Q) => oe.closest<HTMLButtonElement>("button")), O.isNone)
  ) {
    pipe(
      O.bindTo("href")(e.selectedElement.getData("href")),
      O.bind("target", () => pipe(e.selectedElement.getData("target"), O.alt(() => O.some("_self")))),
      O.map(({ href, target }: { href: string, target: string }) => window.open(href, target))
    );
  }
}
