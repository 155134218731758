import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { chain, fold, map, none, some } from "fp-ts/lib/Option";

import { Static } from "@scripts/bondlinkStatic";
import { Config } from "@scripts/csr-only/config";
import { makeConfigLogger } from "@scripts/util/log";

import type { QEvent } from "../dom/q";
import { CachedElements, Q } from "../dom/q";
import type { UnsafeHtml } from "../dom/unsafeHtml";
import { html } from "../dom/unsafeHtml";
import { invoke1, invoke2 } from "../util/invoke";

export type AlertType = "alert-success" | "alert-info" | "alert-warning" | "alert-danger";
export type AlertStyle = "normal" | "pill";

const config = Config(makeConfigLogger());

export class Alert {
  static readonly closeTimeout = 5000;
  static readonly alertContainerSelector = "#header-alert-top-container";

  static get container(): Option<Q> { return Q.one(Alert.alertContainerSelector); }

  static init(): void {
    Q.body.listen("click", ".alert .btn-close", (e: QEvent) => pipe(
      e.originationElement,
      chain(invoke1("closest")(".alert")),
      map(Alert.close)
    ));
  }

  static close(a: Q): void {
    const closeSimple = () => {
      a.removeClass("show");
      window.setTimeout(a.remove, Static.baseTransitionDelay);
    };

    pipe(
      Alert.container,
      fold(
        closeSimple,
        (alertHeader: Q) => {
          const aHeight = a.getHeightNoMargin();
          pipe(alertHeader.closest("header"), fold(closeSimple, (h: Q) => {
            h.setInlineStyle("transform", `translateY(-${aHeight}px)`);
            window.setTimeout(
              () => {
                h.addClass("alert-removed").removeInlineStyle("transform").reflow();
                a.remove();
                h.removeClass("alert-removed");
              },
              Static.baseTransitionDelay);
          }));
        }));
  }

  static roleForAlert(tpe: AlertType): "status" | "alert" {
    switch (tpe) {
      case "alert-success":
      case "alert-info":
        return "status";
      case "alert-warning":
      case "alert-danger":
        return "alert";
    }

    return config.exhaustive(tpe);
  }

  static message(id: string, tpe: AlertType, body: UnsafeHtml, extraContent: Option<UnsafeHtml> = none, classes: string[] = []): Q {
    return Q.createElement("div", [
      invoke1("setId")(CachedElements.normalizeId(id)),
      invoke1("addClass")(["alert", "fade", "show", tpe].concat(classes)),
      invoke2("setAttrUnsafe")("role", Alert.roleForAlert(tpe)),
    ], [
      Q.createElement("div", [invoke1("addClass")("alert-body"), invoke1("setInnerHtml")(body)], []),
    ].concat(fold(() => [], (h: UnsafeHtml) => [Q.createElement("div", [invoke1("setInnerHtml")(h)], [])])(extraContent)));
  }

  static dismissibleMessage(id: string, tpe: AlertType, body: UnsafeHtml, classes: string[] = []): Q {
    return Alert.message(id, tpe, body,
      some(html`<button type="button" class="btn-close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>`), classes);
  }

  static showInContainer(container: Q, id: string, tpe: AlertType, body: UnsafeHtml, closeTimeout: number = Alert.closeTimeout, classes: string[] = []): void {
    const flash = Alert.dismissibleMessage(id, tpe, body, classes);
    fold<Q, (other: Q) => Q>(() => container.prepend, invoke1("replaceWith"))(container.one(`#${CachedElements.normalizeId(id)}`))(flash);
    if (closeTimeout > 0) {
      window.setTimeout(() => Alert.close(flash), closeTimeout);
    }
  }

  static show(id: string, tpe: AlertType, body: UnsafeHtml, closeTimeout: number = Alert.closeTimeout, classes: string[] = []): void {
    map((q: Q) => Alert.showInContainer(q, id, tpe, body, closeTimeout, classes))(Alert.container);
  }
}
