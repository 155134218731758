import type { QEvent } from "@scripts/dom/q";
import { Q } from "@scripts/dom/q";
import { Modal } from "@scripts/ui/modal";
import { requestAnimFrame } from "@scripts/util/requestAnimFrame";

export class Disclaimer {
  static readonly modalId = "dueDisclaimerModal";

  static init(): void {
    Q.body.listen(Modal.openedEvent, `#${Disclaimer.modalId}`, (ev: QEvent) => {
      requestAnimFrame(() => ev.selectedElement.setAttr("scrollTop", 0));
    });
  }
}
