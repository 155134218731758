/* !
 * Copyright 2015-2019 BondLink, Inc.
 */

import { globals } from "@scripts/generated/globals";

import { bootstrap } from "./bootstrap";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
(<any>window).globals = globals;

import { Config } from "@scripts/csr-only/config";
import { bootstrapSentry } from "@scripts/sentry";
import { makeConfigLogger } from "@scripts/util/log";

const config = Config(makeConfigLogger());

bootstrapSentry(config, "Sites");

bootstrap(config)(true);
