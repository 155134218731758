/// <reference types="grecaptcha" />

import { flow, identity, pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import * as TE from "fp-ts/lib/TaskEither";

import { eventName } from "@scripts/bondlink";
import type { BLWindow } from "@scripts/generated/models/blWindow";

import { Q } from "../dom/q";
import { invoke1 } from "../util/invoke";
import { trimFilter } from "../util/trimFilter";

export type RC = ReCaptchaV2.ReCaptcha;

export class Recaptcha {
  static readonly eventNamespace = "recaptcha";
  static readonly loadEvent = eventName(Recaptcha.eventNamespace, "load");

  static get grecaptcha(): Option<RC> {
    return pipe(
      O.fromNullable(window.grecaptcha),
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      O.filter(() => (window as unknown as BLWindow<unknown>).BLConfig.recaptchaLoaded || false),
    );
  }

  static init() {
    O.fold(
      () => { window.setTimeout(Recaptcha.init, 250); },
      (recaptcha: RC) => { Q.root.triggerCustom(Recaptcha.loadEvent, recaptcha); }
    )(Recaptcha.grecaptcha);
  }

  static execute(widgetId: Option<number> = O.none): TE.TaskEither<unknown, string> {
    return O.fold<string, TE.TaskEither<unknown, string>>(
      () => TE.tryCatch(
        () => O.fold(
          () => Promise.reject(new Error("Recaptcha not loaded")),
          (rc: RC) => new Promise((resolve: (s: string) => void, reject: (e: unknown) => void) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
            (<any>window).recaptchaCb = () => {
              O.fold(() => reject(new Error("Failed to get recaptcha response")), resolve)(Recaptcha.getResponse(widgetId));
            };
            rc.execute(O.toUndefined(widgetId));
          })
        )(Recaptcha.grecaptcha),
        identity
      ),
      TE.of
    )(Recaptcha.getResponse(widgetId));
  }

  static reset(widgetId: Option<number> = O.none): void {
    O.chain((rc: RC) => O.tryCatch(() => rc.reset(O.toUndefined(widgetId))))(Recaptcha.grecaptcha);
  }

  static getResponse(widgetId: Option<number> = O.none): Option<string> {
    return O.chain(flow(invoke1("getResponse")(O.toUndefined(widgetId)), (s: string) => trimFilter(s)))(Recaptcha.grecaptcha);
  }
}
