// This file was generated by postcss-transpiler. Do not edit by hand.

export const lightbox = {
  ".lightbox-overlay": {
    css: ".lightbox-overlay",
    html: "lightbox-overlay",
    attrs: {
      ".hiding": {
        css: ".hiding",
        html: "hiding"
      },
      ".show": {
        css: ".show",
        html: "show"
      },
      ".showing": {
        css: ".showing",
        html: "showing"
      }
    },
    ".lightbox-controls": {
      css: ".lightbox-controls",
      html: "lightbox-controls"
    },
    ".btn-link": {
      css: ".btn-link",
      html: "btn-link",
      "svg": {
        css: "svg",
        html: "svg"
      }
    },
    ".btn-close": {
      css: ".btn-close",
      html: "btn-close",
      attrs: {
        ".no-svg": {
          css: ".no-svg",
          html: "no-svg"
        }
      }
    },
    ".nav-btns": {
      css: ".nav-btns",
      html: "nav-btns",
      "span": {
        css: "span",
        html: "span"
      }
    },
    ".slide-container": {
      css: ".slide-container",
      html: "slide-container"
    },
    ".viewport": {
      css: ".viewport",
      html: "viewport"
    },
    ".slides": {
      css: ".slides",
      html: "slides"
    },
    ".slide": {
      css: ".slide",
      html: "slide",
      "img": {
        css: "img",
        html: "img"
      }
    },
    ".caption": {
      css: ".caption",
      html: "caption",
      ".data-point-row": {
        css: ".data-point-row",
        html: "data-point-row",
        "dd": {
          css: "dd",
          html: "dd"
        }
      },
      ".data-vertical": {
        css: ".data-vertical",
        html: "data-vertical",
        "dd": {
          css: "dd",
          html: "dd"
        }
      },
      "p": {
        css: "p",
        html: "p"
      }
    },
    ".thumbnail-container": {
      css: ".thumbnail-container",
      html: "thumbnail-container"
    },
    ".thumbnails": {
      css: ".thumbnails",
      html: "thumbnails"
    },
    ".thumbnail": {
      css: ".thumbnail",
      html: "thumbnail",
      attrs: {
        ".current": {
          css: ".current",
          html: "current"
        }
      },
      "img": {
        css: "img",
        html: "img"
      }
    },
    ".current": {
      css: ".current",
      html: "current"
    }
  },
  ".current": {
    css: ".current",
    html: "current"
  },
  ".data-point-row": {
    css: ".data-point-row",
    html: "data-point-row",
    ".lightbox-overlay": {
      css: ".lightbox-overlay",
      html: "lightbox-overlay",
      ".caption": {
        css: ".caption",
        html: "caption",
        "dd": {
          css: "dd",
          html: "dd"
        }
      }
    }
  },
  ".data-vertical": {
    css: ".data-vertical",
    html: "data-vertical",
    ".lightbox-overlay": {
      css: ".lightbox-overlay",
      html: "lightbox-overlay",
      ".caption": {
        css: ".caption",
        html: "caption",
        "dd": {
          css: "dd",
          html: "dd"
        }
      }
    }
  }
};
