import { filterMap, findFirst, sort, unzip } from "fp-ts/lib/Array";
import { now } from "fp-ts/lib/Date";
import { pipe } from "fp-ts/lib/function";
import * as n from "fp-ts/lib/number";
import * as O from "fp-ts/lib/Option";
import { contramap } from "fp-ts/lib/Ord";
import { randomInt } from "fp-ts/lib/Random";

import { onResize } from "@scripts/dom/onResize";
import { onScroll } from "@scripts/dom/onScroll";
import type { QEvent } from "@scripts/dom/q";
import { Q } from "@scripts/dom/q";
import * as windowLocation from "@scripts/syntax/windowLocation";
import * as invoke from "@scripts/util/invoke";
import { tap } from "@scripts/util/tap";
import { trimFilter } from "@scripts/util/trimFilter";

import { scrollTo } from "./dom/scrollTo";
import { MainNav } from "./mainNav";

interface Range { start: number, end: number }

export class JumpLinks {
  private static scrolling = false;

  static init(): void {
    JumpLinks.initClick();
    JumpLinks.initScroll();
  }

  static getTarget(a: Q<HTMLAnchorElement>): O.Option<Q> {
    return O.chain(Q.body.one)(trimFilter(a.getAttr("hash")));
  }

  static setCurrent(t: Q<HTMLAnchorElement> | [Q<HTMLAnchorElement>, Range]): void {
    O.map((link: Q) => {
      link.siblings(".jump-link").forEach(invoke.invoke1("removeClass")("current"));
      link.addClass("current");
    })((Q.isQ(t) ? t : t[0]).closest(".jump-link"));
  }

  static jumpTo(target: Q, src: O.Option<Q<HTMLAnchorElement>>): void {
    if (!MainNav.stuck && target.getOffsetWithMargin().top > MainNav.blBarHeight) {
      O.map(MainNav.stick)(MainNav.nav);
    }

    JumpLinks.scrolling = true;
    scrollTo(target, () => JumpLinks.scrolling = false);
    O.map(JumpLinks.setCurrent)(src);
  }

  static initClick(): void {
    Q.body.listen("click", ".jump-link a", (e: QEvent<"click", HTMLAnchorElement>) => {
      if (e.selectedElement.getRawHref().startsWith("#")
        || (e.selectedElement.element.hash.length > 0 && windowLocation.urlOf(e.selectedElement.element) === windowLocation.currentUrl())) {
        e.preventDefault();
        O.map((x: Q) => JumpLinks.jumpTo(x, O.some(e.selectedElement)))(JumpLinks.getTarget(e.selectedElement));
      }
    });
  }

  static getTargetPositions(links: Array<Q<HTMLAnchorElement>>): Array<[Q<HTMLAnchorElement>, Range]> {
    return sort(contramap((_: [Q<HTMLAnchorElement>, Range]) => _[1].start)(n.Ord))(
      filterMap((a: Q<HTMLAnchorElement>) => O.map((t: Q): [Q<HTMLAnchorElement>, Range] => {
        const start = t.getOffsetWithMargin().top;
        return [a, { start: start - 1, end: start + t.getHeightWithMargin() }];
      })(JumpLinks.getTarget(a)))(links));
  }

  static isInRange = (scrollY: number) => ([, range]: [Q<HTMLAnchorElement>, Range]): boolean => {
    const bH = MainNav.blBarHeight;
    const sY = scrollY + bH + Q.remToPx(1);
    const wH = window.innerHeight - bH;
    return sY >= range.start - (wH * 0.85) && sY < range.end - (wH * 0.15);
  };

  static dynamicId(el: Q): string {
    return pipe(
      O.fromNullable(el.getAttr("id")), O.filter((x: string) => x.trim() !== ""),
      O.getOrElse(() => tap((id: string) => el.setAttr("id", id))(`jump-links-dynamic-id-${randomInt(0, now())()}`)));
  }

  static initScroll(): void {
    const [scrollFns, resizeFns] = unzip(Q.all(".jump-links").map((container: Q): [(y: number) => void, () => void] => {
      pipe(
        O.bindTo("src")(container.getData("dynamic-src")),
        O.bind("selector", () => container.getData("dynamic-selector")),
        O.map(({ selector, src }: { selector: string, src: string }) =>
          pipe(
            Q.one(src),
            O.map(invoke.invoke1("all")(selector)),
            O.map(invoke.invoke1("forEach")((toAdd: Q) =>
              container.append(Q.createElement("div", [invoke.invoke1("addClass")("jump-link")],
                [Q.createElement("a", [
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  invoke.invoke2("setAttr")(<keyof HTMLAnchorElement>"href", `#${JumpLinks.dynamicId(toAdd)}`),
                  invoke.invoke1("setInnerHtml")(toAdd.getInnerHtml())], [])])))))));

      const links = container.all<HTMLAnchorElement>('.jump-link a[href^="#"]');
      let positions = JumpLinks.getTargetPositions(links);

      return [
        (scrollY: number) => pipe(
          findFirst(JumpLinks.isInRange(scrollY))(positions),
          O.map(JumpLinks.setCurrent)),
        () => positions = JumpLinks.getTargetPositions(links),
      ];
    }));

    const scrollFn = (scrollY: number) => JumpLinks.scrolling || scrollFns.forEach(invoke.invokeSelf1(scrollY));

    scrollFn(Q.getScrollTop());
    onScroll(scrollFn);

    onResize(() => resizeFns.forEach(invoke.invokeSelf0));
  }
}
