import autobind from "autobind-decorator";
import * as E from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import type { IO } from "fp-ts/lib/IO";
import { map as mapIO } from "fp-ts/lib/IO";
import { IORef } from "fp-ts/lib/IORef";
import * as O from "fp-ts/lib/Option";
import { not } from "fp-ts/lib/Predicate";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as Sep from "fp-ts/lib/Separated";

import type { UnsafeHtml } from "../dom/unsafeHtml";
import type { ApiError } from "../generated/models/apiErrors";
import type { ValidationError } from "../generated/models/validationError";
import { eq } from "../util/eq";
import { prop } from "../util/prop";
import { tap } from "../util/tap";
import type { ModelField } from "./modelField";
import type { FieldApiErrors, ResponseErrors } from "./responseErrors";
import type { FieldValidationErrors } from "./validationErrors";

interface BasilSettings<El> {
  element: El | undefined;
  inputErrorClass: string;
  createErrorsWrapper: () => El | undefined;
  createExpectedErrorElement: () => El | undefined;
  createUnexpectedErrorElement: () => El | undefined;
  classHandler: (element: El) => O.Option<El>;
  formatError: (basil: Basil<El>, modelField: ModelField, error: ApiError | ValidationError) => UnsafeHtml;
  showUnexpectedErrors: (errors: ReadonlyArray<El>) => void;

  dom: {
    // selectors
    getOne: (container: O.Option<El>, selector: string) => O.Option<El>;
    getAll: (container: O.Option<El>, selector: string) => El[];
    closest: (el: El | undefined, selector: string) => O.Option<El>;

    // attrs & classes
    getAttr: (el: El | undefined, attr: string) => O.Option<string>;
    addClass: (el: El | undefined, klass: string) => El | undefined;
    removeClass: (el: El | undefined, klass: string) => El | undefined;

    // DOM manipulation
    append: (container: El | undefined, el: El | undefined) => El | undefined;
    remove: (el: El | undefined) => void;

    // element operations
    equals: (el1: El | undefined, el2: El | undefined) => boolean;
    getInnerText: (el: El) => O.Option<string>;
    setInnerHtml: (el: El, html: UnsafeHtml) => El | undefined;
  };
}

@autobind
export class Basil<El> {
  private static readonly errorClass = "basil-errors";

  readonly getErrors: IO<ResponseErrors>;
  readonly isValid: IO<boolean>;
  readonly settings: BasilSettings<El>;
  private readonly errors: IORef<ResponseErrors>;

  constructor(settings: BasilSettings<El>) {
    this.settings = settings;
    this.errors = new IORef(E.left([]));
    this.getErrors = this.errors.read;
    this.isValid = pipe(this.getErrors, mapIO(flow(E.toUnion, prop("length"), eq(0))));
  }

  clearFormErrors(): void {
    this.settings.dom.getAll(O.fromNullable(this.settings.element), ".alert").forEach(el => this.settings.dom.remove(el));
  }

  clearErrorsFromContainer(field: El): void {
    this.settings.dom.getAll(
      O.fromNullable(this.settings.dom.removeClass(field, this.settings.inputErrorClass)),
      `.${Basil.errorClass}`
    ).forEach(el => this.settings.dom.remove(el));
  }

  updateErrors(f: (es: ResponseErrors) => ResponseErrors): void {
    return this.errors.modify(flow(f, tap(this._updateDOM)))();
  }

  clearErrors(field: El | undefined): void {
    const isNotFieldErr = not(<E>([modelField]: [ModelField, E]) =>
      pipe(
        this.settings.dom.getAttr(field, "name"),
        O.fold(() => false, eq(modelField.toString())))
      || pipe(this.settings.dom.getAttr(field, "data-for"), O.fold(() => false, (f: string) => pipe(
        this.settings.dom.getOne(O.fromNullable(this.settings.element), this.fieldNameForModelFieldSurrogate(modelField)),
        O.chain(el => this.settings.dom.getAttr(el, "data-for")),
        O.fold(() => false, eq(f)))))
      || O.isSome(this.settings.dom.closest(field, this.fieldNameForModelFieldSurrogate(modelField))));

    return this.updateErrors(E.bimap(_ => _.filter(isNotFieldErr), _ => _.filter(isNotFieldErr)));
  }

  fieldNameForModelField(modelField: ModelField): string {
    return `[name="${modelField.toString()}"]`;
  }

  fieldNameForModelFieldSurrogate(modelField: ModelField): string {
    return `[data-for="${modelField.toString()}"]`;
  }

  inputOrLabelForModelField(modelField: ModelField): O.Option<El> {
    const fieldName = this.fieldNameForModelField(modelField);
    return pipe(this.settings.dom.getOne(O.fromNullable(this.settings.element), `${fieldName}:not([type="hidden"])`),
      O.alt(() => pipe(this.settings.dom.getOne(O.fromNullable(this.settings.element), fieldName), O.chain(this.labelForField))),
      O.alt(() => this.settings.dom.getOne(O.fromNullable(this.settings.element), this.fieldNameForModelFieldSurrogate(modelField))));
  }

  labelForField(field: El): O.Option<El> {
    return pipe(this.settings.dom.getAttr(field, "name"), O.chain((name: string) => this.settings.dom.getOne(O.none, `label[for="${name}"]`)));
  }

  containerForModelField(modelField: ModelField): O.Option<El> {
    return pipe(
      this.inputOrLabelForModelField(modelField),
      O.chain(this.settings.classHandler),
      O.chain(container => pipe(
        this.settings.dom.getOne(O.fromNullable(container), `.${Basil.errorClass}`),
        O.orElse(() => O.fromNullable(this.createErrorsWrapper(container))),
      )),
    );
  }

  markupForError(modelField: ModelField, error: ApiError | ValidationError): El | undefined {
    return pipe(
      this.containerForModelField(modelField),
      O.fold(
        () => this.settings.createUnexpectedErrorElement(),
        () => this.settings.createExpectedErrorElement(),
      ),
      tap(el => el && this.settings.dom.setInnerHtml(el, this.settings.formatError(this, modelField, error))),
    );
  }

  addErrors(errors: ResponseErrors): void {
    return this.updateErrors(() => errors);
  }

  addErrorsForField(errors: FieldValidationErrors, field: El | undefined): void {
    const isFieldErr = <E>([modelField]: [ModelField, E]): boolean =>
      pipe(this.inputOrLabelForModelField(modelField), O.fold(() => false, el => this.settings.dom.equals(el, field)));
    return this.updateErrors(flow(E.fold(_ => errors.filter(isFieldErr).concat(_.filter(not(isFieldErr))), () => errors), E.left));
  }

  partitionErrors(errors: ResponseErrors): { expected: ResponseErrors, unexpected: ResponseErrors } {
    const isExpectedErr = <E>([modelField]: [ModelField, E]) => O.isSome(this.inputOrLabelForModelField(modelField));
    const { left: unexpected, right: expected } = pipe(
      errors,
      E.fold<FieldValidationErrors, FieldApiErrors, Sep.Separated<ResponseErrors, ResponseErrors>>(
        flow(RA.partition(isExpectedErr), Sep.bimap(E.left, E.left)),
        flow(RA.partition(isExpectedErr), Sep.bimap(E.right, E.right))
      )
    );
    return { expected, unexpected };
  }

  submitEl(): O.Option<El> {
    return this.settings.dom.getOne(O.fromNullable(this.settings.element), "[type=submit]");
  }

  private _updateDOM(errors: ResponseErrors): void {
    if (pipe(errors, flow(E.toUnion, es => es.length > 0))) {
      this.clearFormErrors();
    }

    this.settings.dom.getAll(O.fromNullable(this.settings.element), `.${this.settings.inputErrorClass}`).forEach(this.clearErrorsFromContainer);
    const unexpected = pipe(
      errors,
      E.fold<FieldValidationErrors, FieldApiErrors, ReadonlyArray<El>>(
        RA.chain(([mf, es]) => pipe(es, RA.filterMap(e => this.maybeAppendError(mf, e)))),
        RA.filterMap(([mf, e]) => this.maybeAppendError(mf, e))
      )
    );
    if (unexpected.length > 0) {
      this.settings.showUnexpectedErrors(unexpected);
    }
  }

  private createErrorsWrapper(container: El): El | undefined {
    return pipe(
      this.settings.createErrorsWrapper(),
      tap(wrapper => this.settings.dom.addClass(wrapper, Basil.errorClass)),
      tap(wrapper => this.settings.dom.append(this.settings.dom.addClass(container, this.settings.inputErrorClass), wrapper)),
    );
  }

  private maybeAppendError(modelField: ModelField, error: ApiError | ValidationError): O.Option<El> {
    const markup = this.markupForError(modelField, error);
    return pipe(
      this.containerForModelField(modelField),
      O.fold(
        () => O.fromNullable(markup),
        el => {
          this.settings.dom.append(el, markup);
          return O.none;
        }
      )
    );
  }
}
