import autobind from "autobind-decorator";
import { filterMapWithIndex, head, last, uniq } from "fp-ts/lib/Array";
import { constFalse, constTrue, constVoid, flow, identity, pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { alt, chain, filter, fold as foldO, getOrElse, isSome, map, none, some } from "fp-ts/lib/Option";
import { not } from "fp-ts/lib/Predicate";
import { Key } from "ts-key-enum";

import { eventName } from "@scripts/bondlink";
import { Static } from "@scripts/bondlinkStatic";
import { lightbox } from "@scripts/generated/assets/stylesheets/components/_lightbox";

import { navigationConfirm } from "../dom/navigationConfirm";
import type { QCustomEvent, QEvent } from "../dom/q";
import { CachedElements, Q } from "../dom/q";
import type { UnsafeHtml } from "../dom/unsafeHtml";
import { fold, foldL } from "../util/fold";
import { invoke0, invoke1, invoke2 } from "../util/invoke";
import { prop } from "../util/prop";
import { tap } from "../util/tap";
import { Expandable } from "./expandable";
import { Tooltip } from "./tooltip";

@autobind
export class Modal {
  static readonly toggleSelector = '[data-toggle="modal"]:not([data-auto-open="false"])';
  static readonly modalSelector = ".modal";
  static readonly modalContentSelector = ".modal-content";
  static readonly backgroundSelector = ".modal-background";

  static readonly cache = new CachedElements("modal", Modal.modalSelector, Modal);

  static readonly eventNamespace = "modal";
  static readonly openEvent = eventName(Modal.eventNamespace, "open");
  static readonly openingEvent = eventName(Modal.eventNamespace, "opening");
  static readonly openedEvent = eventName(Modal.eventNamespace, "opened");
  static readonly closeEvent = eventName(Modal.eventNamespace, "close");
  static readonly closingEvent = eventName(Modal.eventNamespace, "closing");
  static readonly closedEvent = eventName(Modal.eventNamespace, "closed");
  static readonly toggleEvent = eventName(Modal.eventNamespace, "toggle");

  element: Q;
  background: Q;

  static init(): void {
    Modal.cache.init();
    Modal.initToggle();
    Modal.initFocus();
    Modal.initOverlay();
    Modal.initTooltips();
    Modal.initExpandable();

    Q.all("[data-show='true']").forEach((m: Q) => Modal.showById(m.getAttr("id")));
  }

  static getModalInDom(id: string): Option<Q> {
    return pipe(
      Q.body.one(`#${CachedElements.normalizeId(id)}`),
      alt(() => Q.body.one(Modal.templateId(id))));
  }

  static allOpen(): Q[] {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return Q.all(Modal.modalSelector).filter(invoke0("isVisible"));
  }

  static toggle(open: boolean): (elOrId: Q | string) => void {
    return (elOrId: Q | string) => map(invoke1("toggle")(open))(Modal.cache.get(elOrId));
  }

  static templateId(id: string): string {
    return `#${CachedElements.normalizeId(id)}-template`;
  }

  static showTemplate(id: string): Option<Q> {
    return pipe(
      Q.one(Modal.templateId(id)),
      map(invoke0("getInnerHtml")),
      chain((h: UnsafeHtml) => Q.parseFragment(h, "text/html")),
      map(Modal.create)
    );
  }

  static showById(id: string): void {
    foldO(() => { Modal.showTemplate(id); }, Modal.toggle(true))(Q.one(`#${CachedElements.normalizeId(id)}`));
  }

  static initToggle(): void {
    Q.body.listen("click", Modal.toggleSelector, Q.prevented(
      (e: QEvent) => {
        map(Modal.showById)(e.selectedElement.getData("target"));
        map((href: string) => navigationConfirm(Modal.cache.get("#exitModal"), href, e.selectedElement.getAttrO("target")))(e.selectedElement.getAttrO("href"));
      })
    );

    Q.body.listen("click", `${Modal.modalSelector} .close-modal`, Q.prevented(
      (e: QEvent) => map(Modal.toggle(false))(e.selectedElement.closest(Modal.modalSelector))));

    Q.body.listen("keyup", `${Modal.modalSelector}-open`, (e: QEvent<"keyup">) => {
      if (e.event.key === Key.Escape) {
        map((modal: Q) => {
          if (isSome(Q.one(lightbox[".lightbox-overlay"].css))) return;

          if (isSome(modal.one(".close-modal"))) {
            Modal.toggle(false)(modal);
          }
        })(Q.one(`${Modal.modalSelector}.show`));
      }
    });

    const triggeredHandler = (f: (m: Q) => boolean) => (e: QEvent) => map((m: Q) => Modal.toggle(f(m))(m))(e.originationElement);
    Q.body.listen(Modal.openEvent, triggeredHandler(constTrue));
    Q.body.listen(Modal.closeEvent, triggeredHandler(constFalse));
    Q.body.listen(Modal.toggleEvent, triggeredHandler(not(invoke0("isVisible"))));

    Q.body.listen(Modal.openingEvent, () => Q.body.addClass("modal-open"));

    Q.body.listen(Modal.closedEvent, () => {
      if (Modal.allOpen().length === 0) {
        Q.body.removeClass("modal-open");
        Q.body.removeInlineStyle("marginRight");
      }
    });

  }

  static initFocus(): void {
    const currentFocus = Q.one(":focus");

    Q.body.listen(Modal.openedEvent, Modal.modalSelector, (ev: QEvent) => map((modal: Q) => {
      const inputs = modal.all(Q.inputSelector).filter(not(invoke0("isDisabled")));
      const submits = modal.all('button[type="submit"]');
      const focusables = modal.all(Q.focusableSelector).filter(not(invoke0("isDisabled")));

      pipe(
        head(inputs),
        alt(() => last(submits)),
        alt(() => head(focusables)),
        map(flow(invoke2("setData")("auto-focus", "true"), prop("element"), invoke0("focus")))
      );

      pipe(
        head(focusables),
        map(flow(invoke1("off")("keydown"), invoke2("listen")("keydown", (e: QEvent<"keydown">) => pipe(
          some(1),
          filter(() => e.event.key === Key.Tab && e.getAttr("shiftKey")),
          map(() => e.preventDefault())
        ))))
      );

      pipe(
        last(focusables),
        map(flow(invoke1("off")("keydown"), invoke2("listen")("keydown", (e: QEvent<"keydown">) => pipe(
          some(1),
          filter(() => e.event.key === Key.Tab && !e.getAttr("shiftKey")),
          map(() => e.preventDefault())
        ))))
      );
    })(ev.originationElement));

    Q.body.listen(Modal.closingEvent, Modal.modalSelector, () => map(flow(prop("element"), tap(invoke0("focus")), invoke0("blur")))(currentFocus));
  }

  static getScrollbarWidth(): number {
    const scrollDiv = Q.createElement("div", [], []).setInlineStyles({
      "overflowY": "scroll",
      "visibility": "hidden",
    }).addClass("scrollbar-measure");

    Q.body.append(scrollDiv);
    const scrollbarWidth = scrollDiv.element.offsetWidth - scrollDiv.element.clientWidth;
    scrollDiv.remove();

    return scrollbarWidth;
  }

  static initOverlay(): void {
    const updateZIndex = (e: Q, base: number, i: number) => e.setInlineStyles({ zIndex: (base - (i * 11)).toString() });

    Q.body.listen(Modal.openingEvent, Modal.modalSelector, (e: QCustomEvent) =>
      filterMapWithIndex((_, m: string | Q) => Modal.cache.get(m))(uniq(Q.eq)(Modal.allOpen().concat([e.selectedElement]))).reverse().forEach((modal: Modal, i: number) => {
        const modalZIndex = modal.element.hasClass("disclaimer-modal") ? Static.zindexes.disclaimerModal : Static.zindexes.modal;
        const bgZindex = modal.element.hasClass("disclaimer-modal") ? Static.zindexes.disclaimerModalBackground : Static.zindexes.modalBackground;
        Q.body.setInlineStyle("marginRight", `${Modal.getScrollbarWidth()}px`);

        updateZIndex(modal.element, modalZIndex, i);
        updateZIndex(modal.background, bgZindex, i);
      }));
  }

  static initTooltips(): void {
    Q.body.listen(Modal.openedEvent, Modal.modalSelector, (e: QCustomEvent) =>
      filterMapWithIndex((_, t: string | Q) => Tooltip.cache.get(t))(pipe(
        e.originationElement,
        map(invoke1("all")(Tooltip.contentSelector)),
        getOrElse<Q[]>(() => [])
      )).forEach(invoke0("update")));
  }

  static initExpandable(): void {
    Q.body.listen(Modal.openedEvent, Modal.modalSelector, Expandable.reinit);
  }

  static createElement(_id: string, title: UnsafeHtml, body: UnsafeHtml, footerO: Option<UnsafeHtml> = none): Q {
    const id = CachedElements.normalizeId(_id);
    const labelId = `${id}-label`;
    return Q.createElement("div", [
      invoke2("setAttrUnsafe")("id", id),
      invoke1("addClass")(["modal", "modal-sm", "fade"]),
      invoke2("setAttrUnsafe")("role", "dialog"),
      invoke2("setAttrUnsafe")("aria-labelledby", labelId),
      invoke2("setAttrUnsafe")("aria-hidden", "true"),
    ], [
      Q.createElement("div", [invoke1("addClass")("modal-viewport")], [
        Q.createElement("div", [invoke1("addClass")(["modal-dialog"])], [
          Q.createElement("div", [invoke1("addClass")("modal-content"),
          invoke2("setData")("closeable", "false")], [
            Q.createElement("div", [invoke1("addClass")("modal-header"), invoke1("addClass")("inverted")], [
              Q.createElement("div", [invoke1("addClass")("modal-title")], [
                Q.createElement("h3", [invoke1("addClass")("my-0"), invoke2("setAttrUnsafe")("id", labelId), invoke1("setInnerHtml")(title)], []),
              ]),
              Q.createElement("button", [
                invoke1("addClass")(["close-modal", "btn-close", "no-decoration"]),
                invoke2("setAttrUnsafe")("type", "button"),
                invoke2("setAttrUnsafe")("aria-label", "Close"),
              ], [Q.createElement("span", [invoke2("setAttrUnsafe")("aria-hidden", "true"), invoke1("setInnerText")("×")], [])]),
            ]),
            Q.createElement("div", [
              invoke1("addClass")("modal-body"),
              invoke1("setInnerHtml")(body),
              e => foldO(() => e, (fh: UnsafeHtml) => e.append(
                Q.createElement("div", [invoke1("addClass")("modal-footer"), invoke1("setInnerHtml")(fh)], [])
              ))(footerO),
            ], []),
          ]),
        ]),
      ]),
    ]);
  }

  static create(modal: Q, onShown: (e: Q) => void = constVoid): Q {
    const modalId = `#${modal.getAttr("id")}`;
    map(invoke0("remove"))(Q.one(modalId));
    modal.listen(Modal.openedEvent, () => onShown(modal)).listen(Modal.closedEvent, modal.remove);
    Q.body.append(modal);
    Modal.toggle(true)(modal);
    return modal;
  }

  constructor(element: Q) {
    this.element = element;
    this.background = Q.createElement("div", [invoke1("addClass")(["modal-background", "fade"])], []);
  }

  getElement(): Q { return this.element; }
  setElement(e: Q): void { this.element = e; }

  toggle(open: boolean): void {
    // Append the background to the body
    open && pipe(
      Q.body.one(".content-container"),
      foldO(() => Q.body.append, d => d.append),
      f => f(this.background)
    );
    // If opening a modal, make sure the main content is showing and the success content is hidden
    pipe(
      some(open),
      filter(identity),
      chain(() => this.element.one("[data-success-container]")),
      map((c: Q) => {
        c.removeClass("d-none");
        pipe(
          c.getData("success-container"),
          chain(Q.one),
          map(invoke1("addClass")("d-none"))
        );
      })
    );

    // Helper to run synchronous and asynchronous effects (after animation) and
    // trigger the opening or closing event on both the element and the background
    const forEachEl = (openSync: (e: Q) => void, openAsync: (e: Q) => void, closeSync: (e: Q) => void, closeAsync: (e: Q) => void) => {
      const els = [this.element, this.background];
      els.forEach(flow(invoke1("triggerCustom")(fold(Modal.openingEvent, Modal.closingEvent)(open)), tap(fold(openSync, closeSync)(open))));
      window.setTimeout(() => els.forEach(flow(tap(fold(openAsync, closeAsync)(open)), invoke1("triggerCustom")(
        fold(Modal.openedEvent, Modal.closedEvent)(open)))), Static.baseTransitionDelay);
    };

    forEachEl(
      // When opening a modal, synchronously set properties to begin the showing animation
      flow(invoke1("setInlineStyles")({ display: "block" }), invoke2("setAttrUnsafe")("aria-hidden", "false"),
        invoke0("reflow"), invoke1("addClass")("showing")),
      // When opening a modal, asynchronously toggle classes once the animation has completed
      flow(invoke1("removeClass")("showing"), invoke1("addClass")("show")),
      // When closing a modal, synchronously add a class to begin the hiding animation
      invoke1("addClass")("hiding"),
      // When closing a modal, asynchronously set properties to hide the element
      // once the animation has completed and remove the backround element
      flow(invoke1("setInlineStyles")({ display: "none" }), invoke2("setAttrUnsafe")("aria-hidden", "true"),
        invoke1("removeClass")(["show", "hiding"]), invoke1("matches")(Modal.backgroundSelector),
        foldL(() => this.background.remove(), constVoid)));
  }
}
