import { constVoid, pipe } from "fp-ts/lib/function";
import { chain, fold, fromNullable, map } from "fp-ts/lib/Option";
import { not } from "fp-ts/lib/Predicate";

import type { QEvent } from "../dom/q";
import { Q } from "../dom/q";
import { eq } from "../util/eq";
import { parseBool } from "../util/parseBool";
import { tap } from "../util/tap";

const LOCK_FORM_CLASS = "form-submitted";
const LOCK_BUTTON_CLASS = "disabled";

export function isFormLocked(formElement: Q<HTMLFormElement>): boolean {
  return formElement.hasClass(LOCK_FORM_CLASS);
}

export function lockForm(formElement: Q<HTMLFormElement>): void {
  formElement.addClass(LOCK_FORM_CLASS);
  formElement.all("button").forEach((e: Q<HTMLButtonElement>) =>
    e.addClass(LOCK_BUTTON_CLASS).setAttrUnsafe("disabled", "disabled"));
}

export function unlockForm(formElement: Q<HTMLFormElement>): void {
  formElement.removeClass(LOCK_FORM_CLASS);
  formElement.all("button").forEach((e: Q<HTMLButtonElement>) =>
    e.removeClass(LOCK_BUTTON_CLASS).removeAttrUnsafe("disabled"));
}

export function isFormChangeWarningEnabled(formElement: Q<HTMLFormElement>): boolean {
  return pipe(formElement.getData("change-warning"), chain(parseBool), fold(() => true, not(eq<boolean>(false))));
}

let formChangeWarningEnabled = true;

export function formChangeWarning(formElement: Q<HTMLFormElement>): void {
  if (isFormChangeWarningEnabled(formElement) && !isFormLocked(formElement)) {
    Q.root.listen("beforeunload", (e: QEvent<"beforeunload">) =>
      (formChangeWarningEnabled
        ? () => tap((msg: string) => pipe(
          e.event,
          fromNullable,
          map((ev: BeforeUnloadEvent) => {
            ev.preventDefault();
            // eslint-disable-next-line @typescript-eslint/no-deprecated
            ev.returnValue = msg;
          })))("You may have unsaved changes.")
        : constVoid)());
  }
}

export function disableFormChangeWarning(): void {
  formChangeWarningEnabled = false;
}
