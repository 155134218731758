import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { filter, fold, map } from "fp-ts/lib/Option";

import { openInSameTab } from "@scripts/routes/router";

import type { Modal } from "../ui/modal";
import { eq } from "../util/eq";

export function navigationConfirm(modalO: Option<Modal>, href: string, target: Option<string>): void {
  map((modal: Modal) =>
    modal.element.listenOnce("click", "button[type=submit]", () =>
      fold(
        () => { openInSameTab(href); },
        () => {
          window.open(href, "_blank");
          modal.toggle(false);
        })(pipe(target, filter(eq("_blank"))))
    ))(modalO);
}
