import { findFirst, findIndex, lookup } from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { alt, chain, exists, filter, fold, getOrElse, isNone, isSome, map, none } from "fp-ts/lib/Option";
import { not } from "fp-ts/lib/Predicate";
import { Key } from "ts-key-enum";
import V from "voca";

import { Static } from "@scripts/bondlinkStatic";
import { Config } from "@scripts/csr-only/config";
import { onResize } from "@scripts/dom/onResize";
import type { QEvent } from "@scripts/dom/q";
import { Q } from "@scripts/dom/q";
import type { UnsafeHtml } from "@scripts/dom/unsafeHtml";
import { html } from "@scripts/dom/unsafeHtml";
import { lightbox } from "@scripts/generated/assets/stylesheets/components/_lightbox";
import { invoke0, invoke1, invoke2 } from "@scripts/util/invoke";
import { makeConfigLogger } from "@scripts/util/log";
import { requestAnimFrame } from "@scripts/util/requestAnimFrame";
interface State {
  currentIndex: number;
  imageOffset: number;
  slides: Array<Q<HTMLDivElement>>;
  thumbnails: Array<Q<HTMLDivElement>>;
}

export class Lightbox {
  static state: State;
  static instance: Q<HTMLElement>;
  static nextBtn: Option<Q<HTMLButtonElement>>;
  static prevBtn: Option<Q<HTMLButtonElement>>;
  static closeBtn: Option<Q<HTMLButtonElement>>;
  static slidesContainer: Option<Q<HTMLDivElement>>;
  static thumbnailContainer: Option<Q<HTMLDivElement>>;
  static viewport: Option<Q<HTMLDivElement>>;
  static imageCounter: Option<Q<HTMLSpanElement>>;

  static update(prevState: State, newIndex?: number): State {
    if (typeof newIndex === "number") {
      const newImageOffset = Lightbox.getImageOffset(prevState, newIndex, Lightbox.viewport);
      return {
        ...prevState,
        currentIndex: newIndex,
        imageOffset: newImageOffset,
      };
    }
    return prevState;
  }

  static init(): void {
    Q.body.setInlineStyle("overflow", "hidden");
    const slides: Array<Q<HTMLDivElement>> = Q.all(".slide");
    const thumbnails: Array<Q<HTMLDivElement>> = Q.all(".thumbnail");
    Lightbox.nextBtn = Q.one(".lightbox-controls .btn-link.next");
    Lightbox.prevBtn = Q.one(".lightbox-controls .btn-link.prev");
    Lightbox.closeBtn = Q.one(".lightbox-controls .btn-close");
    Lightbox.slidesContainer = Q.one(".slides");
    Lightbox.thumbnailContainer = Q.one(".thumbnail-container");
    Lightbox.viewport = Q.one(".viewport");
    Lightbox.imageCounter = Q.one("#image-counter");
    Lightbox.state = Lightbox.update({
      currentIndex: 0,
      imageOffset: 0,
      slides,
      thumbnails,
    });

    // Set width of .images container to 100% * number of slides;
    map((slidesContainer: Q<HTMLDivElement>) => slidesContainer.setInlineStyle("width", `${100 * Lightbox.state.slides.length}%`))(Lightbox.slidesContainer);

    Lightbox.attachEvents();

    Lightbox.resizeImages(Lightbox.state);
    Lightbox.state = Lightbox.update(Lightbox.state, 0);
    Lightbox.render(Lightbox.state);

    requestAnimFrame(() => {
      Lightbox.state = Lightbox.update(Lightbox.state, 0);
      Lightbox.render(Lightbox.state);
    });
  }

  static attachEvents(): void {
    onResize(() => {
      Lightbox.resizeImages(Lightbox.state);
      const translation = Lightbox.getImageOffset(Lightbox.state, Lightbox.state.currentIndex, Lightbox.viewport);
      Lightbox.renderSlides(translation);
    });

    map((btn: Q<HTMLButtonElement>) => btn.listen("click", Lightbox.onNextClick))(Lightbox.nextBtn);

    map((btn: Q<HTMLButtonElement>) => btn.listen("click", Lightbox.onPrevClick))(Lightbox.prevBtn);

    map((btn: Q<HTMLButtonElement>) => {
      btn.listen("click", (e: QEvent<"click", HTMLButtonElement>) => {
        Lightbox.instance.addClass("hiding").removeClass("show");
        window.setTimeout(() => {
          Lightbox.instance.remove();
          Q.body.removeInlineStyle("overflow");
        }, Static.baseTransitionDelay);
        return e;
      });
    })(Lightbox.closeBtn);

    map((sc: Q) => sc.listen("click", Lightbox.onImageClick(this.state.slides, ".slide")))(Lightbox.slidesContainer);
    map((tc: Q) => tc.listen("click", Lightbox.onImageClick(this.state.thumbnails, ".thumbnail")))(Lightbox.thumbnailContainer);

    Q.body.listen("keyup", ".lightbox-open", (e: QEvent<"keyup">) => {
      if (e.event.key === Key.Escape) {
        Lightbox.instance.addClass("hiding").removeClass("show");
        window.setTimeout(() => {
          Lightbox.instance.remove();
          Q.body.removeInlineStyle("overflow")
            .removeClass("lightbox-open")
            .off("keyup", ".lightbox-open");
        }, Static.baseTransitionDelay);
      }
    });
  }

  static onNextClick(): void {
    const currentIndex = Lightbox.state.currentIndex + 1;
    Lightbox.state = Lightbox.update(Lightbox.state, currentIndex);
    Lightbox.render(Lightbox.state);
  }

  static onPrevClick(): void {
    const currentIndex = Lightbox.state.currentIndex - 1;
    Lightbox.state = Lightbox.update(Lightbox.state, currentIndex);
    Lightbox.render(Lightbox.state);
  }

  static onImageClick(images: Q[], selector: string): (e: QEvent) => void {
    return function (e: QEvent): void {
      pipe(
        e.originationElement,
        chain((oe: Q) => oe.closest(selector)),
        map((oe: Q) => {
          map((currentIndex: number) => {
            Lightbox.state = Lightbox.update(Lightbox.state, currentIndex);
            Lightbox.render(Lightbox.state);
          })(findIndex((image: Q) => image.element === oe.element)(images));
        })
      );
    };
  }

  static setButtonState = (isDisabled: boolean) => (btn: Q<HTMLButtonElement>) => {
    btn.setAttr("disabled", isDisabled);
  };

  static resizeImages(state: State): void {
    const viewportWidth = pipe(
      Lightbox.viewport,
      map(invoke0("getWidthNoMargin")),
      getOrElse(() => 0)
    );
    const controlHeight = pipe(
      Q.one(".lightbox-controls"),
      map(invoke0("getHeightWithMargin")),
      getOrElse(() => 0)
    );
    const thumbnailHeight = pipe(
      Lightbox.thumbnailContainer,
      map(invoke0("getHeightWithMargin")),
      getOrElse(() => 0)
    );
    const availheight = window.innerHeight - (controlHeight + thumbnailHeight);

    state.slides.forEach((slide: Q) => {
      slide.setInlineStyles({
        maxHeight: `${availheight}px`,
        maxWidth: `${viewportWidth}px`,
      });
      map((img: Q) => {
        const captionHeight = pipe(
          slide.one(".caption"),
          map(invoke0("getHeightWithMargin")),
          getOrElse(() => 0)
        );
        const height = availheight - captionHeight;
        img.setInlineStyle("maxHeight", `${height}px`);
      })(slide.one("img"));
    });
  }

  static render(state: State): void {
    Lightbox.renderControls(state);

    map(invoke1("removeClass")("current"))(findFirst(invoke1("hasClass")("current"))(state.slides));
    state.slides[state.currentIndex]?.addClass("current");

    Lightbox.renderSlides(state.imageOffset);
    Lightbox.renderThumbnails(state);
  }

  static renderControls(state: State): void {
    map(Lightbox.setButtonState(state.currentIndex >= state.slides.length - 1))(Lightbox.nextBtn);
    map(Lightbox.setButtonState(state.currentIndex <= 0))(Lightbox.prevBtn);

    // Update the "X of Y" text
    map((counter: Q) => {
      counter.setInnerHtml(html`${state.currentIndex + 1} of ${state.slides.length}`);
    })(Lightbox.imageCounter);
  }

  static renderSlides(offset: number): void {
    // Animate the slides moving
    map(
      (slidesContainer: Q) => requestAnimFrame(() => slidesContainer.setInlineStyle("transform", `translateX(${offset}px)`))
    )(Lightbox.slidesContainer);
  }
  static renderThumbnails(state: State): void {
    map(invoke1("removeClass")("current"))(findFirst(invoke1("hasClass")("current"))(state.thumbnails));
    const currentThumbnail = lookup(state.currentIndex, state.thumbnails);
    const previousThumbnail = lookup(state.currentIndex - 1, state.thumbnails);
    const nextThumbnail = lookup(state.currentIndex + 1, state.thumbnails);
    map((t: Q) => t.addClass("current"))(currentThumbnail);

    pipe(
      currentThumbnail,
      filter((t: Q) => !t.isInViewport()),
      map((currentThumb: Q) =>
        map((container: Q) => {
          const direction = (isSome(previousThumbnail) && !pipe(previousThumbnail, exists(invoke0("isInViewport"))))
            || (isNone(previousThumbnail) && pipe(nextThumbnail, exists(invoke0("isInViewport")))) ? -1 : 1;

          const currentScrollPos = container.getAttr("scrollLeft");
          const currentScrollWidth = container.getAttr("scrollWidth");
          const currentOffsetWidth = container.getAttr("clientWidth");
          const maxScrollDistance = currentScrollWidth - currentOffsetWidth;
          const thumbnailScrollWidth = currentThumb.getWidthWithMargin() * 2;
          const nextScrollPos = Math.min(Math.max(currentScrollPos + (thumbnailScrollWidth * direction), 0), maxScrollDistance);

          const duration = 1000;
          const update = () => {
            const step = (currentScrollPos + nextScrollPos) / (duration / 60); // 60fps
            const oldLeft = container.getAttr("scrollLeft");
            const newLeft = oldLeft + (step * direction);
            container.setAttr("scrollLeft", newLeft);

            if ((newLeft * direction) <= nextScrollPos) {
              requestAnimFrame(update);
            }
          };
          requestAnimFrame(update);
        })(Q.one(".thumbnails"))
      )
    );
  }

  static getImageOffset(state: State, newIndex: number, viewport: Option<Q>): number {
    const image = state.slides[newIndex];
    if (image == null) {
      return 0;
    }
    const imageOffsetLeft = pipe(
      image.parent(),
      map((p: Q) => image.getOffsetWithMargin().left - p.getOffsetWithMargin().left),
      getOrElse(() => image.getOffsetWithMargin().left)
    );
    const imageWidth = image.getWidthNoMargin();
    const viewportWidth = pipe(
      viewport,
      map(invoke0("getWidthNoMargin")),
      getOrElse(() => 0)
    );
    const availSpace = (viewportWidth - imageWidth) / 2;
    return (-imageOffsetLeft) + availSpace;
  }

  static buildSlides(images: Array<Q<HTMLImageElement>>): Array<Q<HTMLDivElement>> {
    return images.map((img: Q<HTMLImageElement>) => {
      const cap: Q[] = fold(() => [], (c: string) => [Lightbox.buildCaption(html`${c}`)])(img.getData("caption"));
      const i: Q[] = fold(() => [], (s: string) => [Q.createElement("img", [invoke2("setAttrUnsafe")("src", s)], [])])(Lightbox.getSrcO(img));
      const children: Q[] = i.concat(cap);
      return Q.createElement("div", [invoke1("addClass")("slide")], children);
    });
  }

  static buildCaption(cap: UnsafeHtml): Q<HTMLDivElement> {
    return Q.createElement("div", [invoke1("addClass")("caption")], [
      Q.createElement("p", [invoke1("setInnerHtml")(cap)], []),
    ]);
  }

  static buildThumbnails(images: Array<Q<HTMLImageElement>>): Array<Q<HTMLDivElement>> {
    return images.map((image: Q<HTMLImageElement>) => {
      return Q.createElement("div", [invoke1("addClass")("thumbnail")],
        fold(() => [], (src: string) => [
          Q.createElement("img", [invoke2("setAttrUnsafe")("src", src)], []),
        ])(Lightbox.getSrcO(image))
      );
    });
  }

  static getSrcO(img: Q<HTMLImageElement>): Option<string> {
    return pipe(
      img.getAttrO("src"),
      filter<string>(not(V.isEmpty)),
      alt(() => filter<string>(not(V.isEmpty))(img.getData("src"))),
      alt<string>(() => {
        Config(makeConfigLogger()).log.error("No ‘src’ found for element");
        return none;
      })
    );
  }

  static buildLayout(images: Array<Q<HTMLImageElement>>): Q<HTMLElement> {
    const imgs = images.filter((i: Q<HTMLImageElement>) => isSome(Lightbox.getSrcO(i)));
    const slides = Lightbox.buildSlides(imgs);
    const thumbnails = Lightbox.buildThumbnails(imgs);
    Q.body.addClass("lightbox-open");
    // .lightbox-overlay
    return Lightbox.instance = pipe(
      Q.one("#lightbox-template"),
      map(invoke0("getInnerHtml")),
      chain((h: UnsafeHtml) => Q.parseFragment(h, "text/html")),
      fold(() => Lightbox.fallbackLayout(slides, thumbnails), (l: Q<HTMLElement>) => {
        map((sc: Q) => {
          slides.forEach((s: Q) => sc.append(s));
        })(l.one(".slides"));
        map((tc: Q) => {
          thumbnails.forEach((t: Q) => tc.append(t));
        })(l.one(".thumbnails"));
        return l;
      })
    );
  }

  static fallbackLayout(slides: Array<Q<HTMLElement>>, thumbnails: Array<Q<HTMLDivElement>>): Q<HTMLElement> {
    return Q.createElement("div", [invoke1("addClass")([lightbox[".lightbox-overlay"].css, "fade", "inverted"])], [
      // .lightbox-controls
      Q.createElement("div", [invoke1("addClass")("lightbox-controls")], [
        // .nav-btns
        Q.createElement("div", [invoke1("addClass")("nav-btns")], [
          Q.createElement("button", [invoke1("addClass")(["btn-link", "prev"])], []),
          Q.createElement("span", [invoke1("addClass")("pages"), invoke1("setId")("image-counter")], []),
          Q.createElement("button", [invoke1("addClass")(["btn-link", "next"])], []),
        ]),
        // .btn-close
        Q.createElement("button", [invoke1("addClass")(["btn-close", "no-svg"]), invoke1("setInnerHtml")("&times;")], []),
      ]),
      // .slides-container
      Q.createElement("div", [invoke1("addClass")("slide-container")], [
        Q.createElement("div", [invoke1("addClass")("viewport")], [
          Q.createElement("div", [invoke1("addClass")("slides")], slides),
        ]),
      ]),
      // .thumbnail-container
      Q.createElement("div", [invoke1("addClass")("thumbnail-container")], [
        Q.createElement("div", [invoke1("addClass")("thumbnails")], thumbnails),
      ]),
    ]);
  }
}
