import { fromOption, tryCatch } from "fp-ts/lib/Either";
import { constVoid, pipe } from "fp-ts/lib/function";
import * as IO from "fp-ts/lib/IO";
import type { Option } from "fp-ts/lib/Option";
import { filter, fold, map, none, some } from "fp-ts/lib/Option";
import type { Predicate } from "fp-ts/lib/Predicate";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import { getItem, removeItem, setItem } from "fp-ts-local-storage";
import { jwtDecode } from "jwt-decode";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { openInSameTab } from "@scripts/routes/router";
import { debounce } from "@scripts/util/debounce";
import { isString } from "@scripts/util/refinements";

import type { FetchUnsafeResp, UnsafeResp } from "../fetch";
import { fetchUnsafeResp } from "../fetch";
import * as V2Router from "../generated/routers/v2Router";
import type { UrlInterface } from "../routes/urlInterface";
import { tap } from "../util/tap";

export type XDLParentAction = "activity" | "logout";
export type XDLIframeAction = "reset" | "warn" | "expire" | "logout";

export type MsgData = XDLParentData | XDLIframeData;

export interface XDLParentData {
  kind: "XDLParentData";
  action: XDLParentAction;
}

export interface XDLIframeData {
  kind: "XDLIframeData";
  action: XDLIframeAction;
  mod: number;
}

interface JWS { readonly value: string }

const expBuffer = 5;
const expDebounce = 10000;
const expWarningDur = 55;
// https://stackoverflow.com/a/59427787/8014660 replace moment.utc().unix() usage for now()
export const now = () => Math.floor(Date.now() / 1000);
export const calcExpDur = (userExpDur: number) => (mod: number) => mod + userExpDur - now();

const postDataParent = (action: XDLParentAction) => {
  const d: XDLParentData = { kind: "XDLParentData", action };
  window.postMessage(d, window.location.origin);
};

export const postActivityParent = () => postDataParent("activity");
export const postLogoutParent = () => postDataParent("logout");
export const sessionExpLogin = (config: BLConfigWithLog) => {
  postActivityParent();
  openInSameTab(`${config.baseUrl}${V2Router.baseAuthControllerLogin({
    issuerId: none,
    bankId: none,
    reason: none,
    uhash: none,
    redirect: some(window.location.pathname),
  }).url}`);
};
export const sessionExpLogout = (config: BLConfigWithLog) => {
  postLogoutParent();
  openInSameTab(`${config.baseUrl}${V2Router.baseAuthControllerLogout().url}`);
};

export function initSessExp(
  config: BLConfigWithLog,
  onCount: (currentTime: number) => void
): void {
  pipe(
    config.secReqs,
    map(({ sessExpDur }) => {
      let expTimeout: number | undefined;
      let sessionInterval: number | undefined;
      let expdur: number | undefined;
      let expired: boolean = false;

      const postData = (action: XDLIframeAction) => (mod: number): IO.IO<void> => {
        const d: XDLIframeData = { kind: "XDLIframeData", action, mod };
        return () => window.parent.postMessage(d, window.location.origin);
      };
      const postReset = postData("reset");
      const postWarn = postData("warn");
      const postExpire = () => postData("expire")(0);
      const postLogout = () => postData("logout")(0);

      const JWS = (value: string): JWS => ({ value });
      const jwsModKey = "sessionJwsMod";
      const setJWS = (jws: JWS) => setItem(jwsModKey, jws.value);
      const getJWS = () => IO.map((j: Option<string>) => map(JWS)(j))(getItem(jwsModKey));
      const removeJWS = () => removeItem(jwsModKey);
      const maybeJWS = (jws: unknown): TE.TaskEither<unknown, JWS> => TE.fromEither(fromOption(() => "JWS is empty")(
        pipe(
          some(jws),
          filter(isString),
          filter(_ => _.length > 0),
          map(JWS)
        )
      ));
      const parseJWS = (jws: JWS): TE.TaskEither<unknown, number> =>
        TE.fromEither(tryCatch(() => parseInt(jwtDecode(jws.value)),
          tap((e: unknown) => config.log.warn("Unable to parse stored JWS", e, jws.value))));
      const onFail = (msg: string, ...args: unknown[]): void => {
        config.log.error(msg, args);
        expireSess()();
      };

      const ajaxSettings = (s: RequestInit): RequestInit =>
        Object.assign({
          credentials: "include",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        }, s);

      const ajaxGet = (url: UrlInterface<"GET">): FetchUnsafeResp => fetchUnsafeResp(config)(url, ajaxSettings({}));
      const ajaxRefresh = (url: UrlInterface<"POST">, jws: Option<JWS>): FetchUnsafeResp =>
        fetchUnsafeResp(config)(url, ajaxSettings({
          body: JSON.stringify(fold<JWS, Partial<{ jws: string }>>(() => ({}), (j: JWS) => ({ jws: j.value }))(jws)),
          headers: {
            "Content-Type": "application/json",
          },
        }));

      const stopExpTimeout: IO.IO<void> = () => {
        window.clearTimeout(expTimeout);
        window.clearTimeout(sessionInterval);
      };

      const startExpTimeout: Predicate<number> = (mod: number) => {
        stopExpTimeout();
        expdur = calcExpDur(sessExpDur)(mod);
        if (expired || expdur <= 0) {
          return false;
        }
        expTimeout = window.setTimeout(() => { expireSess()(); }, expdur * 1000);
        sessionInterval = window.setInterval(() => {
          if (expdur) {
            if (expdur === expBuffer) {
              postExpire()();
              window.clearTimeout(sessionInterval);
            } else if (expdur === expWarningDur) {
              onCount(expdur);
              postWarn(mod)();
              expdur -= 1;
            } else if (expdur >= expBuffer) {
              onCount(expdur);
              expdur -= 1;
            }
          }
        }, 1000);
        postReset(mod)();

        return true;
      };

      function sessionUpdated(
        upd: (updatedMod: number) => void,
        exp: () => TE.TaskEither<unknown, unknown>
      ): TE.TaskEither<unknown, number> {

        return pipe(
          TE.rightIO(getJWS()),
          TE.chain(jws =>
            fold(
              () => TE.leftTask<unknown, number>(T.of("JWS Cache Empty")),
              (j: JWS) => pipe(
                parseJWS(j),
                TE.filterOrElse<unknown, number>(startExpTimeout, () => "Unable to parse JWS"),
                TE.map(tap(upd))
              )
            )(jws)
          ),
          TE.alt(() =>
            TE.mapLeft(tap(() => exp()()))(pipe(
              ajaxGet(V2Router.baseAuthControllerCheckSession()),
              TE.chain((resp: UnsafeResp) => maybeJWS(resp.data)),
              TE.chain((jws: JWS) => pipe(
                parseJWS(jws),
                TE.filterOrElse<unknown, number>(startExpTimeout, () => "Unable to parse JWS"),
                TE.map((mod: number) => {
                  setJWS(jws)();
                  upd(mod);
                  return mod;
                })
              ))
            ))
          )
        );
      }

      function expireSess(): TE.TaskEither<unknown, UnsafeResp> | TE.TaskEither<unknown, number> {
        if (expired) {
          return TE.leftTask<unknown, UnsafeResp>(T.of("Session already expired"));
        }

        expired = true;

        function _expSess(): TE.TaskEither<unknown, unknown> {
          stopExpTimeout();
          removeJWS()();

          return TE.of(sessionExpLogout(config));
        }

        return sessionUpdated(
          () => {
            expired = false;
          },
          _expSess);
      }

      function refreshSess(jwsO: Option<JWS>): TE.TaskEither<unknown, unknown> {
        if (expired) {
          return TE.leftTask(T.of("Session already expired"));
        }

        return pipe(
          TE.bindTo("resp")(
            ajaxRefresh(V2Router.baseAuthControllerRefreshSession(), jwsO)),
          TE.bind("jws", ({ resp }: { resp: UnsafeResp }) => maybeJWS(resp.data)),
          TE.bind("mod", ({ jws }: { jws: JWS }) => parseJWS(jws)),
          TE.chain<unknown, { jws: JWS, mod: number }, unknown>(({ jws, mod }) => {
            if (startExpTimeout(mod)) {
              setJWS(jws)();
              return TE.rightTask(T.of(constVoid()));
            }

            const fMsg = "Unable to refresh session";
            onFail(fMsg);
            return TE.leftTask(T.of(fMsg));
          }));
      }

      window.addEventListener("storage", (se: StorageEvent) => {
        if (se.key !== jwsModKey) {
          return;
        }

        if (se.newValue == null || se.newValue === "") {
          postLogout()();
          return;
        }

        pipe(
          maybeJWS(se.newValue),
          TE.chain((j: JWS) => TE.map((mod: number) => startExpTimeout(mod))(parseJWS(j)))
        )();
      });

      window.addEventListener("message", (oe: MessageEvent): void => {
        const d = <XDLParentData>oe.data; // eslint-disable-line @typescript-eslint/consistent-type-assertions
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (d.kind === "XDLParentData") {
          switch (d.action) {
            case "activity":
              refreshSess(none)();
              return;
            case "logout":
              pipe(removeJWS(), IO.chain(() => postLogout()))();
              return;
          }

          return config.exhaustive(d.action);
        }
      });

      const debouncedRefreshSess = debounce(refreshSess(none), expDebounce);

      refreshSess(none)();
      globalThis.addEventListener("click", debouncedRefreshSess);
      globalThis.addEventListener("keypress", debouncedRefreshSess);
      globalThis.addEventListener("scroll", debouncedRefreshSess);
    })
  );
}
