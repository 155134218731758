import autobind from "autobind-decorator";
import { constVoid, flow } from "fp-ts/lib/function";
import { map } from "fp-ts/lib/Option";

import { Static } from "@scripts/bondlinkStatic";
import { onResize } from "@scripts/dom/onResize";
import { onScroll } from "@scripts/dom/onScroll";
import type { QPosition } from "@scripts/dom/q";
import { Q } from "@scripts/dom/q";
import { foldL } from "@scripts/util/fold";
import { invoke1 } from "@scripts/util/invoke";
import { merge } from "@scripts/util/merge";
import { requestAnimFrame } from "@scripts/util/requestAnimFrame";
import { updProp } from "@scripts/util/updProp";

import { MainNav } from "./mainNav";

@autobind
export class StickySidebar {
  static readonly gridClass = "grid-75-25-simple:not(.no-sticky)";
  static readonly gridRightClass = "grid-right";

  fixed: boolean = false;
  container: Q;
  containerOffset: QPosition;
  positions: Array<[Q, QPosition]> = [];
  mainNavHeight: number = 0;
  sidebarHeight: number = 0;

  static init(): void {
    Q.all(`.${StickySidebar.gridClass}`).forEach((container: Q) => new StickySidebar(container));
  }

  constructor(container: Q) {
    this.container = container;
    this.containerOffset = this.container.getOffsetWithMargin();
    this.bindEvents();
    requestAnimFrame(() => {
      this.calcPositions();
      this.update(false)(Q.getScrollTop());
    });
  }

  bindEvents(): void {
    // Trigger an update on scroll
    onScroll(this.update(false));

    // Trigger a recalculation and an update on window resize
    onResize(() => this.update(true)(Q.getScrollTop()));

    // Recalculate main nav height when it sticks or unsticks
    map((h: Q) => h.observe({ childList: true, subtree: true }, () => this.update(true)(Q.getScrollTop())))(Q.one("header"));

    // Observe the container's subtree and trigger an update on the addition or removal of `.grid-right` elements
    this.container.observe({ childList: true, subtree: true }, flow(Q.mutationElements,
      invoke1("some")(invoke1("hasClass")(StickySidebar.gridRightClass)), foldL(() => this.update(true)(Q.getScrollTop()), constVoid)));
  }

  fix(subtract: number): void {
    // Set the height of the container to its current height to ensure content below doesn't jump up
    this.container.setInlineStyles({ height: this.container.getComputedStyle("height") });

    this.positions.forEach(([element, position]: [Q, QPosition]) => {
      element.setInlineStyles({
        // Set an inline style for width to the current width of the element
        // to ensure that when the element becomes fixed its width will not change
        width: `${element.getWidthWithMargin()}px`,
        position: "fixed",
        top: `${position.top - Math.abs(subtract)}px`,
        left: `${position.left}px`,
      });
    });
  }

  reset(): void {
    this.fixed = false;
    this.resetStyles();
  }

  update = (recalc: boolean) => (scrollY: number) => {
    // No sticky sidebar on mobile
    if (!window.matchMedia(Static.matchMedia.md).matches) {
      this.reset();
      return;
    }

    if (recalc) { this.calcPositions(); }

    // If the distance scrolled down plus the height of the sidebar is past the bottom of the container (BOC),
    // fix each element at its calculated position minus the distance past the BOC
    if ((scrollY + this.sidebarHeight) > this.containerOffset.bottom) {
      this.fixed = false;
      this.fix(this.containerOffset.bottom - (scrollY + this.sidebarHeight));
      return;

      // Else if the distance scrolled down is past the top of the container
      // and we are recalculating or not already fixed, fix each element at its calculated position
    } else if (scrollY >= this.containerOffset.top && (recalc || !this.fixed)) {
      this.fixed = true;
      this.fix(0);
      return;

      // Else if the distanced scrolled down is above the top of the container
      // and we are recalculating or fixed, reset the styles on each element
    } else if (scrollY < this.containerOffset.top && (recalc || this.fixed)) {
      this.reset();
      return;
    }
  };

  calcPositions(): void {
    // Reset styles to ensure that calculated dimensions are not affected by fixed position
    this.resetStyles();

    // Calculate the offset of the container
    const updPos = (k: keyof QPosition) => updProp(k)((i: number) => i - MainNav.blBarHeight);
    this.containerOffset = flow(updPos("top"), updPos("bottom"))(this.container.getOffsetWithMargin());

    // Calculate the positions of all `.grid-right` elements where
    //   top = the distance between the top of the element and the point where we want to stick
    //   left = the element's current left position
    this.positions = this.container.all(`.${StickySidebar.gridRightClass}`).map((el: Q): [Q, QPosition] => {
      const offset = el.getOffsetWithMargin();
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return [el, merge(offset)({ top: offset.top - this.containerOffset.top }) as QPosition];
    });

    // Calculate the height of the sidebar by summing the heights of all `.grid-right` elements
    // and any whitespace between elements
    this.sidebarHeight = this.positions.reduce((acc: number, [e]: [Q, QPosition]) =>
      acc + e.getHeightWithMargin(),
      0
    );
  }

  resetStyles(): void {
    // Remove inline height style from container
    this.container.removeInlineStyles(["height"]);

    this.positions.forEach(([element]: [Q, QPosition]) => {
      // Remove all inline styles we've applied
      element.removeInlineStyles(["position", "top", "left", "width"]);
    });
  }
}
