import { identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import V from "voca";

import type { Q } from "@scripts/dom/q";
import { openInSameTab } from "@scripts/routes/router";
import { makeConfigLogger } from "@scripts/util/log";

import { Config } from "../csr-only/config";
import { html, joinHtml, UnsafeHtml } from "../dom/unsafeHtml";
import type { ErrorCodeName } from "../generated/domaintables/errorCodes";
import type { ResponseCodeName } from "../generated/domaintables/responseCodes";
import type { ApiError } from "../generated/models/apiErrors";
import type { ValidationError } from "../generated/models/validationError";
import { parseNumber } from "../util/parseNumber";
import { concat } from "../util/string";
import { trimFilter } from "../util/trimFilter";
import type { Basil } from "./basil";
import type { ModelField } from "./modelField";

export type ErrorFn = (m: ModelField, label: O.Option<string>, value: O.Option<UnsafeHtml>, extra: O.Option<string>) => UnsafeHtml;

const formatErrorNewlines = (e: string) => joinHtml(e.split("\n").map(s => html`${s}`), html`<br>`);

const config = Config(makeConfigLogger());
const contactBLSupport = html`contact <a href="mailto:${config.customerSuccessEmail}">BondLink support</a>`;
const refreshingThePage = html`<a href="javascript:window.location.reload()">refreshing the page</a> or ${contactBLSupport}`;
const refresh = html`We're sorry, we encountered an error on our end. Try ${refreshingThePage} or ${contactBLSupport} for help if the problem persists.`;
const retryLater = html`We're sorry, we encountered an error on our end. Please try again later or ${contactBLSupport} for help.`;
const badSession = html`We're sorry, we encountered an error on our end. Try ${refreshingThePage} or try logging out and back in again.`;

export const formatErrorCode = (
  code: ErrorCodeName | ResponseCodeName,
  forgotPasswordButton: () => O.Option<UnsafeHtml>
): ErrorFn => (m, label, value, extra): UnsafeHtml => {
  /*
   * Error Responses
   */
  switch (code) {
    case "DOES_NOT_MATCH":
      return html`${O.getOrElse(() => m.field)(label)} does not match`;
    case "ID_NOT_FOUND":
      return html`${O.getOrElse(() => m.field)(label)} could not be found`;
    case "MISSING_REQUIRED_FIELD":
      return html`A response is required`;
    case "DUPLICATE_UNIQUE_VALUE":
    case "UNIQUE_CONSTRAINT": {
      // This might make sense in ModelField.format but wait til its needed elsewhere
      const v = pipe(
        value,
        O.alt(() => pipe(
          label,
          O.map((l: string) => html`${V.capitalize(l)}`))));
      const field = O.getOrElse(() => m.field)(label).toLowerCase();
      return html`${O.fold(() => html``, (x: UnsafeHtml) => html`${x} already exists. `)(v)}Please specify a unique ${field}`;
    }
    case "DUPLICATE_USER_EMAIL":
      return html`There is already an account associated with this email address. ${O.getOrElse(() => html``)(forgotPasswordButton())}`;
    /*
     * Field Validation Errors
     */
    case "FIELD_MIN_LENGTH_VIOLATION":
      return pipe(
        extra,
        O.chain(parseNumber),
        O.fold(
          () => html`${O.getOrElse(() => m.field)(label)} does not meet the minimum length requirements`,
          (e: number) => html`${O.getOrElse(() => m.field)(label)} must be at least ${e} characters`));
    case "FIELD_MAX_LENGTH_VIOLATION":
      return pipe(
        extra,
        O.chain(parseNumber),
        O.fold(
          () => html`${O.getOrElse(() => m.field)(label)} exceeds the maximum length requirements`,
          (e: number) => html`${O.getOrElse(() => m.field)(label)} must not exceed ${e} characters`));
    case "EMPTY_FIELD":
      return html`${O.getOrElse(() => m.field)(label)} must not be empty`;
    case "INVALID_VALUE":
      return html`Invalid ${O.getOrElse(() => m.field)(label)}`;
    case "INVALID_EMAIL":
      return O.fold(() => html`Invalid email address`, (v: UnsafeHtml) => html`${v} is not a valid email address`)(value);
    case "INVALID_DATE":
      return html`${O.getOrElse(() => m.field)(label)} is not a valid date`;
    case "INVALID_DATE_ORDER":
      return O.fold(() => html`${O.getOrElse(() => m.field)(label)} is not a valid date`, (e: string) => html`${e}`)(extra);
    case "INVALID_PAST_DATE":
      return html`${O.getOrElse(() => m.field)(label)} cannot be in the past`;
    case "INVALID_HEX_COLOR":
      return html`${O.getOrElse(() => m.field)(label)} must be a valid 6 character hex color`;
    case "INVALID_EXTENSION":
      return html`Upload has an invalid extension`;
    case "INVALID_PASSWORD_REQUIREMENTS":
      return html`Password must be strong or better${O.fold(() => "", concat(" - "))(extra)}`;
    case "INVALID_PHONE_NUMBER":
      return O.fold(() => html`Invalid phone number`, (v: UnsafeHtml) => html`${v} is not a valid phone number`)(value);
    case "INVALID_SPACE_PREFIX":
      return html`${O.getOrElse(() => m.field)(label)} can not contain a leading space`;
    case "INVALID_SPACE_SUFFIX":
      return html`${O.getOrElse(() => m.field)(label)} can not contain a trailing space`;
    case "INVALID_URL":
      return html`${O.getOrElse(() => m.field)(label)} must be a fully qualified URL, ie:&nbsp;https://www.example.com/page`;
    case "URL_NOT_ALLOWED":
      return html`The URL you provided is not permitted for ${O.getOrElse(() => m.field)(label)}. Please try a different URL.`;
    case "INVALID_CUSIP":
      return html`${O.getOrElse(() => m.field)(label)} must be a valid CUSIP`;
    case "MALFORMED_INPUT":
      return html`${O.getOrElse(() => m.field)(label)} is an unexpected value`;
    /*
     * Authentication Responses
     */
    case "CREDENTIALS_INVALID":
      return pipe(
        extra,
        O.map(formatErrorNewlines),
        O.getOrElse(() => html`Invalid email or password. ${O.getOrElse(() => html``)(forgotPasswordButton())}`)
      );
    case "PASSWORD_EXPIRED": {
      O.map((e: string) => openInSameTab(e))(extra);
      return html`Your password has expired`;
    }
    case "CUSTOM":
      return O.fold(
        () => html`invalid value for ${O.getOrElse(() => m.field)(label)}`,
        formatErrorNewlines)(extra);
    case "TOO_MANY_REQUESTS":
      return html`${O.fold(() => "You're doing that too much", identity)(extra)}. Please wait a few minutes then try again.`;
    case "EMPTY_RESULT":
      return refresh;
    case "RESOURCE_NOT_FOUND":
      return retryLater;
    case "TOO_MANY_RESULTS":
      return refresh;
    case "FOREIGN_KEY_CONSTRAINT":
      return refresh;
    case "INTERNAL_SERVER_ERROR":
      return refresh;
    case "SESSION_ERROR":
      return badSession;
    case "CLEAR_SESSION":
      return badSession;
    case "FIELD_ERRORS":
      return pipe(
        O.some(O.getOrElse(() => m.field)(label)),
        O.filter(_ => _.length > 0),
        O.fold(
          () => html`Please make sure you've entered valid information in all required fields before submitting.`,
          f => html`There are issues with ${f}.`
        )
      );
  }
  return config.exhaustive(code);
};

export const formatError = <El>(
  getValue: (el: Q<HTMLElement & { value: string }>) => O.Option<unknown>,
  forgotPasswordButton: () => O.Option<UnsafeHtml>
) => (
  basil: Basil<El>,
  modelField: ModelField,
  error: ApiError | ValidationError
): UnsafeHtml => {
  const field = basil.inputOrLabelForModelField(modelField);
  const label = O.chain(basil.labelForField)(field);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const value = O.chain(getValue)(field as O.Option<Q<HTMLElement & { value: string }>>);
    return pipe(
      formatErrorCode(error.error.error, forgotPasswordButton)(
        modelField,
        trimFilter(pipe(label, O.chain(basil.settings.dom.getInnerText))),
        trimFilter(pipe(value, O.map((v: unknown) => html`${v}`))),
        trimFilter(error.extra)),
      h => h.toString(),
      V.capitalize,
      UnsafeHtml
    );
  };
