import { constVoid, pipe } from "fp-ts/lib/function";
import { fold, map } from "fp-ts/lib/Option";

import { Static } from "@scripts/bondlinkStatic";

import { Q } from "../dom/q";
import { invoke1, invoke2 } from "../util/invoke";

const spinnerOverlay = "spinner-overlay";
const spinnerContainer = "icon-bondlink-loader-container";
const spinnerImg = "icon-bondlink-loader";

export function spinnerCreate(): void {
  pipe(Q.one(`.${spinnerOverlay}`),
    fold(() => {
      Q.body.append(Q.createElement("div", [
        invoke1("addClass")([spinnerOverlay, "fade"])], []));
      Q.body.append(
        Q.createElement("div", [invoke1("addClass")([spinnerContainer, "fade"])], [
          Q.createElement("img", [
            invoke1("addClass")([spinnerImg, "fade"]),
            invoke2("setAttrUnsafe")("src", "/assets/img/bondlink-loader.svg"),
          ], []),
        ])
      );
      pipe(Q.one(`.${spinnerOverlay}`), map(show));
      pipe(Q.one(`.${spinnerContainer}`), map(show));
      pipe(Q.one(`.${spinnerImg}`), map(show));
    },
      constVoid));
}

export function spinnerDestroy(): void {
  pipe(Q.one(`.${spinnerOverlay}`), map(remove));
  pipe(Q.one(`.${spinnerContainer}`), map(remove));
  pipe(Q.one(`.${spinnerImg}`), map(remove));
}

function show(s: Q): void {
  s.addClass(["showing", "show"]);
  window.setTimeout(() => s.removeClass("showing"), Static.baseTransitionDelay);
}

function remove(s: Q): void {
  s.addClass("hiding").removeClass("show");
  window.setTimeout(s.remove, Static.baseTransitionDelay);
}
