import { constVoid } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { fold, map } from "fp-ts/lib/Option";
import { Key } from "ts-key-enum";

import { Static } from "@scripts/bondlinkStatic";

import type { QEvent } from "../dom/q";
import { Q } from "../dom/q";
import { invoke1 } from "../util/invoke";
import { requestAnimFrame } from "../util/requestAnimFrame";

type Input = [Q, keyof WindowEventMap, string, (c: Q) => Option<Q>, (e: QEvent, a: Q, open: boolean) => void];

export class Expandable {
  private static readonly initialized: Input[] = [];

  static reinit(): void {
    Expandable.initialized.forEach(Expandable._init);
  }

  static init(root: Q, eventName: keyof WindowEventMap, containerSel: string,
    getAnimatable: (container: Q) => Option<Q>, extraHandler: (e: QEvent, a: Q, open: boolean) => void = constVoid): void {
    const input: Input = [root, eventName, containerSel, getAnimatable, extraHandler];
    Expandable.initialized.push(input);
    Expandable._init(input);
  }

  static initBasic(containerSel: string, animatableSel: string): void {
    Expandable.init(Q.body, "click", containerSel, invoke1("one")(animatableSel));
    Expandable.init(Q.body, "keyup", containerSel, invoke1("one")(animatableSel));
  }

  static toggle = (open: boolean) => (container: Q) => (animatable: Q): void => {
    requestAnimFrame(() => open
      ? requestAnimationFrame(() => {
        const height = animatable.getAttr("scrollHeight");
        animatable.setInlineStyle("maxHeight", `${height}px`);
      })
      : animatable.removeInlineStyle("maxHeight"));

    if (open) {
      container.addClass(["show", "open"]);
    } else {
      container.removeClass("open").reflow().addClass("close");
      window.setTimeout(() => container.removeClass(["show", "close"]), Static.baseTransitionDelay);
    }
  };

  private static _init([root, eventName, containerSel, getAnimatable, extraHandler]: Input): void {
    Q.all(`${containerSel}.open`).forEach((c: Q) => map(Expandable.toggle(true)(c))(getAnimatable(c)));

    root
      .off(eventName, containerSel)
      .listen(eventName, containerSel, (e: QEvent) => map((animatable: Q) => {
        if (fold(() => false, (el: Q) => el.equals(animatable) || animatable.contains(el))(e.originationElement)) { return; }
        if (!(e.event instanceof KeyboardEvent) || (e.event instanceof KeyboardEvent && (e.event.key === Key.Enter || e.event.key === " "))) {
          const open = !e.selectedElement.hasClass("open");
          Expandable.toggle(open)(e.selectedElement)(animatable);
          extraHandler(e, animatable, open);
        }
      })(getAnimatable(e.selectedElement)));
  }
}
