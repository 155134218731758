import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { QEvent } from "../dom/q";
import { Q } from "../dom/q";

export class ShowAll {
  static init(): void {
    Q.body.listen("click", ".show-all-button", Q.prevented(
      (e: QEvent) => {
        const key = O.getOrElse(() => "")(e.selectedElement.getData("key"));
        const expanded = (O.getOrElse(() => "false")(e.selectedElement.getData("expanded"))) === "true";
        pipe(
          Q.body.one(`#show-all-${key}`),
          O.map(el => el.addOrRemoveClass("d-none", expanded))
        );
        pipe(
          Q.body.one(`#show-all-text-${key}`),
          O.map(el => el.setInnerText(expanded ? "Show all" : "Show less"))
        );
        pipe(
          Q.body.one(`#show-all-chevron-${key}`),
          O.map(el => el.addOrRemoveClass("rotate", !expanded))
        );
        e.selectedElement.setData("expanded", (!expanded).toString());
      })
    );
  }
}
