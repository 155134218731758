import { constVoid, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { updateIssuerNotifications } from "@scripts/api/api";
import { getIssuer, getUser } from "@scripts/bondlinkUser";
import { Config } from "@scripts/csr-only/config";
import type { QEvent } from "@scripts/dom/q";
import { Q } from "@scripts/dom/q";
import { Form } from "@scripts/form/form";
import { run } from "@scripts/fp-ts/Task";
import { notifications } from "@scripts/generated/domaintables/loginReasons";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { openInSameTab } from "@scripts/routes/router";
import { getAbsoluteUrl, modifyAbsoluteUrl } from "@scripts/routes/urlInterface";
import { Alert } from "@scripts/ui/alert";
import { makeConfigLogger } from "@scripts/util/log";

import { addHoverable } from "./bookmark";

const config = Config(makeConfigLogger());

export class Notifications {
  static init(): void {
    Q.body.listen("click", ".btn-toggle-alerts", (e: QEvent) => {
      e.preventDefault();
      e.stopPropagation();
      addHoverable(e);
      Notifications.toggleNotifications(
        e,
        pipe(e.selectedElement.getData("checked"), O.exists(_ => _ === "false")),
        (enabled: boolean) => Q.all(".btn-toggle-alerts").forEach(el => el.setData("checked", enabled.toString())),
      );
    });
  }

  private static toggleNotifications(
    event: QEvent,
    isSubscribing: boolean,
    setUI: (enabled: boolean) => void,
  ): void {
    pipe(getIssuer(), O.map(issuer => {
      const forceLogin = () =>
        openInSameTab(`${config.baseUrl}${V2Router.baseAuthControllerLogin({
          issuerId: O.some(issuer.id),
          bankId: O.none,
          uhash: O.none,
          reason: O.some(notifications.machineReadable),
          redirect: O.some(modifyAbsoluteUrl(
            identity,
            () => V2Router.investorPortalNotificationSubscriptionsControllerEnableIssuerSubscriptionRedirect({ issuerId: issuer.id, redirect: getAbsoluteUrl() }).url,
            () => new URLSearchParams(),
          )),
        }).url}`);

      const submit = () => {
        setUI(isSubscribing); // Eagerly set UI state assuming request succeeds

        pipe(
          updateIssuerNotifications(config)({ issuerId: issuer.id, enabled: isSubscribing })(
            () => {
              setUI(!isSubscribing); // Revert UI state if request fails
              Alert.show("issuer-notification-toggle", "alert-danger", Form.genericErrorMessage, 0, ["text-center"]);
            },
            constVoid,
          ),
          run,
        );
      };

      O.fold(forceLogin, submit)(getUser());
    }));
  }
}
