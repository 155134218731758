import autobind from "autobind-decorator";
import { filterMap } from "fp-ts/lib/Array";
import { pipe, tuple } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { not } from "fp-ts/lib/Predicate";

import { Q, type QEvent } from "../dom/q";
import { Form } from "../form/form";
import { eq } from "../util/eq";
import { invoke1 } from "../util/invoke";

type FilterValue = [string, (row: Q<HTMLTableRowElement>) => O.Option<string>];
type FilterValues = FilterValue[];

@autobind
export class TableFilters {
  table: Q<HTMLTableElement>;
  selector: string;
  values: { and: FilterValues, or: FilterValues } = { and: [], or: [] };

  static init(table: Q<HTMLTableElement>, onChange: (ev: QEvent<"change", HTMLInputElement>) => void): TableFilters {
    const filters = new TableFilters(table);
    Q.body.listen<"change", HTMLInputElement>("change", filters.selector, ev => {
      filters.cacheValues();
      onChange(ev);
    });
    filters.cacheValues();
    return filters;
  }

  static selector(table: Q<HTMLTableElement>): string {
    return `.table-filter-trigger[data-table="#${table.getAttr("id")}"]`;
  }

  static getKeyAndVal(e: Q<HTMLInputElement>): O.Option<{ key: string, val: string }> {
    return pipe(
      O.Do,
      O.apS("key", e.getData("data-key")),
      O.apS("val", pipe(Form.getElementValue(e), O.filter(not(eq("all"))))),
    );
  }

  constructor(table: Q<HTMLTableElement>) {
    this.table = table;
    this.selector = TableFilters.selector(table);
  }

  get allValues(): FilterValues { return this.values.and.concat(this.values.or); }

  getValues(selector: string): FilterValues {
    return filterMap((e: Q<HTMLInputElement>) => pipe(
      TableFilters.getKeyAndVal(e),
      O.map(({ key, val }) => (tuple(val, invoke1("getData")(key))))
    ))(Q.all<HTMLInputElement>(selector).filter(Form.isChecked));
  }

  cacheValues(): void {
    this.values = { and: this.getValues(`${this.selector}.and-op`), or: this.getValues(`${this.selector}.or-op`) };
  }

  matches(row: Q<HTMLTableRowElement>): boolean {
    const predicate = ([v, f]: FilterValue) => O.fold(() => false, eq(v))(f(row));
    return [tuple(this.values.or, (<FilterValues>[]).some), tuple(this.values.and, (<FilterValues>[]).every)].reduce( // eslint-disable-line @typescript-eslint/consistent-type-assertions
      (acc: boolean, [vs, f]: [FilterValues, (f: (t: FilterValue) => boolean) => boolean]): boolean => acc && (vs.length > 0 ? f.call(vs, predicate) : true),
      true
    );
  }
}
