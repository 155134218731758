import autobind from "autobind-decorator";
import Clipboard from "clipboard";
import { findFirst, findIndex, Foldable, zip } from "fp-ts/lib/Array";
import { constVoid, flow, pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { chain, filter, fold, fromNullable, getOrElse, isSome, map, none, some } from "fp-ts/lib/Option";
import { fromFoldable, toArray } from "fp-ts/lib/Record";
import { last } from "fp-ts/lib/Semigroup";
import { regexp } from "regex-prepared-statements";

import { Config } from "@scripts/csr-only/config";
import type { QEvent } from "@scripts/dom/q";
import { CachedElements, Q } from "@scripts/dom/q";
import { getWindowSize } from "@scripts/dom/window";
import { Modal as ModalComp } from "@scripts/ui/modal";
import { eq } from "@scripts/util/eq";
import { invoke0, invoke1, invoke2 } from "@scripts/util/invoke";
import { makeConfigLogger } from "@scripts/util/log";
import { merge } from "@scripts/util/merge";
import { prop } from "@scripts/util/prop";

import { JumpLinks } from "../jumpLinks";
import { Table } from "../ui/table";

const config = Config(makeConfigLogger());

@autobind
abstract class DeepLinkType {
  separator = "~";
  abstract re: RegExp;
  abstract reMatchNames: string[];
  abstract linkBuilders: { [selector: string]: (el: Q) => string };

  abstract match(m: { [k: string]: string }): boolean;
  abstract process(m: { [k: string]: string }): void;
}

@autobind
export class Modal extends DeepLinkType {
  static dataTarget = (base: string, id: Option<string>) => `${base}[data-target${fold(() => "", (s: string) => `="${s}"`)(id)}]`;
  static toggle = (id: Option<string>) => Modal.dataTarget(ModalComp.toggleSelector, id);

  re = regexp(`^modal<_>([A-Za-z][a-zA-Z0-9-_:.]+)$`)(this.separator);
  reMatchNames = ["modalId"];
  linkBuilders = {
    [Modal.toggle(none)]: (btn: Q) =>
      `modal${this.separator}${CachedElements.normalizeId(getOrElse(() => "")(btn.getData("target")))}`,
    [`${ModalComp.toggleSelector}[href]:not([data-target])`]: (link: Q) =>
      `modal${this.separator}${CachedElements.normalizeId(getOrElse(() => "")(link.getAttrO("href")))}`,
  };

  // If it matches the regex, it's all good
  match(): boolean { return true; }

  process(m: { modalId: string }): void {
    ModalComp.showById(m.modalId);
  }
}

@autobind
export class DataTable extends DeepLinkType {
  re = regexp(`^dt<_>([A-Za-z][a-zA-Z0-9-_:.]+)<_>([a-zA-Z0-9]+)(<_>([a-zA-Z0-9]+))?$`)(this.separator, this.separator, this.separator);
  reMatchNames = ["dtId", "rowId", "_", "action"];
  linkBuilders = {
    ".table tbody tr": (row: Q) => `dt${this.separator}${getOrElse(() => "")(this.dtAndRowIds(row))}`,
    '.table td[data-action]:not([data-action="delete"])': (cell: Q) =>
      `dt${this.separator}${getOrElse(() => "")(this.dtAndRowIds(cell))}${this.separator}${getOrElse(() => "")(cell.getData("action"))}`,
  };

  // If it matches the regex, it's all good
  match(): boolean { return true; }

  process(m: { dtId: string, rowId: string, action?: string }): void {
    const id = `${m.dtId}-${m.rowId}`;
    pipe(Q.one(`#${m.dtId}`), chain(Table.cache.get), map((table: Table) =>
      pipe(
        findIndex(flow(prop("id"), eq(id)))(table.dataTable.data),
        map((rowIdx: number) => {
          table.paginateTo(Math.max(1, Math.ceil(rowIdx / Table.pageSize)), false);
          Q.waitFor(() => pipe(Q.one(`#${id}`), fold(() => false, invoke0("isVisible"))))
            .then(() => map((row: Q) => {
              window.setTimeout(() => {
                JumpLinks.jumpTo(row, none);
                row.addClass("highlight-primary");
                window.setTimeout(() => row.removeClass("highlight-primary"), 5000);
              }, 100);

              chain((a: string) =>
                map((actionEl: Q) => {
                  getOrElse(() => actionEl)(actionEl.one("button")).trigger("click");
                })(row.one(`td[data-action="${a}"]:not([data-action="delete"])`))
              )(fromNullable(m.action));
            })(Q.one(`#${id}`)))
            .catch(() => config.log.warn("DeepLink", "Row never became visible"));
        })
      )
    ));
  }

  private dtAndRowIds(el: Q): Option<string> {
    return pipe(
      el.closest(".table"),
      chain(Table.cache.get),
      map((table: Table) => `${table.dataTable.element.getAttr("id")}${this.separator}${getOrElse(() => "")(table.getRowIdFromTarget(el))}`)
    );
  }
}

// This should always come last because it has the loosest checks
@autobind
class Any extends DeepLinkType {
  re = /^([A-Z][\w\-:.]+)$/i;
  reMatchNames = ["id"];
  linkBuilders = {};

  match(m: { id: string }): boolean { return isSome(Q.one(`#${m.id}`)); }
  process(m: { id: string }): void {
    map((e: Q) => JumpLinks.jumpTo(e, Q.one(`.jump-link a[href="#${m.id}"]`)))(Q.one(`#${m.id}`));
  }
}

const deepLinkTypes: DeepLinkType[] = [new Modal(), new DataTable(), new Any()];

interface Builders { [selector: string]: (e: Q) => string }

export class DeepLink {
  static init(): void {
    DeepLink.initMatch();
    DeepLink.initLinkBuilders();
  }

  static initMatch(): void {
    findFirst(DeepLink.tryMatch)(deepLinkTypes);
  }

  static initLinkBuilders(): void {
    DeepLink.bindLinkBuilders(deepLinkTypes.reduce((acc: Builders, dlt: DeepLinkType) =>
      merge(acc)(dlt.linkBuilders), {}));
  }

  private static tryMatch(dlt: DeepLinkType): boolean {
    return pipe(
      fromNullable(dlt.re.exec(window.location.hash.slice(1))),
      filter((rm: RegExpMatchArray) => rm.length - 1 === dlt.reMatchNames.length),
      map((rm: RegExpMatchArray) => fromFoldable(last<string>(), Foldable)(
        zip(dlt.reMatchNames, rm.slice(1, dlt.reMatchNames.length + 1)))),
      filter(dlt.match),
      fold(
        () => false,
        (m: { [k: string]: string }) => {
          dlt.process(m);
          return true;
        }
      )
    );
  }

  private static ctxMenu(hash: string): Q {
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}#${hash}`;
    const mkA = (aAttrs: Array<(e: Q<HTMLAnchorElement>) => Q<HTMLAnchorElement>>, text: string) =>
      Q.createElement("a", aAttrs.concat([invoke1("addClass")("ctx-link"), invoke1("setInnerText")(text)]), []);
    return Q.createElement("div", [invoke2("setAttrUnsafe")("id", "ctx-menu")], [
      mkA([
        invoke2("setAttrUnsafe")("href", "javascript:void(0)"),
        invoke1("addClass")("copy-text"),
        invoke2("setData")("clipboard-text", url),
      ], "Copy Deep Link"),
      mkA([
        invoke2("setAttrUnsafe")("href", url),
        invoke2("setAttrUnsafe")("target", "_blank"),
      ], "Open Deep Link"),
    ]);
  }

  private static bindLinkBuilders(builders: Builders): void {
    let menu: Option<Q> = none;
    const removeCtxMenu = () => { map(invoke0("remove"))(menu); };
    (new Clipboard("#ctx-menu .copy-text")).on("success", removeCtxMenu);

    Q.body.listen("contextmenu", Object.keys(builders).join(", "), (e: QEvent<"contextmenu">) => {
      if (!e.getAttr("altKey")) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      removeCtxMenu();
      pipe(
        findFirst(flow((a: [string, (e: Q) => string]) => a[0], e.selectedElement.matches))(toArray(builders)),
        map(([, builder]: [string, (e: Q) => string]) => {
          const newMenu = DeepLink.ctxMenu(builder(e.selectedElement));
          menu = some(newMenu);
          const pageX = e.getAttr("pageX");
          const menuWidth = newMenu.getWidthWithMargin();
          newMenu.setInlineStyles({
            position: "absolute",
            top: `${e.getAttr("pageY")}px`,
            left: `${(pageX + menuWidth) >= getWindowSize().width ? pageX - menuWidth : pageX}px`,
          });
          Q.body.append(newMenu);
        })
      );
    });

    Q.body.listen("click", (e: QEvent) => fold(removeCtxMenu, constVoid)(e.selectedElement.closest("#ctx-menu")));
  }
}
