import { constVoid, pipe } from "fp-ts/lib/function";
import { fromNullable, map } from "fp-ts/lib/Option";
import { lookup } from "fp-ts/lib/Record";
import type { Listener } from "xstream";
import xs from "xstream";

import { Config } from "@scripts/csr-only/config";
import { makeConfigLogger } from "@scripts/util/log";

import { cancelAnimFrame, requestAnimFrame } from "../util/requestAnimFrame";

const config = Config(makeConfigLogger());

const handlerFns: Record<string, () => void> = {};

export const debouncedEvent = <A extends unknown[]>(eventName: string, mkArgs: () => A): (f: (...args: A) => void) => void => {
  let tick: number | undefined;
  const stream = xs.create({
    start: (listener: Listener<Date>) => {
      handlerFns[eventName] = () => {
        pipe(fromNullable(tick), map(cancelAnimFrame));
        tick = requestAnimFrame(() => listener.next(new Date()));
      };

      window.addEventListener(eventName, handlerFns[eventName]);
    },
    stop: () => pipe(lookup(eventName, handlerFns), map((fn: () => void) => window.removeEventListener(eventName, fn))),
  });
  return (f: (...args: A) => void): void => stream.addListener({
    next: () => f(...mkArgs()),
    error: (e: unknown) => config.log.error(`Error occurred in ${eventName} event stream`, e),
    complete: constVoid,
  });
};
