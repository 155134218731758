import { constVoid, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { updateOfferingSubscriptionRequest, updateRfpSubscriptionRequest } from "@scripts/api/api";
import type { ErrorHandlerApiReq } from "@scripts/api/methods";
import { getIssuer, getUser } from "@scripts/bondlinkUser";
import { Config } from "@scripts/csr-only/config";
import type { QEvent } from "@scripts/dom/q";
import { Q } from "@scripts/dom/q";
import * as lr from "@scripts/generated/domaintables/loginReasons";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { openInSameTab } from "@scripts/routes/router";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { getAbsoluteUrl, modifyAbsoluteUrl } from "@scripts/routes/urlInterface";
import { defaultDelay } from "@scripts/ui/tooltip";
import { makeConfigLogger } from "@scripts/util/log";
import { parseBool } from "@scripts/util/parseBool";
import { parseNumber } from "@scripts/util/parseNumber";

const bookmarkKey = "checked";
const hoverableKey = "hoverable";
const getBookmarked = (e: Q) => O.chain(parseBool)(e.getData(bookmarkKey));
const getOfferingId = (e: Q) => O.chain(parseNumber)(e.getData("offeringId"));
const getRfpId = (e: Q) => O.chain(parseNumber)(e.getData("rfpId"));

const config = Config(makeConfigLogger());

const bookmark = (
  loginReason: lr.LoginReasonU,
  bookmarkRedirect: (id: number, redir: string) => UrlInterface<"GET">,
  updateBookmarkRequest: (id: number, enabled: boolean) => ErrorHandlerApiReq<object>,
) => (e: Q) => (itemId: number) => pipe(
  getUser(),
  O.fold(
    () => {
      openInSameTab(`${config.baseUrl}${V2Router.baseAuthControllerLogin({
        issuerId: pipe(getIssuer(), O.map(_ => _.id)),
        bankId: O.none,
        uhash: O.none,
        reason: O.some(loginReason.machineReadable),
        redirect: O.some(modifyAbsoluteUrl(
          identity,
          () => bookmarkRedirect(itemId, getAbsoluteUrl()).url,
          () => new URLSearchParams(),
        )),
      }).url}`);
    },
    () => {
      O.map((bookmarked: boolean) => {
        e.setData(bookmarkKey, (!bookmarked).toString()); // Eagerly set UI state, assuming request succeeds.
        const issuerToggles = Q.all(".btn-toggle-alerts");
        const isUserSubscribedToIssuer = pipe(issuerToggles[0], O.fromNullable, O.chain(getBookmarked));
        if (!bookmarked) {
          issuerToggles.forEach((element) => element.setData(bookmarkKey, true.toString()));
        }
        updateBookmarkRequest(itemId, bookmarked)(
          () => {
            e.setData(bookmarkKey, bookmarked.toString()); // Revert UI state if bookmarking request fails.
            if (!bookmarked) {
              O.map((isBookmarked: boolean) =>
                issuerToggles.forEach((element) => element.setData(bookmarkKey, isBookmarked.toString()))
              )(isUserSubscribedToIssuer);
            }
          },
          constVoid
        )();
      })(getBookmarked(e));
    },
  )
);

const subscribeOffering = bookmark(
  lr.bookmarkBond,
  (offeringId, redirect) => V2Router.investorPortalOfferingsControllerSubscribeRedirect({ offeringId, redirect }),
  (offeringId, enabled) => updateOfferingSubscriptionRequest(config)({ offeringId, enabled }),
);

const subscribeRfp = bookmark(
  lr.bookmarkRfp,
  (rfpId, redirect) => V2Router.investorPortalRfpsControllerSubscribeRedirect({ rfpId, redirect }),
  (rfpId, enabled) => updateRfpSubscriptionRequest(config)({ rfpId, enabled }),
);

export const addHoverable = (e: QEvent) => {
  e.selectedElement.setData(hoverableKey, false.toString());
  e.selectedElement.listen("mouseleave", () => {
    window.setTimeout(() => e.selectedElement.setData(hoverableKey, true.toString()), defaultDelay);
  });
};

export const bindBookmarks = (container: Q) => {
  container.listen("click", ".btn-toggle-bookmark", (e: QEvent) => {
    e.preventDefault();
    e.stopPropagation();
    addHoverable(e);
    pipe(
      getOfferingId(e.selectedElement),
      O.map(subscribeOffering(e.selectedElement)),
      O.alt(() => pipe(getRfpId(e.selectedElement), O.map(subscribeRfp(e.selectedElement)))),
    );
  });
};
