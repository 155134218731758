import type { Eq } from "fp-ts/lib/Eq";
import { eqStrict } from "fp-ts/lib/Eq";
import type { Ord } from "fp-ts/lib/Ord";
import type { Ordering } from "fp-ts/lib/Ordering";

import { merge } from "./merge";

export const eqSetoid = <A>(): Eq<A> => eqStrict;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const eqOrd = <A>(): Ord<A> => merge(eqSetoid<A>())({ compare: unsafeCompare }) as Ord<A>;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
function unsafeCompare(x: any, y: any): Ordering {
  return x < y ? -1 : x > y ? 1 : 0;
}
