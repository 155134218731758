import type { Placement } from "@popperjs/core";
import * as Popper from "@popperjs/core";
import autobind from "autobind-decorator";
import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { alt, chain, fold, getOrElse, isSome, map, none } from "fp-ts/lib/Option";
import { lookup } from "fp-ts/lib/Record";

import { Static } from "@scripts/bondlinkStatic";
import { isOffsetOutsideViewport } from "@scripts/syntax/popper";

import type { QEvent } from "../dom/q";
import { CachedElements, Q } from "../dom/q";
import type { UnsafeHtml } from "../dom/unsafeHtml";
import { invoke0, invoke1, invoke2 } from "../util/invoke";
import { parseBool } from "../util/parseBool";
import { parseNumber } from "../util/parseNumber";

export const defaultDelay = 250;

@autobind
export class Tooltip {
  static readonly targetSelector = ".tooltip-target";
  static readonly contentSelector = ".tooltip-content";
  static readonly cache = new CachedElements("tooltip", Tooltip.contentSelector, Tooltip);

  id: string;
  element: Q<HTMLElement>;
  trigger: Option<Q> = none;
  popper: Option<Popper.Instance> = none;
  transitioning: boolean = false;
  anchor: Option<Q> = none;

  static init(): void {
    Tooltip.cache.init();
    Tooltip.bindEvents();
  }

  static bindEvents(): void {
    const showTimeouts: { [id: string]: number } = {};
    const hideTimeouts: { [id: string]: number } = {};

    const clearTimeouts = (timeouts: typeof showTimeouts | typeof hideTimeouts) => (tooltip: Tooltip) => pipe(
      lookup(tooltip.id, timeouts),
      map<number, void>(_ => {
        window.clearTimeout(_);
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete timeouts[tooltip.id];
      }));

    const clearShow = clearTimeouts(showTimeouts);
    const clearHide = clearTimeouts(hideTimeouts);

    const handleTooltipTrigger = (e: QEvent) =>
      pipe(
        e.selectedElement.getData("target"),
        chain(Tooltip.cache.get),
        map((tooltip: Tooltip) => {
          // If there is a hideTimeout still present on additional mouseenter then remove that timeout
          clearHide(tooltip);

          showTimeouts[tooltip.id] = window.setTimeout(() => {
            tooltip.update();
            Tooltip.maybeShow(tooltip, e);

            Q.body.off("mouseleave", Tooltip.contentSelector).listen("mouseleave", Tooltip.contentSelector, (ev: QEvent) => {
              clearShow(tooltip);
              Tooltip.maybeHide(tooltip, ev);
            });
          }, pipe(tooltip.element.getData("delay"), chain(parseNumber), getOrElse(() => defaultDelay)));

          e.selectedElement.off("mouseleave").listen("mouseleave", (ev: QEvent) => {
            clearShow(tooltip);
            hideTimeouts[tooltip.id] = window.setTimeout(() => {
              Tooltip.maybeHide(tooltip, ev);
            }, pipe(tooltip.element.getData("delay"), chain(parseNumber), getOrElse(() => defaultDelay)));
          });
        })
      );

    Q.body.listen("mouseenter", `${Tooltip.targetSelector}:not(.no-hover)`, handleTooltipTrigger);
    Q.body.listen("click", `${Tooltip.targetSelector}.no-hover`, handleTooltipTrigger);
  }

  static getTooltip(e: Q<HTMLElement>): Option<Tooltip> {
    return chain(Tooltip.cache.get)(e.getData("target"));
  }

  /**
   * Conditions for NOT calling hideNow():
   * IF element that triggered mouseOff has class .tooltip-target AND an element with class .tooltip-content is in hover state
   * OR element's originationElement (first child that the mouse left, triggering mouseOff) is a child of an element that has class .tooltip-content
   *
   * @param tooltip: Tooltip
   * @param e: QEvent
   */
  static maybeHide(tooltip: Tooltip, e: QEvent): void {
    if ((e.selectedElement.matches(Tooltip.targetSelector) && isSome(Q.one(`${Tooltip.contentSelector}:hover`)))
      || (fold(() => false, (el: Q) => isSome(el.closest(Tooltip.contentSelector)) && !el.matches(Tooltip.contentSelector))(e.originationElement))) {
      return;
    }
    tooltip.hideNow();
  }

  static maybeShow(tooltip: Tooltip, e: QEvent): void {
    if (!tooltip.transitioning && (e.selectedElement.matches(Tooltip.targetSelector) && isSome(Q.one(`${Tooltip.targetSelector}:hover`)))) {
      tooltip.showNow();
    }
  }

  static popperOptions(tooltip: Q<HTMLElement>): Popper.Options {
    return {
      modifiers: [
        {
          name: "flip",
          enabled: pipe(tooltip.getData("flip"), chain(parseBool), getOrElse<boolean>(() => true)),
        },
        {
          name: "offset",
          enabled: pipe(tooltip.getData("offset"), chain(parseBool), getOrElse<boolean>(() => true)),
          options: {
            offset: ((x: number) => (y: number) => [x, y])(Q.remToPx(parseFloat(getOrElse(() => "0")(tooltip.getData("offset-x")))))(Q.remToPx(parseFloat(getOrElse(() => "0.75")(tooltip.getData("offset-y"))))),
          },
        },
        {
          name: "preventOverflow",
          enabled: pipe(tooltip.getData("prevent-overflow"), chain(parseBool), getOrElse<boolean>(() => true)),
          options: {
            rootBoundary: "viewport",
            padding: 16,
          },
        },
        {
          name: "computeStyles",
          options: {
            gpuAcceleration: false,
          },
        },
      ],
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      placement: getOrElse(() => "auto")(tooltip.getData("x-placement")) as Placement,
      strategy: "absolute",
    };
  }

  static create(tooltip: Q<HTMLElement>, trigger: Q): Popper.Instance {
    return Popper.createPopper(trigger.element, tooltip.element, Tooltip.popperOptions(tooltip));
  }

  static createTooltipContent(id: string, titleO: Option<UnsafeHtml>, body: UnsafeHtml, placement?: Placement): Q<HTMLElement> {
    return Q.createElement("div", [
      (e: Q<HTMLDivElement>) => e.setAttr("id", CachedElements.normalizeId(id)),
      invoke1("addClass")(["tooltip-content"]),
    ].concat(placement ? [invoke2("setAttrUnsafe")("x-placement", placement)] : []),
      fold(() => [], (title: UnsafeHtml) => [Q.createElement("p", [invoke1("addClass")(["small", "m-05"])], [
        Q.createElement("strong", [invoke1("setInnerHtml")(title)], []),
      ])])(titleO).concat([
        Q.createElement("div", [invoke1("addClass")("m-05"), invoke1("setInnerHtml")(body)], []),
        Q.createElement("div", [invoke1("addClass")("arrow"), invoke2("setAttrUnsafe")("data-popper-arrow", "")], []),
      ]));
  }

  constructor(element: Q<HTMLElement>) {
    this.id = Math.round(Math.random() * 10000).toString();
    this.element = element;
    this.initPopper();
  }

  getElement(): Q<HTMLElement> { return this.element; }
  setElement(e: Q<HTMLElement>): void { this.element = e; }

  update(): void {
    this.initPopper();
    map(invoke0("forceUpdate"))(this.popper);
  }

  initPopper(): void {
    map(invoke0("destroy"))(this.popper);
    // Trigger is the element for which mouseOn triggers tooltip to appear.
    this.trigger = Q.one(`${Tooltip.targetSelector}[data-target="#${this.element.getAttr("id")}"]`);
    // Anchor is an optional element defined with a data-anchor property; it is used to focus tooltip on a child element of the trigger.
    this.anchor = Q.one(`${Tooltip.targetSelector}[data-target="#${this.element.getAttr("id")}"] [data-anchor]`);
    // If an anchor is not defined, then create popper based on the trigger element's properties.
    this.popper = pipe(
      this.anchor,
      alt(() => this.trigger),
      map((el: Q) => Tooltip.create(this.element, el))
    );
    Q.body.listen("scroll", () => pipe(
      this.popper,
      map(_ => isOffsetOutsideViewport(_.state) && this.hideNow())
    ));
  }

  showNow(): void {
    if (this.element.hasClass("show")) { return; }
    this.transition(() => this.element.addClass("show"));
  }

  hideNow(): void {
    if (!this.element.hasClass("show")) { return; }
    this.transition(() => this.element.removeClass("show"));
  }

  private transition(f: () => void): void {
    this.transitioning = true;
    f();
    this.element.reflow();
    window.setTimeout(() => this.transitioning = false, Static.baseTransitionDelay);
  }
}
