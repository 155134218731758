import { ErrorCodeCU as imported1_ErrorCodeCU } from "../domaintables/errorCodes";
import * as t from "io-ts";

export const validationErrorC = t.type({
  error: imported1_ErrorCodeCU,
  extra: t.string
});
export type ValidationErrorC = typeof validationErrorC;
export type ValidationError = t.TypeOf<ValidationErrorC>;


