import autobind from "autobind-decorator";
import * as A from "fp-ts/lib/Array";
import { constVoid, flow, identity, pipe, tuple } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { not } from "fp-ts/lib/Predicate";
import * as R from "fp-ts/lib/Record";
import { end, parse, query, Route } from "fp-ts-routing";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";
import { Columns as SimpleDTColumns } from "simple-datatables-classic/src/columns";
import { DataTable as SimpleDataTable } from "simple-datatables-classic/src/index";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { Config } from "@scripts/csr-only/config";
import { Form } from "@scripts/form/form";
import { replaceUrlState } from "@scripts/routes/urlInterface";
import { fromNullableOrOption } from "@scripts/util/fromNullableOrOption";
import { makeConfigLogger } from "@scripts/util/log";
import { trimFilter } from "@scripts/util/trimFilter";

import type { QElement, QEvent } from "../dom/q";
import { Q } from "../dom/q";
import { invoke0, invoke1 } from "../util/invoke";
import { prop } from "../util/prop";
import { TableFilters } from "./filters";

const strRec = t.record(t.string, t.string);
const recordFromString = new t.Type<Record<string, string>, string, unknown>(
  "recordFromString",
  strRec.is,
  (u, c) => {
    try {
      return typeof u === "string" ? strRec.validate(JSON.parse(u), c) : t.failure(u, c);
    } catch (e) {
      return t.failure(u, c, e instanceof Error ? e.message : "Unable to decode JSON");
    }
  },
  j => JSON.stringify(strRec.encode(j)),
);

export const dataTableParams = t.partial({
  page: NumberFromString,
  search: t.string,
  sortCol: t.string,
  sortDir: t.union([t.literal("asc"), t.literal("desc")]),
  filters: recordFromString,
});
export type DataTableParams = t.TypeOf<typeof dataTableParams>;

const dataTableParamsMatch = query(dataTableParams).then(end);

export const parseDataTableParams = (): DataTableParams =>
  parse(dataTableParamsMatch.parser, Route.parse(window.location.search), {});

class Columns extends SimpleDTColumns {
  dt: DataTable;

  constructor(dt: DataTable) {
    super(dt);
    this.dt = dt;
  }

  sort(idx: number, dir?: SimpleDataTables.SortDir): void {
    pipe(
      O.Do,
      O.apS("th", pipe(A.lookup(idx)(this.dt.headings), O.map(Q.of))),
      O.bind("col", ({ th }) => pipe(th.getData("column"), O.chain(s => trimFilter(s)))),
      O.map(({ th, col }) => {
        this.dt.setParam("sortCol", col);
        this.dt.setParam("sortDir", DataTable.getTrueSortDir(th, dir));
      })
    );
    super.sort(idx, dir);
  }
}

@autobind
export class DataTable extends SimpleDataTable {
  columns: () => Columns;

  config: BLConfigWithLog;
  element: Q<HTMLTableElement>;
  filters: O.Option<TableFilters>;
  params: DataTableParams;
  readonly initialSort: O.Option<[number, SimpleDataTables.SortDir]>;

  static isAdmin(): boolean {
    return window.location.pathname === "/admin" || window.location.pathname.startsWith("/admin/");
  }

  static getTrueSortDir(th: Q<HTMLTableCellElement>, givenDir?: SimpleDataTables.SortDir): SimpleDataTables.SortDir {
    return pipe(
      O.fromNullable(givenDir),
      O.getOrElse(() => th.hasClass("asc") ? "desc" : "asc")
    );
  }

  constructor(
    element: Q<HTMLTableElement>,
    params: DataTableParams,
    applyParams: boolean,
    options?: SimpleDataTables.Options,
  ) {
    const config = Config(makeConfigLogger());
    const updates = { search: "", page: -1 };
    if (applyParams && DataTable.isAdmin()) {
      let sortApplied: boolean = false;
      R.keys(dataTableParams.props).forEach(key => {
        switch (key) {
          case "page":
            return pipe(
              O.fromNullable(params[key]),
              O.map(page => { updates.page = page; }),
            );
          case "search":
            return pipe(
              O.Do,
              O.apS("search", pipe(O.fromNullable(params[key]), O.chain(s => trimFilter(s)))),
              O.apS("input", Q.one<HTMLInputElement>(`.table-search-proxy[data-table="#${element.getAttr("id")}"]`)),
              O.map(({ search, input }) => {
                input.setAttr("value", search);
                updates.search = search;
              }),
            );
          case "sortCol":
          case "sortDir":
            return sortApplied ? constVoid() : pipe(
              O.fromNullable(params.sortCol),
              O.chain(col => element.one(`th[data-column="${col}"]`)),
              O.map(th => {
                sortApplied = true;
                const exDir = th.hasClass("asc") ? "asc" : th.hasClass("desc") ? "desc" : null;
                element.all("th").map(_ => _.removeClass("asc").removeClass("desc"));
                th.addClass(params.sortDir ?? exDir ?? "asc");
              }),
            );
          case "filters": return;
          default: return config.exhaustive(key);
        }
      });
    }

    super(element.element, options);

    this.config = config;
    this.element = element;
    this.filters = O.none;
    this.params = params;

    const columns = (): Columns => new Columns(this);
    this.columns = columns;

    this.on("datatable.update", () => this.element.triggerCustom("datatable.update"));

    this.initialSort = pipe(
      A.findFirst<[HTMLTableCellElement, number, unknown]>(flow(prop(0), invoke1("matches")(".asc, .desc")))(this.headings.map(tuple)),
      O.map(([th, i]: [HTMLTableCellElement, number, unknown]): [number, SimpleDataTables.SortDir] => {
        const dir: SimpleDataTables.SortDir = Q.of(th).hasClass("asc") ? "asc" : "desc";
        this.columns().sort(i, dir);
        return [i, dir];
      })
    );

    pipe(
      O.Do,
      O.apS("q", trimFilter(updates.search)),
      O.apS("inp", this.origSearchInput),
      O.fold(
        constVoid,
        ({ q, inp }) => {
          inp.setAttr("value", q);
          this.search(q);
        },
      ),
    );

    if (updates.page > 0 && updates.page !== this.currentPage) {
      this.page(updates.page);
    }
  }

  get elementId(): string { return this.element.getAttr("id"); }

  get origSearchInput(): O.Option<Q<HTMLInputElement>> {
    return Q.of(this.wrapper).one<HTMLInputElement>(".dataTable-input");
  }

  get searchInput(): O.Option<Q<HTMLInputElement>> {
    return Q.one(`.table-search-proxy[data-table="#${this.elementId}"]`);
  }

  initCheckedFilters(): void {
    Object.entries(this.params.filters ?? {}).forEach(([key, val]) => {
      Q.all<HTMLInputElement>(`${TableFilters.selector(this.element)}[data-data-key="${key}"][value="${val}"]`).forEach(i =>
        i.setAttr("checked", true),
      );
    });
  }

  updateFilters(ev: QEvent<"change", HTMLInputElement>, cb: () => void): void {
    pipe(
      TableFilters.getKeyAndVal(ev.selectedElement),
      O.map(({ key, val }) => {
        this.setParam(
          "filters",
          (Form.isChecked(ev.selectedElement) ? R.upsertAt(key, val) : R.deleteAt(key))(this.params.filters ?? {}),
        );
        cb();
      }),
    );
  }

  initFilters(): void {
    this.initCheckedFilters();
    const filters = TableFilters.init(this.element, ev => this.updateFilters(ev, () => this.update()));
    this.filters = O.some(filters);
    this.update();
  }

  page(page0: number | string): void {
    const page = typeof page0 === "string" ? parseInt(page0) : page0;
    this.setParam("page", page);
    super.page(page);
  }

  paginate(): number {
    super.paginate();

    pipe(fromNullableOrOption(this.filters), O.map(fs => {
      if (fs.allValues.length > 0) {
        this.pages = A.chunksOf(this.options.perPage)(A.flatten(this.pages).filter(r => fs.matches(Q.of(r))));
        this.totalPages = this.lastPage = this.pages.length;
      }
      this.hasRows = this.pages.some(_ => _.length !== 0);
    }));

    return this.totalPages;
  }

  search(q: string): void {
    this.setParam("search", O.toUndefined(trimFilter(q)));
    super.search(q);
  }

  setParam<K extends keyof DataTableParams>(key: K, value: DataTableParams[K]): void {
    if (DataTable.isAdmin()) {
      this.params[key] = value;

      const updParam = (u: URLSearchParams, k: string) => (vo: O.Option<string>) => {
        pipe(vo, O.fold(() => u.delete(k), v => u.set(k, v)));
        return u;
      };

      replaceUrlState(
        identity,
        identity,
        q => R.keys(dataTableParams.props).reduce(
          (acc: URLSearchParams, k) => {
            switch (k) {
              case "filters":
                return pipe(
                  O.fromNullable(this.params[k]), O.filter(not(R.isEmpty)),
                  O.map(recordFromString.encode),
                  updParam(acc, k),
                );
              case "page":
              case "search":
              case "sortCol":
              case "sortDir":
                return pipe(O.fromNullable(this.params[k]), O.map(v => `${v}`), updParam(acc, k));
              default: return this.config.exhaustive(k);
            }
          },
          q,
        ),
      );
    }
  }

  getPagers(): Q[] {
    return (<Node[]>[].slice.call(this.pagers)).filter(Q.nodeIsElement).map((e: QElement) => Q.of(e)); // eslint-disable-line @typescript-eslint/consistent-type-assertions
  }

  replace(table: Q<HTMLTableElement>): void {
    Q.of(this.wrapper).replaceWith(table);
    this.element = table;
    this.table = table.element;
    this.getPagers().forEach(invoke0("remove"));
    this.render();
  }

  renderPager(): void {
    super.renderPager();
    const upd = (disabled: boolean) => (e: Q) => disabled ? e.setAttrUnsafe("disabled", "disabled") : e.removeAttrUnsafe("disabled");

    this.getPagers().forEach(pager => {
      pipe(
        pager.closest(".dataTable-pagination"),
        O.map(_ => _.addOrRemoveClass("d-none", pager.all("li").length === 0)),
      );

      pager.all("li.pager:not(.ellipsis)").forEach((arrow: Q) => {
        const disabled = A.compact([arrow.prevSibling(), arrow.nextSibling()]).filter(_ => _.hasClass("active")).length > 0;
        upd(disabled)(arrow);
        O.map(upd(disabled))(arrow.one("a"));
      });
    });
  }
}
