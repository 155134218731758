import { constVoid } from "fp-ts/lib/function";

import { Static } from "@scripts/bondlinkStatic";
import { Q } from "@scripts/dom/q";

import { MainNav } from "../mainNav";

export const scrollTo = (el: Q, cb?: () => void): void => {
  globalThis.scrollBy(0, el.element.getBoundingClientRect().top - (MainNav.blBarHeight + Q.remToPx(1)));
  globalThis.setTimeout(cb || constVoid, Static.baseTransitionDelay);
};
