/* eslint-disable curly-quotes/no-straight-quotes */
import { constVoid, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { BLConfigWithLog } from "@scripts/bondlink";

import { Q } from "../dom/q";
import { html } from "../dom/unsafeHtml";
import { Modal } from "../ui/modal";
import { invoke1, invoke2 } from "../util/invoke";
import { tap } from "../util/tap";
import type { MsgData } from "./sessExp";
import { initSessExp, postActivityParent, sessionExpLogin, sessionExpLogout } from "./sessExp";

const warnModalId = "sessWarnModal";
const warnModalCancelId = "sessWarnCancel";
const expModalId = "sessExpModal";

export function sessionExpiration(config: BLConfigWithLog): void {
  pipe(
    config.secReqs,
    O.map(() => {
      let duration: number | undefined;
      const warnModalHide = (hiddenFn: () => unknown) => {
        const warnM = Q.one(`#${warnModalId}`);
        O.fold(
          hiddenFn,
          (m: Q) => Modal.toggle(false)(tap(invoke2("listen")(Modal.closedEvent, hiddenFn))(m))
        )(warnM);
      };

      const warnModal = () =>
        Modal.create(Modal.createElement(
          warnModalId,
          html`Session Expiring`,
          html`Your session will expire in <span class="sess-exp-seconds">${duration}</span> seconds.`,
          O.some(html`<button id="${warnModalCancelId}" type="button" class="confirm-cancel btn btn-secondary">Log out</button>
          <button type="submit" class="confirm-submit btn btn-primary">Keep me logged in</button>`)
        ))
          .listen("click", 'button[type="submit"]', postActivityParent)
          .listen("click", `#${warnModalCancelId}`, () => sessionExpLogout(config));

      const expireModal = () =>
        warnModalHide(() => Modal.create(Modal.createElement(
          expModalId,
          html`Session Expired`,
          html`Please log in again to start a new session.`,
          O.some(html`<button type="submit" class="confirm-submit btn btn-primary">Login</button>`))
          .listen("click", 'button[type="submit"]', () => sessionExpLogin(config))));

      const updateDuration = (nextDuration: number) => O.map(invoke1("setInnerHtml")(html`${nextDuration}`))(Q.one(".sess-exp-seconds"));

      initSessExp(config, updateDuration);

      window.addEventListener("message", (e: MessageEvent): void => {
        const d = <MsgData>e.data; // eslint-disable-line @typescript-eslint/consistent-type-assertions
        if (d.kind === "XDLIframeData") {
          switch (d.action) {
            case "reset": {
              warnModalHide(constVoid);
              return;
            }
            case "warn": {
              warnModal();
              return;
            }
            case "expire": {
              expireModal();
              return;
            }
            case "logout": {
              sessionExpLogout(config);
              return;
            }
          }

          return config.exhaustive(d);
        }
      });
    })
  );
}
