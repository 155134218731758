
/* !
 * Copyright 2015-2019 BondLink, Inc.
 */

import { globals } from "@scripts/generated/globals";
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
(<any>window).globals = globals;

import { init } from "document-viewer-ts";

import { analytics, analyticsVideoTracker } from "@scripts/analytics";
import type { BLConfigWithLog } from "@scripts/bondlink";
import { Q } from "@scripts/dom/q";
import { Form } from "@scripts/form/form";
import { Alert } from "@scripts/ui/alert";
import { initCardLinks } from "@scripts/ui/card";
import { DataFilters } from "@scripts/ui/dataFilters";
import { Expandable } from "@scripts/ui/expandable";
import { Modal } from "@scripts/ui/modal";
import { ShowAll } from "@scripts/ui/showAll";
import { Tabs } from "@scripts/ui/tabs";
import { Tooltip } from "@scripts/ui/tooltip";
import { sessionExpiration } from "@scripts/user/securityRequirements";
import { User } from "@scripts/user/user";

import { bindBookmarks } from "./bookmark";
import { Copy } from "./copy";
import { Disclaimer } from "./disclaimer";
import { DeepLink } from "./dom/deepLink";
import { bindAutoDownload } from "./download";
import { initDraftPreview } from "./drafts";
import { Filters } from "./filters";
import { ImageGallery } from "./imageGallery";
import { JumpLinks } from "./jumpLinks";
import { landerInit } from "./lander";
import { bindBackButton } from "./loginUtil";
import { MainNav } from "./mainNav";
import { Notifications } from "./notifications";
import { StickySidebar } from "./stickySidebar";
import { Table } from "./ui/table";

export const bootstrap = (config: BLConfigWithLog) => (initForms: boolean) => {
  Q.ready(() => {
    User.removeNoRedirParam();
    analytics("Issuer Sites", "IR Page");
    StickySidebar.init();
    initCardLinks();
    Expandable.initBasic(".accordion", ".content");
    Filters.init();
    Disclaimer.init();
    if (initForms) { Form.init(true); }
    ImageGallery.init();
    MainNav.init();
    Modal.init();
    Table.init();
    DataFilters.init();
    JumpLinks.init();
    Tooltip.init();
    Tabs.init();
    DeepLink.init();
    initDraftPreview();
    Alert.init();
    User.initLogoutNotification();
    bindAutoDownload(Q.body);
    bindBookmarks(Q.body);
    Notifications.init();
    sessionExpiration(config);
    Copy.init();
    ShowAll.init();
    landerInit();
    init(`${config.currentBaseUrl}/assets/scripts/pdfjs/pdf.worker.min.mjs`);
    bindBackButton();
    analyticsVideoTracker();
  });
};
