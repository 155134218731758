import { filterMap } from "fp-ts/lib/Array";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { not } from "fp-ts/lib/Predicate";

import { eventName } from "@scripts/bondlink";

import type { QCustomEvent, QEvent } from "../dom/q";
import { Q } from "../dom/q";
import { invoke0, invoke1 } from "../util/invoke";
import { Tooltip } from "./tooltip";

export class Tabs {
  static readonly dataToggleSelector = '[data-toggle="tabs"]';

  static readonly eventNamespace = "tabs";
  static readonly showEvent = eventName(Tabs.eventNamespace, "show");
  static readonly hideEvent = eventName(Tabs.eventNamespace, "hide");

  static init(): void {
    Q.body.listen<"click", HTMLAnchorElement>("click", `${Tabs.dataToggleSelector} .tab-link`,
      Q.prevented((e: QEvent<"click", HTMLAnchorElement>) => pipe(
        O.bindTo("tabsContainer")(e.selectedElement.closest(Tabs.dataToggleSelector)),
        O.bind("targetContainer", (x) => pipe(x.tabsContainer.getData("target"), O.chain(Q.one))),
        O.bind("targetEl", (x) => x.targetContainer.one(e.selectedElement.getRawHref())),
        O.map((x: { tabsContainer: Q, targetContainer: Q, targetEl: Q }) => {
          x.targetContainer.all(".tab-content").filter(not(invoke1("equals")(x.targetEl)))
            .forEach(flow(invoke1("addClass")("d-none"), invoke1("triggerCustom")(Tabs.hideEvent)));
          x.targetEl.removeClass("d-none").triggerCustom(Tabs.showEvent);

          x.tabsContainer.all(".tab-link").forEach(invoke1("removeClass")("current"));
          e.selectedElement.addClass("current");
          return O.some(undefined); // eslint-disable-line no-undefined
        }))));

    Q.body.listen(Tabs.showEvent, (e: QCustomEvent) =>
      filterMap(Tooltip.cache.get)(
        pipe(e.originationElement, O.map(invoke1("all")(Tooltip.contentSelector)), O.getOrElse<Q[]>(() => [])))
        .forEach(invoke0("update")));
  }
}
