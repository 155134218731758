import autobind from "autobind-decorator";
import { constVoid, pipe } from "fp-ts/lib/function";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import * as R from "fp-ts/lib/Record";
import * as T from "fp-ts/lib/Task";
import { fold as foldTE } from "fp-ts/lib/TaskEither";
import * as iots from "io-ts";
import { Columns as SimpleDTColumns } from "simple-datatables-classic/src/columns";
import { button } from "simple-datatables-classic/src/helpers";

import { RA } from "@scripts/fp-ts";
import * as tp from "@scripts/generated/domaintables/tableParams";

import { Q } from "../dom/q";
import { html, UnsafeHtml } from "../dom/unsafeHtml";
import type { RespOrErrors } from "../fetch";
import { fetchUnsafeResp, handleFetchedResp } from "../fetch";
import { Form } from "../form/form";
import { Alert } from "../ui/alert";
import { spinnerCreate, spinnerDestroy } from "../ui/spinner";
import { parseNumber } from "../util/parseNumber";
import { prop } from "../util/prop";
import { trimFilter } from "../util/trimFilter";
import { DataTable, type DataTableParams, dataTableParams } from "./dataTable";
import { TableFilters } from "./filters";

interface Sort { idx: number, col: string, dir: SimpleDataTables.SortDir }

@autobind
export class AjaxColumns extends SimpleDTColumns {
  dt: AjaxTable;

  constructor(dt: AjaxTable) {
    super(dt);
    this.dt = dt;
  }

  getHeader(idx: number): O.Option<Q<HTMLTableCellElement>> {
    return O.map(Q.of)(RA.lookup(idx, this.dt.headings));
  }

  renderSort(): void {
    O.map((sort: Sort) => O.map((th: Q<HTMLTableCellElement>) => {
      this.dt.headings.forEach((t: HTMLTableCellElement) => Q.of(t).removeClass(["asc", "desc"]));
      th.addClass(sort.dir);
    })(this.getHeader(sort.idx)))(this.dt.currSort);
  }

  sort(idx: number, direction?: SimpleDataTables.SortDir): void {
    this.setCurrSort([idx, direction]);
    this.dt.load();
  }

  setCurrSort([idx, direction]: [number, SimpleDataTables.SortDir | undefined]): void {
    O.map((th: Q<HTMLTableCellElement>) =>
      O.map((col: string) => {
        const dir = DataTable.getTrueSortDir(th, direction);
        this.dt.setParam("sortCol", col);
        this.dt.setParam("sortDir", dir);
        this.dt.currSort = O.some<Sort>({ idx, col, dir });
      })(th.getData("column"))
    )(this.getHeader(idx));
  }
}

@autobind
export class AjaxTable extends DataTable {
  columns: () => AjaxColumns;
  load: () => void;
  currSort: O.Option<Sort> = O.none;
  currFilter: O.Option<string> = O.none;
  currPage: number = 1;
  searchTimeout: O.Option<number> = O.none;

  constructor(element: Q<HTMLTableElement>, params: DataTableParams, options?: SimpleDataTables.Options) {
    super(element, params, false, options);

    let paramsApplied: boolean = false;
    let sortApplied: boolean = false;

    if (DataTable.isAdmin()) {
      R.keys(dataTableParams.props).forEach(key => {
        switch (key) {
          case "page": return pipe(O.fromNullable(params[key]), O.filter(p => p !== this.currPage), O.map(p => {
            paramsApplied = true;
            this.currPage = p;
          }));
          case "search": return pipe(O.fromNullable(params[key]), O.chain(s => trimFilter(s)), O.map(s => {
            paramsApplied = true;
            pipe(this.searchInput, O.map(_ => _.setAttr("value", s).trigger("input")));
            this.currFilter = O.some(s);
          }));
          case "sortCol":
          case "sortDir":
            return sortApplied ? constVoid() : pipe(
              O.Do,
              O.apS("col", O.fromNullable(params.sortCol)),
              O.bind("th", ({ col }) => pipe(
                O.Do,
                O.apS("idx", pipe(this.headings, RA.findIndex(th => Q.of(th).matches(`[data-column="${col}"]`)))),
                O.bind("th", ({ idx }) => pipe(RA.lookup(idx)(this.headings), O.map(Q.of))),
              )),
              O.map(({ col, th: { idx, th } }) => {
                sortApplied = true;
                this.currSort = O.some({
                  idx: idx,
                  col,
                  dir: params.sortDir ?? (th.hasClass("desc") ? "desc" : "asc"),
                });

                // Only trigger a reload of the table if there was a sort dir in the URL
                // and the table isn't already sorted that way
                if (typeof params.sortDir !== "undefined" && !th.hasClass(params.sortDir)) {
                  paramsApplied = true;
                }
              }),
            );
          case "filters": return pipe(O.fromNullable(params[key]), O.map(() => {
            paramsApplied = true;
          }));
          default: return this.config.exhaustive(key);
        }
      });
    }

    const columns = (): AjaxColumns => new AjaxColumns(this);
    this.columns = columns;
    pipe(this.initialSort, O.filter(() => !sortApplied), O.map(this.columns().setCurrSort));

    this.load = constVoid;
    const getCount = () => O.chain(parseNumber)(this.element.getData("count"));

    O.fold(
      () => this.config.log.error("Failed to initialize AJAX table because URL or count is missing", this.element),
      ({ ajaxUrl, totalCount }: { ajaxUrl: string, totalCount: number }) => {
        const updatePages = (count: number) => {
          this.setParam("page", this.currPage);
          this.currentPage = this.currPage;
          this.totalPages = this.lastPage = Math.ceil(count / this.options.perPage);
          this.pages = NEA.range(1, this.totalPages).map((i: number) =>
            i === this.currPage ? this.element.all<HTMLTableRowElement>("tbody tr").map(prop("element")) : []);
          this.links = this.pages.map((_: unknown, i: number) => button(i === 0 ? "active" : "", i + 1, (i + 1).toString()));
        };

        this.load = (): void => {
          const page: ReadonlyArray<[tp.TableParamU, string]> = [[tp.page, this.currPage.toString()]];
          const sort: ReadonlyArray<[tp.TableParamU, string]> = pipe(
            this.currSort,
            O.fold(() => [], s => [[tp.sortCol, s.col], [tp.sortDir, s.dir]]),
          );
          const filter: ReadonlyArray<[tp.TableParamU, string]> = pipe(
            this.currFilter,
            O.fold(() => [], f => [[tp.search, f]]),
          );
          const additionalFilters: ReadonlyArray<[tp.TableParamU, string]> = pipe(
            O.fromNullable(this.params.filters),
            O.fold(() => [], fs => [[tp.filters, JSON.stringify(fs)]]),
          );
          const qs = new URLSearchParams(page.concat(sort).concat(filter).concat(additionalFilters).map(([p, s]) => [p.key, s]));
          const url = `${ajaxUrl}${ajaxUrl.includes("?") ? "&" : "?"}${qs.toString()}`;

          spinnerCreate();
          pipe(
            fetchUnsafeResp(this.config)({ url, method: "GET" }, { cache: "no-cache" }),
            handleFetchedResp(iots.string, "Parse string"),
            foldTE(
              (res: RespOrErrors) => {
                this.config.log.error("Failed to load AJAX table", res);
                return T.of(pipe(
                  this.element.parent(),
                  O.alt(() => Alert.container),
                  O.map((q: Q) => Alert.showInContainer(q, "ajax-table-error", "alert-danger", html`${Form.genericErrorMessage}`, 0))
                ));
              },
              (r: [Response, string]) => {
                pipe(
                  Q.parseFragment(UnsafeHtml(r[1]), "text/html"),
                  O.chain((e: Q) => e.one<HTMLTableElement>(`#${this.elementId}`)),
                  O.fold(
                    () => this.config.log.error("Failed to parse HTML from AJAX table load", r[1]),
                    this.replace
                  ),
                );
                return T.of(undefined); // eslint-disable-line no-undefined
              }
            ),
            T.map(spinnerDestroy)
          )();
        };

        this.search = (query: string): void => {
          O.map<number, void>(window.clearTimeout)(this.searchTimeout);
          this.searchTimeout = O.some(window.setTimeout(() => {
            this.currFilter = trimFilter(query);
            this.setParam("search", O.toUndefined(this.currFilter));
            if (this.currPage !== 1) {
              this.page(1);
            } else {
              this.load();
            }
          }, 1200));
        };

        this.page = (page0: number | string): void => {
          const page = typeof page0 === "string" ? parseInt(page0) : page0;
          if (page === this.currPage) { return; }
          this.currPage = page;
          this.emit("datatable.page", this.currPage);
          this.load();
        };

        this.paginate = (): number => {
          O.map(updatePages)(getCount());
          this.renderPage();
          this.renderPager();
          columns().renderSort();
          return this.totalPages;
        };

        updatePages(totalCount);
        this.paginate();

        if (paramsApplied) {
          if (O.isSome(this.currFilter)) {
            this.currPage = 1;
          }
          this.load();
        }
      }
    )(pipe(
      O.bindTo("ajaxUrl")(this.element.getData("ajax-url")),
      O.bind("totalCount", getCount)
    ));
  }

  initFilters(): void {
    this.initCheckedFilters();
    const filters = TableFilters.init(this.element, ev => this.updateFilters(ev, () => this.load()));
    this.filters = O.some(filters);
  }
}
