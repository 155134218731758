/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import autobind from "autobind-decorator";
import { sequenceT } from "fp-ts/lib/Apply";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import { constant, constVoid, flow, identity, pipe, tuple } from "fp-ts/lib/function";
import * as IO from "fp-ts/lib/IO";
import { values } from "fp-ts/lib/Map";
import type { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { getSemigroup } from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import { not } from "fp-ts/lib/Predicate";
import { fromFoldable, toArray } from "fp-ts/lib/Record";
import * as Sg from "fp-ts/lib/Semigroup";
import * as s from "fp-ts/lib/string";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import * as t from "io-ts";
import { Key } from "ts-key-enum";

import { eventName } from "@scripts/bondlink";
import { CUSTOM } from "@scripts/generated/domaintables/errorCodes";
import type { ApiRedirect, ApiReload } from "@scripts/generated/models/apiRedirect";
import { apiRedirectC, apiReloadC } from "@scripts/generated/models/apiRedirect";
import { openInSameTab } from "@scripts/routes/router";
import { logErrors, makeConfigLogger } from "@scripts/util/log";

import * as bondlinkUser from "../bondlinkUser";
import { Config } from "../csr-only/config";
import type { QCustomEvent, QElement, QEvent } from "../dom/q";
import { CachedElements, Q } from "../dom/q";
import type { UnsafeHtml } from "../dom/unsafeHtml";
import { html, joinHtml } from "../dom/unsafeHtml";
import type { FetchUnsafeResp, RespOrErrors, UnsafeResp } from "../fetch";
import { fetchJsonUnsafe, parseUnsafeResp } from "../fetch";
import type { UrlInterface } from "../routes/urlInterface";
import { Alert, type AlertType } from "../ui/alert";
import { Expandable } from "../ui/expandable";
import { Modal } from "../ui/modal";
import { spinnerCreate, spinnerDestroy } from "../ui/spinner";
import { eq } from "../util/eq";
import { eqOrd } from "../util/instances";
import { invoke0, invoke1 } from "../util/invoke";
import { deepArrSg, isObj, mergeDeep, mergeWith } from "../util/merge";
import { parseBool } from "../util/parseBool";
import { parseAsJsonAndDecode } from "../util/parseJson";
import { mkDeepObj } from "../util/path";
import { prop } from "../util/prop";
import { tap } from "../util/tap";
import { trimFilter } from "../util/trimFilter";
import { wrapArr } from "../util/wrapArr";
import { Basil } from "./basil";
import { formatError } from "./formatError";
import * as FormUtil from "./formUtil";
import { ModelField } from "./modelField";
import type { RC } from "./recaptcha";
import { Recaptcha } from "./recaptcha";
import type { ResponseErrors } from "./responseErrors";
import { decodeResponseErrors } from "./responseErrors";
import type { FieldValidationErrors } from "./validationErrors";

const config = Config(makeConfigLogger());

const buildEls = <E extends QElement = QElement>(els: E[], root: Q): Array<Q<E>> => els.map((e: E) => Q.of(e, O.some(root)));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormData = Record<string, any>;

export interface FormModal {
  forms: Form[];
  modal: Q;
}

export type Preprocessor = (data: FormData) => T.Task<FormData>;

export interface FormError {
  element: Q;
  error: string;
}

export const formErrToFieldErrs = (fe: FormError): FieldValidationErrors => [[
  ModelField.fromString(O.getOrElse(() => "")(fe.element.getAttrO("name"))),
  [{ error: CUSTOM, extra: fe.error }],
]];

export type Validated = E.Either<NonEmptyArray<FormError>, unknown>;
export type Validator = (data: FormData) => Validated;

export type BeforeSubmitFn = (data: FormData) => TE.TaskEither<unknown, FormData>;
export type SuccessFn = (res: UnsafeResp, data: FormData) => TE.TaskEither<unknown, void>;
export type ErrorFn = (m: ModelField, label: O.Option<string>, value: O.Option<UnsafeHtml>, extra: O.Option<string>) => UnsafeHtml;

const apiRespC = t.union([apiRedirectC, apiReloadC]);

@autobind
export class Form {
  static readonly cache = new CachedElements("form", "form[data-action]", Form);
  static readonly genericErrorMessage = html`An error has occurred. Please try again or contact <a href="mailto:${config.customerSuccessEmail}">BondLink</a> for support.`;

  static readonly passwordStrengthRequirement = 2;
  static readonly customSelectSelector = ".custom-select"; // TODO: Put CSSType back in place once form CSS is available in root assets dir
  static readonly customSelectWrapperSelector = ".custom-select-wrapper"; // TODO: Put CSSType back in place once form CSS is available in root assets dir
  static readonly customSelectOptionsSelector = ".custom-select-options"; // TODO: Put CSSType back in place once form CSS is available in root assets dir
  static readonly customOptionSelector = ".custom-option"; // TODO: Put CSSType back in place once form CSS is available in root assets dir
  static readonly checkboxSelector = 'input[type="checkbox"]';
  static readonly textInputTypes = ["email", "password", "text", "url"];

  static readonly eventNamespace = "form";
  static readonly initEvent = eventName(Form.eventNamespace, "init");
  static readonly modalInitEvent = eventName(Form.eventNamespace, "modal:init");
  static readonly beforeSubmitEvent = eventName(Form.eventNamespace, "beforeSubmit");
  static readonly successEvent = eventName(Form.eventNamespace, "success");
  static readonly failEvent = eventName(Form.eventNamespace, "fail");
  static readonly alwaysEvent = eventName(Form.eventNamespace, "always");

  private element: Q<HTMLFormElement>;
  private readonly basil: Basil<Q>;
  private readonly preprocessors: Preprocessor[] = [];
  private readonly validators: Validator[] = [];
  private readonly _beforeSubmit: BeforeSubmitFn[] = [];
  private readonly _onSuccess: SuccessFn[] = [];

  static init(initRecaptcha: boolean): void {
    Form.initCustomSelects(Q.body);
    Form.initCheckboxGroups(Q.body);
    Form.initShowPassword(Q.body);

    if (initRecaptcha) {
      Recaptcha.init();
    }

    const initCaptcha = (recaptcha: RC) => Form.initRecaptcha(Q.body, recaptcha);
    O.fold(
      () => { Q.root.listenOnce(Recaptcha.loadEvent, (e) => O.map(initCaptcha)(O.fromNullable(e.getAttr("detail")))); },
      (recaptcha: RC) => initCaptcha(recaptcha)
    )(Recaptcha.grecaptcha);

    Q.body.listen("click", () => {
      Q.all(Form.customSelectSelector).forEach(invoke1("removeClass")("focus"));
      Q.all(Form.customSelectWrapperSelector).forEach(invoke1("removeClass")("show"));
    });

    Q.body.listen("click", ".input-group-clear", (e: QEvent) =>
      pipe(
        e.selectedElement.closest(".input-group"),
        O.chain((g: Q) => g.one<HTMLInputElement>("input")),
        O.map((input: Q<HTMLInputElement>) => input.setAttr("value", "").trigger("change").trigger("input"))));

    const getForm = (e: QEvent) => O.chain(Form.cache.get)(e.selectedElement.closest("form"));

    Q.body.listen("blur", "input, textarea", (e: QEvent) =>
      pipe(
        e.selectedElement.getData("auto-focus"),
        O.chain(parseBool),
        O.filter(identity),
        O.fold(
          () => Form.removeAutoFocus(e.selectedElement),
          constVoid
        )));

    const changeHandler = (e: QEvent) => pipe(
      getForm(e),
      O.map((form: Form) => {
        Form.removeAutoFocus(e.selectedElement);
        form.basil.clearErrors(e.selectedElement);
        FormUtil.formChangeWarning(form.element);
      }));

    Q.body.listen("keyup", "input, textarea", changeHandler);
    Q.body.listen("change", `select, textarea, ${Form.checkboxSelector}, input[type="radio"], input[data-for]`, changeHandler);
    Q.body.listen("click", ".form-success-content .success-container-back", (e: QEvent) =>
      pipe(
        e.selectedElement.closest(".form-success-content"),
        O.chain((succ: Q) =>
          pipe(
            Q.one(`[data-success-container="#${succ.getAttr("id")}"]`),
            O.map((orig: Q) => {
              succ.addClass("d-none");
              orig.removeClass("d-none");
            })))));

    Form.cache.init();
  }

  static forgotPasswordModalButton(): O.Option<UnsafeHtml> {
    return pipe(
      Modal.getModalInDom("#forgotPasswordModal"),
      O.map(() => html`<button class="btn-link" data-toggle="modal" data-target="#forgotPasswordModal">Forgot your password?</button>`));
  }

  static removeAutoFocus(e: Q): void { e.removeData("auto-focus"); }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getElementValue(q: Q<HTMLElement & { value: string }>): O.Option<any> {
    const el = q.element;
    const rawVal = O.fromNullable(q.element?.value);

    const isJson = (): boolean => pipe(
      q.getData("json"),
      O.chain(parseBool),
      O.filter(identity),
      O.isSome,
    );

    if (isJson()) {
      return O.chain((rv: string) => O.tryCatch(() => JSON.parse(rv)))(rawVal);
    } else if (el instanceof HTMLOptionElement) {
      return pipe(rawVal, O.filter(() => el.selected));
    } else {
      return rawVal;
    }
  }

  static updArrFields(obj: FormData): FormData {
    return fromFoldable(Sg.last<string>(), A.Foldable)(toArray(obj).map(([k, v]): [string, string] => {
      const isArrField = k.endsWith("[]");

      const val = isArrField ? wrapArr(v) : v;
      return [isArrField ? k.replace(/\[\]$/, "") : k, isObj(val) ? Form.updArrFields(val) : val];
    }));
  }

  static deepMerge(_objs: FormData[]): FormData {
    return _objs.map(Form.updArrFields).reduce((acc: FormData, o: FormData) => mergeWith(deepArrSg)(acc)(o), {});
  }

  static isChecked(e: Q<HTMLInputElement>): boolean {
    return e.element?.checked ?? true;
  }

  static getFormAsObject(form: Q<HTMLFormElement>): FormData {
    const elsWithNames = A.filterMap(
      (q: Q<HTMLInputElement>) => pipe(
        trimFilter(q.getAttrO("name")),
        O.chain(ModelField.split),
        O.map((mf: string[]) => tuple(q, mf))))(
          buildEls([].slice.call(form.getAttr("elements")), form));
    const filtered = elsWithNames.filter(([q]: [Q<HTMLInputElement>, string[]]) =>
      !(q.element?.disabled ?? false)
        && (q.element?.type ?? false) ? !["button", "file", "reset"].includes(q.element.type) : true);
    return Form.deepMerge(A.filterMap(([q, nameParts]: [Q<HTMLInputElement>, string[]]) => {
      const val = Form.getElementValue(q);
      const mkObj = (vo: O.Option<unknown>) => O.map(mkDeepObj(nameParts))(vo);
      return pipe(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        O.some(q.element as unknown as QElement & { type: string, options: unknown }),
        O.filter(_ => (_?.type || _?.options) ? true : false),
        O.fold(
          () => mkObj(val),
          (e: QElement & { type: string, options: unknown }) => {
            if (e.type === "select-multiple" && e?.options) {
              return mkObj(O.some(A.filterMap(Form.getElementValue)(buildEls([].slice.call(e.options), q))));
            } else if (e.type === "checkbox" && O.fold(() => false, eq("true"))(val)) {
              return mkObj(O.some(Form.isChecked(q) ? "true" : "false"));
            } else if (e.type === "checkbox" || e.type === "radio") {
              return Form.isChecked(q) ? mkObj(val) : O.none;
            } else if (Form.textInputTypes.includes(e.type.toLowerCase())) {
              return mkObj(O.filter(not(s.isEmpty))(val));
            } else {
              return mkObj(val);
            }
          }));
    })(filtered));
  }

  static updateCustomSelect(dropdown: Q<HTMLSelectElement>, val: string | Q): void {
    pipe(
      A.head(dropdown.siblings(Form.customSelectWrapperSelector)),
      O.map((wrapper: Q) =>
        pipe(
          (Q.isQ(val) ? O.some(val) : wrapper.one(`${Form.customOptionSelector}[data-value="${val}"]`)),
          O.map((selected: Q) => {
            wrapper.all(`${Form.customSelectOptionsSelector} ${Form.customOptionSelector}`).forEach((e: Q) => e.removeClass("selected"));
            selected.addClass("selected");
            dropdown.setAttr("value", O.getOrElse(() => "")(selected.getData("value")));
            dropdown.trigger("change");
            pipe(
              wrapper.one(`${Form.customSelectSelector} span`),
              O.map((sel: Q) => {
                sel.removeClass("gray-500");
                sel.setInnerHtml(selected.getInnerHtml());
              })
            );

            wrapper.removeClass("show");
          }))));
  }

  static initCustomSelects(root: Q): void {

    root.listen("focus", Form.customSelectSelector, (e: QEvent) => {
      e.stopPropagation();
      e.selectedElement.addClass("focus");
    });

    root.listen("blur", Form.customSelectSelector, (e: QEvent) => {
      e.stopPropagation();
      e.selectedElement.removeClass("focus");
    });

    root.listen("keyup", Form.customSelectSelector, (e: QEvent<"keyup">) => {
      if (e.event.key === Key.Enter) {
        e.selectedElement.trigger("click");
      }
    });

    root.listen("click", Form.customSelectSelector, (e: QEvent) => {
      e.stopPropagation();
      pipe(
        sequenceT(O.Applicative)(
          e.selectedElement.closest(Form.customSelectWrapperSelector),
          A.head(e.selectedElement.siblings(Form.customSelectOptionsSelector))),
        O.map(([wrapper]) => {
          const showing = !wrapper.hasClass("show");
          wrapper.addOrRemoveClass("show", showing);
        }));
    });

    root.listen("click", Form.customOptionSelector, (e: QEvent) =>
      pipe(
        e.originationElement,
        O.chain(invoke1("getData")("toggle")),
        O.fold(
          () => {
            return pipe(
              O.some(e.selectedElement),
              O.filter(not(invoke0("isDisabled"))),
              O.chain(invoke1("closest")(Form.customSelectWrapperSelector)),
              O.chain(flow(invoke1("siblings")(`select${Form.customSelectSelector}`), A.head)),
              O.filter(Q.isOfType("select")),
              O.map((dropdown: Q<HTMLSelectElement>) => Form.updateCustomSelect(dropdown, e.selectedElement)));
          },
          () => {
            return O.none;
          }))
    );
  }

  static initCheckboxGroups(root: Q): void {
    const groupCbs = (group: Q) => group.all<HTMLInputElement>(Form.checkboxSelector);
    const toggleDisabled = (disabled: boolean) => (group: Q) => groupCbs(group).forEach((cb: Q<HTMLInputElement>) => cb.setAttr("disabled", disabled));
    const toggleCb = (checked: boolean) => (cb: Q<HTMLInputElement>) => cb.setAttr("checked", checked).trigger("change");
    const toggleAllG = (checked: boolean) => (group: Q) => groupCbs(group).forEach(toggleCb(checked));

    Expandable.init(root, "change", ".checkbox-group-control", (c: Q) => O.chain(Q.one)(c.getData("group")),
      (e: QEvent, group: Q) => {
        const checked = e.selectedElement.matches(":checked");
        toggleDisabled(!checked)(group);
        if (checked && groupCbs(group).every(not(invoke1("matches")(":checked")))) {
          toggleAllG(checked)(group);
        }
      });

    const toggleAll = (checked: boolean) => Q.prevented((e: QEvent) =>
      O.map(toggleAllG(checked))(e.selectedElement.closest(".checkbox-group")));

    root.listen("click", ".checkbox-group-select-all", toggleAll(true));
    root.listen("click", ".checkbox-group-unselect-all", toggleAll(false));
  }

  static initShowPassword(root: Q): void {
    root.listen("click", ".show-password", Q.prevented((e: QEvent) => {
      pipe(
        e.selectedElement.getData("target"),
        O.chain(Q.one),
        O.filter(Q.isOfType("input")),
        O.map((input: Q<HTMLInputElement>) => {
          const wasText = input.getAttr("type") === "text";
          e.selectedElement.addOrRemoveClass("show", !wasText);
          input.setAttr("type", wasText ? "password" : "text");
        }));
    }));
  }

  static recaptchaPreprocessor(el: Q<HTMLElement>, recaptcha: RC): Preprocessor {
    const captchaPath = pipe(el.getData("path"), O.getOrElse(() => "")).split(".").filter(not(eq("")));
    if (captchaPath.length === 0) {
      return T.of;
    }
    const rcId = recaptcha.render(el.element, { "expired-callback": () => Recaptcha.reset() });
    return (d: FormData) => pipe(Recaptcha.execute(O.some(rcId)), TE.fold(() => T.of(d), (res: string) => T.of(mergeDeep(d)(mkDeepObj(captchaPath)(res)))));
  }

  static initRecaptcha(root: Q, recaptcha: RC): void {
    const initRecaptchaForForm = (form: Form) =>
      pipe(form.element.one<HTMLElement>(".g-recaptcha:not(.manual-init)"),
        O.map((captchaEl: Q<HTMLElement>) => form.addPreprocessor(Form.recaptchaPreprocessor(captchaEl, recaptcha))));

    values(eqOrd<Form>())(Form.cache.cache).forEach(initRecaptchaForForm);
    root.listen(Form.initEvent, (e: QCustomEvent) => O.map(initRecaptchaForForm)(O.fromNullable(e.getAttr("detail"))));
    root.listen(Form.alwaysEvent, () => Recaptcha.reset());
  }

  static showFormTemplateModal(id: string): O.Option<FormModal> {
    return O.map(Form.initModalForm)(Modal.showTemplate(id));
  }

  static initModalForm(modal: Q): FormModal {
    modal
      .triggerCustom(Form.modalInitEvent, { clonedModal: modal })
      .listen("hide.bs.modal", () => FormUtil.disableFormChangeWarning());

    return {
      forms: A.filterMap(Form.cache.get)(modal.all("form[data-action]")),
      modal,
    };
  }

  constructor(element: Q<HTMLFormElement>) {
    this.element = element;
    this.setElement(element);

    this.basil = new Basil({
      element: this.element,
      inputErrorClass: "has-danger",
      createErrorsWrapper: () => Q.createElement("div", [], []),
      createExpectedErrorElement: () => Q.createElement("div", [invoke1("addClass")("error-text")], []),
      createUnexpectedErrorElement: () => Q.createElement("li", [invoke1("addClass")("error-text")], []),
      classHandler: (field: Q): O.Option<Q> => pipe(
        field.closest(".form-input"),
        O.alt(() => field.closest("div"))),
      formatError: formatError(Form.getElementValue, Form.forgotPasswordModalButton),
      showUnexpectedErrors: es => this.showUnexpectedErrors(es)(),
      dom: {
        getOne: (container: O.Option<Q>, selector: string): O.Option<Q> => pipe(container, O.getOrElse<Q>(() => Q.root)).one(selector),
        getAll: (container: O.Option<Q>, selector: string): Q[] => pipe(container, O.getOrElse<Q>(() => Q.root)).all(selector),
        closest: (el: Q | undefined, selector: string): O.Option<Q> => pipe(O.fromNullable(el), O.chain(_ => _.closest(selector))),
        getAttr: (el: Q | undefined, attr: string): O.Option<string> => pipe(O.fromNullable(el), O.chain(_ => _.getAttrO(attr))),
        addClass: (el: Q | undefined, klass: string): Q | undefined => el?.addClass(klass),
        removeClass: (el: Q | undefined, klass: string): Q | undefined => el?.removeClass(klass),
        append: (container: Q | undefined, el: Q | undefined): Q | undefined => el && container?.append(el),
        remove: (el: Q | undefined): void => el?.remove(),
        equals: (el1: Q | undefined, el2: Q | undefined): boolean => el1 != null && el2 != null && el1.equals(el2),
        getInnerText: (el: Q | undefined): O.Option<string> => pipe(O.fromNullable(el), O.chain(_ => _.getInnerText())),
        setInnerHtml: (el: Q | undefined, h: UnsafeHtml): Q | undefined => el?.setInnerHtml(h),
      },
    });
  }

  getElement(): Q<HTMLFormElement> { return this.element; }
  setElement(e: Q<HTMLFormElement>): void {
    this.element = e;
    this.init();
  }

  init(): void {
    this.bindEvents();
    this.element.triggerCustom(Form.initEvent, this);
  }

  addPreprocessor(p: Preprocessor): void {
    this.preprocessors.push(p);
  }

  addValidator(v: Validator): void {
    this.validators.push(v);
  }

  addBeforeSubmit(fn: BeforeSubmitFn): void {
    this._beforeSubmit.push(fn);
  }

  addOnSuccess(cb: SuccessFn): void {
    this._onSuccess.push(cb);
  }

  prependOnSuccess(cb: SuccessFn): void {
    this._onSuccess.unshift(cb);
  }

  getOnSuccess(): SuccessFn[] {
    return this._onSuccess;
  }

  showFormErrors(errs: NonEmptyArray<FormError>): void {
    errs.map((e: FormError) => this.basil.addErrorsForField(formErrToFieldErrs(e), e.element));
  }

  validate(): TE.TaskEither<NonEmptyArray<FormError>, FormData> {
    return pipe(
      this.preprocessors.reduce(
        (acc: T.Task<FormData>, p: Preprocessor) => T.chain(p)(acc), T.of(Form.getFormAsObject(this.element))),
      T.chain((data: FormData) => pipe(
        A.sequence(E.getApplicativeValidation(getSemigroup<FormError>()))(
          this.validators.map((v: Validator) => v(data))),
        E.fold(
          (e: NonEmptyArray<FormError>) => T.of(E.left(e)),
          () => T.of(E.right(data))))
      ));
  }

  bindEvents(): void {
    const failed = T.of(E.left<O.Option<Response>, UnsafeResp>(O.none));
    this.element.listen("submit", Q.prevented(() => {
      pipe(
        this.validate(),
        TE.fold(
          (e: NonEmptyArray<FormError>) => {
            this.showFormErrors(e);
            return failed;
          },
          (data: FormData) =>
            O.fold(
              () => failed,
              (url: string) =>
                TE.fold(() => failed, (r: UnsafeResp) => T.of(E.right<O.Option<Response>, UnsafeResp>(r)))(this.submit({ url, method: "POST" }, data))
            )(this.element.getData("action"))))();
    }));
  }

  showMessage(id: string, alertType: AlertType, msg: UnsafeHtml): IO.IO<void> {
    const remove = () => O.map(invoke0("remove"))(Q.one(`#${id}`));
    remove();

    const addErr: (err: Q) => Q = pipe(
      // First try to show the error in the form's `msg-selector`
      pipe(this.element.getData("msg-selector"), O.chain(Q.one), O.map(_ => _.append)),
      // Next try to display it after the form's submit button
      O.alt(() => pipe(
        this.basil.submitEl(),
        O.map(b => pipe(b.closest(".modal-footer"), O.fold(() => b.after, f => f.append))),
      )),
      // Next check if the form is in a table row and if so prepend a new table row with the error
      O.alt(() => pipe(
        this.element.closest("tr"),
        O.map(tr => (err: Q) => tr.before(Q.createElement(
          "tr",
          [_ => _.setId(id)],
          [Q.createElement("td", [_ => _.setAttr("colSpan", tr.all("td").length)], [err.removeAttrUnsafe("id")])],
        ))),
      )),
      // Fallback to displaying the error after the form
      O.getOrElse((): (err: Q) => Q => this.element.after),
    );

    const alert = Alert.dismissibleMessage(id, alertType, msg);
    addErr(alert);

    alert.listenOnce("click", ".btn-close", () => remove);
    this.element.listenOnce("submit", remove);

    return IO.of(constVoid());
  }

  handleErrors(errors: O.Option<ResponseErrors>): IO.IO<void> {
    return pipe(
      errors,
      O.fold(
        () => this.showUnexpectedErrors([]),
        es => pipe(
          IO.of(this.basil.addErrors(es)),
          IO.chainFirst(() => (
            E.toUnion(this.basil.partitionErrors(es).expected).length > 0
            && pipe(this.element.getData("expected-msg"), O.chain(parseBool), O.fold(() => true, not(eq<boolean>(false))))
          ) ? this.showMessage("expected-error", "alert-danger", html`Please review and edit any highlighted fields above.`)
            : IO.of(constVoid()))
        )
      ),
    );
  }

  showUnexpectedErrors(errors: ReadonlyArray<Q>): IO.IO<void> {
    const unexpectedErrorId = "unexpected-error";
    const errorMessage = (errors.length > 0)
      ? html`<ul class="my-0 ml-05">${joinHtml(errors.map(e => e.getOuterHtml()))}</ul>`
      : Form.genericErrorMessage;
    return this.showMessage(unexpectedErrorId, "alert-danger", errorMessage);
  }

  defaultSuccessHandler(res: UnsafeResp): TE.TaskEither<unknown, void> {
    return pipe(
      parseUnsafeResp(apiRespC, "info", "Parse apiRespC")(res),
      TE.chain(([, r]: [Response, ApiRedirect | ApiReload]) => TE.rightIO(() => {
        if (apiReloadC.is(r)) {
          window.location.reload();
        } else {
          const currUrl = window.location.href;
          const redirWithoutHash = r.redirect.replace(/#.*$/, "");
          openInSameTab(r.redirect);
          if (currUrl === redirWithoutHash || currUrl === window.location.origin + redirWithoutHash) {
            window.location.reload();
          }
        }
      })),
      TE.alt(() => TE.of<RespOrErrors, void>(constVoid())),
      TE.chainFirst(() => TE.rightIO(() => pipe(
        this.element.getData("success-container"),
        O.chain(Q.one),
        O.fold(
          () => {
            pipe(
              this.element.closest(Modal.modalSelector),
              O.filter(() => O.toUndefined(this.element.getData("form-modal-close")) !== "false"),
              O.map(Modal.toggle(false)),
            );
          },
          (c: Q) => {
            this.element.addClass("d-none");
            c.removeClass("d-none");
          })))),
      TE.map(constVoid)
    );
  }

  submit(url: UrlInterface<"POST">, data: FormData): FetchUnsafeResp {
    return pipe(
      this.beforeSubmit(url, data),
      TE.chain(() => fetchJsonUnsafe(config)(data)(url, {
        headers: { "BL-IssuerId": pipe(bondlinkUser.getIssuer(), O.map(prop("id")), O.getOrElse(() => 0)).toString() },
      })),
      TE.chain(r => this.onSuccess(r, data)),
      TE.orElse(this.onFail),
      TE.bimap(tap(this.always), tap(this.always)));
  }

  private beforeSubmit(url: UrlInterface<"POST">, data: FormData): TE.TaskEither<O.Option<Response>, FormData> {
    return pipe(
      TE.of<O.Option<Response>, void>(constVoid()),
      TE.filterOrElse<O.Option<Response>, void>(() => !FormUtil.isFormLocked(this.element), () => O.zero<Response>()),
      TE.chain(() => pipe(this._beforeSubmit.reduce(
        (acc: TE.TaskEither<unknown, FormData>, fn: BeforeSubmitFn) => pipe(acc, TE.chain(fn)),
        TE.of(data)), TE.mapLeft(constant(O.zero<Response>())))),
      TE.map((finalData: FormData) => {
        FormUtil.lockForm(this.element);
        spinnerCreate();
        this.element.triggerCustom(Form.beforeSubmitEvent, { form: this, url });
        return finalData;
      }));
  }

  private always(): void {
    FormUtil.unlockForm(this.element);
    spinnerDestroy();
    this.element.triggerCustom(Form.alwaysEvent, this);
  }

  private onSuccess(res: UnsafeResp, data: FormData): FetchUnsafeResp {
    return pipe(
      this._onSuccess.length > 0 ? this._onSuccess : [this.defaultSuccessHandler],
      A.traverse(TE.ApplicativeSeq)((fn: SuccessFn) => fn(res, data)),
      TE.bimap(() => O.some(res.resp), () => res),
      TE.map(tap(() => this.element.triggerCustom(Form.successEvent, this)))
    );
  }

  private onFail(res: O.Option<Response>): FetchUnsafeResp {
    return pipe(
      res,
      O.map(
        (r: Response) => pipe(
          TE.tryCatch(() => r.text(), constant(O.some(r))),
          TE.chain((str: string) =>
            pipe(
              TE.fromEither(parseAsJsonAndDecode(decodeResponseErrors(config), "warn", "Parse responseErrorsC")(str)),
              TE.mapLeft(logErrors),
              TE.fold(
                () => TE.rightIO(this.handleErrors(O.none)),
                errs => TE.rightIO(this.handleErrors(O.some(errs)))
              ),
              TE.fold(() => T.of(E.left(res)), () => TE.of<O.Option<Response>, UnsafeResp>({ kind: "text", resp: r, data: str }))
            )
          )
        )
      ),
      O.getOrElse(() =>
        pipe(TE.rightIO(this.handleErrors(O.none)), TE.mapLeft(() => res), TE.chain(() => T.of(E.left<O.Option<Response>, UnsafeResp>(O.none))))),
      TE.chainFirst(() =>
        TE.rightIO(() => this.element.triggerCustom(Form.failEvent, { form: this, response: res }))),
      TE.chain(() =>
        O.fold(() => T.of(E.left(res)), (r: Response) => TE.of<O.Option<Response>, UnsafeResp>({ kind: "text", resp: r, data: "" }))(res))
    );
  }
}
