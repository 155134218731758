import { identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { Q } from "@scripts/dom/q";
import { openInSameTab } from "@scripts/routes/router";
import { replaceUrlState } from "@scripts/routes/urlInterface";
import { tap } from "@scripts/util/tap";

const autoDownload = "autoDownload";
const loginToDownload = ".login-to-download";

export const bindAutoDownload = (container: Q) => pipe(
  O.fromNullable(new URLSearchParams(window.location.search).get(autoDownload)),
  O.map((mediaId: string) => {
    replaceUrlState(identity, identity, tap(q => q.delete(autoDownload)));
    pipe(
      container.one<HTMLAnchorElement>(`${loginToDownload}[data-mediaId="${mediaId}"]`),
      O.map(link => {
        openInSameTab(link.getRawHref());
      }),
    );
  }),
);
