import { filterMap, head } from "fp-ts/lib/Array";
import { pipe, tuple } from "fp-ts/lib/function";
import { chain, filter, fold, map } from "fp-ts/lib/Option";

import type { QEvent } from "@scripts/dom/q";
import { Q } from "@scripts/dom/q";
import { Form } from "@scripts/form/form";
import { Expandable } from "@scripts/ui/expandable";
import { prop } from "@scripts/util/prop";

export class Filters {
  static init(): void {
    Expandable.initBasic(".filters", ".filter-options-container");

    Q.body.listen("change", `.filters ${Form.checkboxSelector}`, (e: QEvent) =>
      map((filters: Q) => {
        const cbsAndChecked: Array<[Q, boolean]> = filterMap(
          (x: Q) => map(
            (label: Q) => tuple(label, x.matches(":checked"))
          )(x.closest("label"))
        )(filters.all(Form.checkboxSelector));
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const checkedCbs = cbsAndChecked.filter(prop(1));
        const isAll = (cbsAndChecked.length === checkedCbs.length) || checkedCbs.length === 0;
        pipe(
          filters.getData("toggle-text-target"),
          chain(Q.one),
          map((el: Q) => pipe(
            head(checkedCbs),
            filter(() => checkedCbs.length === 1),
            fold(
              () => pipe(
                filters.getData(`${isAll ? "all" : "some"}-text`),
                map((text: string) => isAll ? text : `${checkedCbs.length} ${text}`)
              ),
              ([cb]: [Q, boolean]) => cb.getInnerText()
            ),
            map(el.setInnerText)
          ))
        );
      })(e.selectedElement.closest(".filters")));
  }
}
